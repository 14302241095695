// https://developers.facebook.com/docs/facebook-pixel/implementation/pixel-for-official-events#object-properties
import errorTracking from "../../utils/ErrorTracking";

const FacebookPixelService = {
  track(type, fbEventName, fbEventData, event) {
    fbEventData.content_ids = event && event.dates ? event.dates.map((d) => d._id) : [];
    fbEventData.eventref = window.fb_oea ? "fb_oea" : "";
    if (typeof window !== "undefined") {
      // TODO: 5871 follow up - investigate potential bugs/cleanup
      // eslint-disable-next-line no-undef
      if (window.fbq) fbq(type, fbEventName, fbEventData);
      window?.top?.postMessage({ type: "hx-metapixel-echo", contents: [type, fbEventName, fbEventData] }, "*");
    }
  },
  ticketsViewed(event) {
    this.track("trackCustom", "ViewContent", {}, event);
  },
  ticketsAdded(event, order) {
    try {
      if (event && order) {
        this.track(
          "trackCustom",
          "TicketsAdded",
          {
            currency: event.currency,
            totalTickets: order.tickets.length
          },
          event
        );
      }
    } catch (e) {
      errorTracking.track(e);
    }
  },
  enteredDetails(event, order) {
    try {
      if (event && order) {
        this.track(
          "trackCustom",
          "EnteredDetails",
          {
            value: order.totals.total,
            currency: event.currency,
            totalTickets: order.tickets.length
          },
          event
        );
      }
    } catch (e) {
      errorTracking.track(e);
    }
  },
  checkout(event, order) {
    if (event && order) {
      this.track(
        "track",
        "InitiateCheckout",
        {
          value: order.totals.total,
          currency: event.currency,
          totalTickets: order.tickets.length
        },
        event
      );
    }
  },
  complete(event, order) {
    try {
      if (event && order) {
        this.track(
          "track",
          "Purchase",
          {
            value: order.totals.total,
            currency: event.currency,
            totalTickets: order.tickets.length,
            num_items: order.tickets.length
          },
          event
        );
      }
    } catch (e) {
      errorTracking.track(e);
    }
  }
};
export default FacebookPixelService;
