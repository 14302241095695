const determineManualOrder = () => {
  if (typeof window !== "undefined") {
    return window.token && window.isManualOrder && window.manualOrderSettings;
  }
  return false;
};

const fetchManualOrderSettings = () => {
  if (typeof window !== "undefined" && window.token && window.isManualOrder && window.manualOrderSettings) {
    let cleanedSetting = window.manualOrderSettings.replace(/\\"/g, '"');
    cleanedSetting = cleanedSetting.substring(1, cleanedSetting.length - 1);
    return JSON.parse(cleanedSetting);
  }
  return undefined;
};

const createManualOrderTicketOverride = (ticketSelection) => {
  if (!determineManualOrder()) return undefined;

  const manualOrderTicketOverride = {};
  ticketSelection?.requestedTickets?.forEach((ticketSelection) => {
    manualOrderTicketOverride[ticketSelection._id] = ticketSelection.price;
  });
  return manualOrderTicketOverride;
};

export { determineManualOrder, fetchManualOrderSettings, createManualOrderTicketOverride };
