import { route } from "preact-router";
import MerchandiseService from "../../services/MerchandiseService";
import normaliseMerchandiseData from "../../utils/normaliseMerchandiseData";
import { nextPage } from "./routingActions";
export const CLEAR_ORDER = "CLEAR_ORDER";

export const GET_MERCHANDISE_LIST_REQUEST = "GET_MERCHANDISE_LIST_REQUEST";
export const GET_MERCHANDISE_LIST_SUCCESS = "GET_MERCHANDISE_LIST_SUCCESS";
export const GET_MERCHANDISE_LIST_ERROR = "GET_MERCHANDISE_LIST_ERROR";

export const CREATE_MERCHANDISE_REQUEST = "CREATE_MERCHANDISE_REQUEST";
export const CREATE_MERCHANDISE_SUCCESS = "CREATE_MERCHANDISE_SUCCESS";
export const CREATE_MERCHANDISE_ERROR = "CREATE_MERCHANDISE_ERROR";

export const GET_MERCHANDISE_REQUEST = "GET_MERCHANDISE_REQUEST";
export const GET_MERCHANDISE_SUCCESS = "GET_MERCHANDISE_SUCCESS";
export const GET_MERCHANDISE_ERROR = "GET_MERCHANDISE_ERROR";

export const RESET_MERCHANDISE = "RESET_MERCHANDISE";

export const resetMerchOrder = () => {
  return (dispatch) => {
    dispatch({ type: RESET_MERCHANDISE });
  };
};

export const listMerchandise = (orderId) => {
  return (dispatch) => {
    dispatch({ type: GET_MERCHANDISE_LIST_REQUEST });
    MerchandiseService.list(orderId)
      .then((merchList) => {
        dispatch({ type: GET_MERCHANDISE_LIST_SUCCESS, merchList });
      })
      .catch((err) => {
        dispatch({ type: GET_MERCHANDISE_LIST_ERROR, error: err });
      });
  };
};

export const createMerchandiseOrder = (eventSlug, orderId, merchData) => {
  return async (dispatch, getState) => {
    dispatch({ type: CREATE_MERCHANDISE_REQUEST });
    try {
      const normalisedMerchData = normaliseMerchandiseData(merchData);
      const merch = await MerchandiseService.create(orderId, normalisedMerchData);
      if (merch.soldOut) {
        route(`/${eventSlug}/add-ons/${orderId}`);
        dispatch({
          type: GET_MERCHANDISE_LIST_SUCCESS,
          merchList: merch.merchList
        });
        dispatch({
          type: CREATE_MERCHANDISE_ERROR,
          error: "Some add-ons selected are now sold out"
        });
      } else {
        dispatch({
          type: CREATE_MERCHANDISE_SUCCESS,
          data: merch
        });
        dispatch(nextPage());
      }
    } catch (err) {
      dispatch({ type: CREATE_MERCHANDISE_ERROR, error: err });
    }
  };
};

export const getMerchandise = (orderId) => {
  return (dispatch) => {
    dispatch({ type: GET_MERCHANDISE_REQUEST });
    MerchandiseService.getMerch(orderId)
      .then(({ merch, offer }) => {
        dispatch({
          type: GET_MERCHANDISE_SUCCESS,
          data: merch,
          offer
        });
      })
      .catch((err) => {
        dispatch({ type: GET_MERCHANDISE_ERROR, error: err });
      });
  };
};
