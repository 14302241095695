import {
  LOAD_TILL_REQUEST,
  LOAD_TILL_SUCCESS,
  LOAD_TILL_ERROR,
  TILL_INIT,
  UPDATED_FIELDS
} from "../actions/tillActions";

const initialState = {
  checkoutScript: {
    loading: false,
    error: false,
    loaded: false
  },
  payment: {
    loading: true,
    form: {
      validCvv: undefined,
      validNumber: undefined,
      numberLength: undefined,
      cvvLength: undefined,
      cardType: undefined,
      numberFocused: undefined,
      cvvFocused: undefined,
      numberTouched: undefined,
      cvvTouched: undefined
    }
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_TILL_REQUEST:
      return {
        ...state,
        payment: {
          ...initialState.payment
        },
        checkoutScript: {
          loading: true,
          error: false
        }
      };
    case LOAD_TILL_SUCCESS:
      return {
        ...state,
        checkoutScript: {
          loading: false,
          loaded: true,
          error: false
        }
      };
    case LOAD_TILL_ERROR:
      return {
        ...state,
        checkoutScript: {
          loading: false,
          error: true
        }
      };
    case TILL_INIT:
      return {
        ...state,
        payment: {
          ...state.payment,
          loading: false
        }
      };
    case UPDATED_FIELDS:
      return {
        ...state,
        payment: {
          ...state.payment,
          form: action.data
        }
      };
    default:
      return state;
  }
};
export default {
  initialState,
  reducer
};
