import AddressService from "../../services/addressService";

export const GET_COUNTRY_INFO_REQUEST = "GET_COUNTRY_INFO_REQUEST";
export const GET_COUNTRY_INFO_SUCCESS = "GET_COUNTRY_INFO_SUCCESS";
export const GET_COUNTRY_INFO_ERROR = "GET_COUNTRY_INFO_ERROR";

export const GET_STATE_INFO_REQUEST = "GET_STATE_INFO_REQUEST";
export const GET_STATE_INFO_SUCCESS = "GET_STATE_INFO_SUCCESS";
export const GET_STATE_INFO_ERROR = "GET_STATE_INFO_ERROR";

export const getCountryInfo = (isoCode) => {
  return async (dispatch, getState) => {
    const { address } = getState();
    if (address.countries.loading) {
      return;
    }
    dispatch({ type: GET_COUNTRY_INFO_REQUEST });
    try {
      const result = await AddressService.getCountryInfo(isoCode);
      dispatch({ type: GET_COUNTRY_INFO_SUCCESS, countries: result.data });
    } catch (err) {
      dispatch({ type: GET_COUNTRY_INFO_ERROR });
    }
  };
};

export const getStatesForIsoCode = (country) => {
  return async (dispatch, getState) => {
    const { address } = getState();
    const selectedCountry = address.countries.info.find((countryObject) => countryObject.name === country);
    if (address.countries.loading || address.states.loading || !selectedCountry) {
      return;
    }
    const existingStateList = address.states.info[selectedCountry.isoCode];
    if (existingStateList) {
      return;
    }
    dispatch({ type: GET_STATE_INFO_REQUEST });
    try {
      const result = await AddressService.getStatesForIsoCode(selectedCountry.isoCode);
      dispatch({ type: GET_STATE_INFO_SUCCESS, states: result.data });
    } catch (err) {
      dispatch({ type: GET_STATE_INFO_ERROR });
    }
  };
};
