import Modal from "./Modal";
import { css } from "emotion";
import { Link } from "preact-router/match";

const linkcss = css({
  border: "none",
  padding: 9,
  fontSize: 16,
  color: "#5a626c",
  fontWeight: 600,
  borderRadius: 4,
  backgroundColor: "#fafafa",
  marginBottom: 8,
  width: "100%",
  textAlign: "left",
  display: "block",
  "&:hover": {
    boxShadow: "0 1px 4px 0 rgba(9, 30, 66, 0.13)",
    color: "#323232",
    textDecoration: "none"
  }
});

const wrapper = css({
  maxHeight: 300,
  overflowY: "scroll",
  "@media(max-width: 600px)": {
    maxWidth: "calc(100vw - 80px)"
  }
});

const DateModal = ({ open, closeHandler, title, dates, eventSlug }) => {
  const sortedDates = dates.sort(function (a, b) {
    a = new Date(a.startDate);
    b = new Date(b.startDate);
    return a > b ? 1 : a < b ? -1 : 0;
  });
  return (
    <Modal
      showModal={open}
      closeHandler={closeHandler}
      title={title}
      maxWidth={600}
      contents={
        <div className={wrapper}>
          {sortedDates.map((date) => {
            if (date.valid) {
              return (
                <Link href={`/${eventSlug}/tickets?dateId=${date._id}`} className={linkcss}>
                  {date.display.combined}
                </Link>
              );
            }
          })}
        </div>
      }
    />
  );
};

export default DateModal;
