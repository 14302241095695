export const GET_SEATING_MAP_REQUEST = "GET_SEATING_MAP_REQUEST";
export const GET_SEATING_MAP_SUCCESS = "GET_SEATING_MAP_SUCCESS";
export const GET_SEATING_MAP_ERROR = "GET_SEATING_MAP_ERROR";

export const GET_SEATING_MAPS_REQUEST = "GET_SEATING_MAPS_REQUEST";
export const GET_SEATING_MAPS_SUCCESS = "GET_SEATING_MAPS_SUCCESS";
export const GET_SEATING_MAPS_ERROR = "GET_SEATING_MAPS_ERROR";

export const GET_SEATING_MAP_PREVIEWS_REQUEST = "GET_SEATING_MAP_PREVIEWS_REQUEST";
export const GET_SEATING_MAP_PREVIEWS_SUCCESS = "GET_SEATING_MAP_PREVIEWS_SUCCESS";
export const GET_SEATING_MAP_PREVIEWS_ERROR = "GET_SEATING_MAP_PREVIEWS_ERROR";

export const SAVE_TICKET_MAPPING_REQUEST = "SAVE_TICKET_MAPPING_REQUEST";
export const SAVE_TICKET_MAPPING_SUCCESS = "SAVE_TICKET_MAPPING_SUCCESS";
export const SAVE_TICKET_MAPPING_ERROR = "SAVE_TICKET_MAPPING_ERROR";
export const SAVE_TICKET_MAPPING_CONFLICT = "SAVE_TICKET_MAPPING_CONFLICT";

export const SEATING_MAP_REQUIRED = "SEATING_MAP_REQUIRED";
export const RESET_SEATING_MAP_EVAL = "RESET_SEATING_MAP_EVAL";
