import loadScript from "load-script";

export const LOAD_GOOGLE_PAY_REQUEST = "LOAD_GOOGLE_PAY_REQUEST";
export const LOAD_GOOGLE_PAY_SUCCESS = "LOAD_GOOGLE_PAY_SUCCESS";
export const LOAD_GOOGLE_PAY_ERROR = "LOAD_GOOGLE_PAY_ERROR";
export const GOOGLE_PAY_IS_READY_TO_PAY = "GOOGLE_PAY_IS_READY_TO_PAY";

import { checkoutError, pay, payClicked, payV2 } from "./orderActions";
import config from "../../config";

export const loadCheckOutScript = () => {
  return (dispatch, getState) => {
    const state = getState();
    const checkoutScript = state.googlePay.checkoutScript;
    if (checkoutScript.error || (!checkoutScript.loaded && !checkoutScript.loading)) {
      dispatch({ type: LOAD_GOOGLE_PAY_REQUEST });
      loadScript(`https://pay.google.com/gp/p/js/pay.js`, (err, script) => {
        if (err) {
          dispatch({ type: LOAD_GOOGLE_PAY_ERROR });
          return;
        }
        dispatch({ type: LOAD_GOOGLE_PAY_SUCCESS });
      });
    } else {
      dispatch({ type: LOAD_GOOGLE_PAY_SUCCESS });
    }
  };
};

const baseRequest = {
  apiVersion: 2,
  apiVersionMinor: 0
};
const allowedCardNetworks = ["AMEX", "DISCOVER", "INTERAC", "JCB", "MASTERCARD", "MIR", "VISA"];
const allowedPinCardNetworks = ["MASTERCARD", "VISA"];
const allowedCardAuthMethods = ["PAN_ONLY", "CRYPTOGRAM_3DS"];

function isPaymentIdPin(paymentId) {
  return paymentId === "pin";
}

const getBaseCardPaymentMethod = (getState) => {
  const { event } = getState();

  return {
    type: "CARD",
    parameters: {
      allowedAuthMethods: allowedCardAuthMethods,
      allowedCardNetworks: isPaymentIdPin(event.data.paymentOptions.paymentId)
        ? allowedPinCardNetworks
        : allowedCardNetworks
    }
  };
};

let paymentsClient = null;

function getGooglePaymentDataRequest(getState) {
  const { event, order } = getState();
  const isPin = isPaymentIdPin(event.data.paymentOptions.paymentId);

  const gatewayMerchantId = isPin
    ? event.data.paymentOptions.pinInfo.googlePayMerchantId
    : config("TILL_GATEWAY_GOOGLE_PAY_MERCHANT_ID", order.data.location);

  const paymentDataRequest = {
    ...baseRequest,
    allowedPaymentMethods: [
      {
        ...getBaseCardPaymentMethod(getState),
        tokenizationSpecification: {
          type: "PAYMENT_GATEWAY",
          parameters: {
            gateway: isPin ? "pinpayments" : "ixopay",
            gatewayMerchantId
          }
        }
      }
    ],
    transactionInfo: getGoogleTransactionInfo(getState),
    merchantInfo: {
      merchantId: config("GOOGLE_PAY_MERCHANT_ID"), // only prod has config, all other envs uses google generic merchantId
      merchantName: "Humanitix"
    },
    emailRequired: true
  };
  return paymentDataRequest;
}

function getGooglePaymentsClient() {
  if (paymentsClient === null) {
    paymentsClient = new window.google.payments.api.PaymentsClient({
      environment: config("NODE_ENV") === "production" ? "PRODUCTION" : "TEST"
    });
  }
  return paymentsClient;
}

export function onGooglePayLoaded() {
  return (dispatch, getState) => {
    const paymentsClient = getGooglePaymentsClient();
    paymentsClient
      .isReadyToPay({ ...baseRequest, allowedPaymentMethods: [getBaseCardPaymentMethod(getState)] })
      .then(function (response) {
        if (response.result) {
          addGooglePayButton(dispatch, getState);
          dispatch({ type: GOOGLE_PAY_IS_READY_TO_PAY });
        } else {
          console.log("Device/browser may not be supported by Google Pay", navigator.userAgent);
        }
      })
      .catch(function (err) {
        console.error("Google Pay loading failed", err);
      });
  };
}

function addGooglePayButton(dispatch, getState) {
  const paymentsClient = getGooglePaymentsClient();
  const button = paymentsClient.createButton({
    onClick: () => onGooglePaymentButtonClicked(dispatch, getState),
    allowedPaymentMethods: [getBaseCardPaymentMethod(getState)],
    buttonColor: "black",
    buttonType: "checkout"
  });
  const buttonElement = document.getElementById("google-pay-button-container");
  buttonElement.childNodes.forEach((child) => buttonElement.removeChild(child));

  buttonElement.appendChild(button);
}

function getGoogleTransactionInfo(getState) {
  const { orderSummary, event } = getState();
  return {
    countryCode: event.data.location,
    currencyCode: orderSummary.data.currency,
    totalPriceStatus: "ESTIMATED",
    totalPrice: String(orderSummary.data.total)
  };
}

function onGooglePaymentButtonClicked(dispatch, getState) {
  const { order } = getState();
  if (order.paymentLoading || order.paymentRequest.loading) return;
  dispatch(payClicked());
  const paymentDataRequest = getGooglePaymentDataRequest(getState);
  const paymentsClient = getGooglePaymentsClient();
  paymentsClient
    .loadPaymentData(paymentDataRequest)
    .then(function (paymentData) {
      processPayment(dispatch, getState, paymentData);
    })
    .catch(function (err) {
      console.error("Google Pay payment failed", err, err.statusCode, err.message, JSON.stringify(err));
      dispatch(checkoutError("Sorry, we couldn't fetch your card details. Please try again."));
    });
}

function processPayment(dispatch, getState, paymentData) {
  const { order, event } = getState();
  const cardNetwork = paymentData.paymentMethodData.info.cardNetwork;
  const lastFourDigits = paymentData.paymentMethodData.info.cardDetails;
  const paymentToken = paymentData.paymentMethodData.tokenizationData.token;
  const paymentEmail = paymentData.email;

  const eventId = event.data._id;

  if (isPaymentIdPin(event.data.paymentOptions.paymentId)) {
    dispatch(payV2(order.data._id, { gateway: "pin", data: { googlePayToken: paymentToken } }, eventId));
  } else {
    dispatch(
      pay(
        order.data._id,
        "till",
        {
          card_type: cardNetwork,
          last_four_digits: lastFourDigits,
          transactionToken: `googlepay:${paymentToken}`,
          email: paymentEmail
        },
        eventId,
        false
      )
    );
  }
}
