import { route, getCurrentUrl } from "preact-router";
import { getApplicableRoutes, getGiftCardRoutes, COMPLETE, AFTER_CHECKOUT, PAYMENT } from "../../utils/navigationUtils";
import TrackingService from "../../services/tracking/TrackingService";

export const SET_ROUTE_LIST = "SET_ROUTE_LIST";
export const NEXT_ROUTE = "NEXT_ROUTE";
export const ROUTE_MOVE = "ROUTE_MOVE";

const normalizeUrl = (url) => {
  //remove query params
  let normalizedUrl = url.split("?")[0];

  //remove spaces in url segments
  normalizedUrl = normalizedUrl
    .split("/")
    .map((segment) => {
      // eslint-disable-next-line no-useless-escape
      return segment.replace(/\%20/g, "");
    })
    .join("/");

  //remove trailing slashes
  normalizedUrl = normalizedUrl
    .toLowerCase()
    .split("")
    .reverse()
    .reduce((str, char) => {
      if (str.length === 0 && char === "/") {
        return str;
      }
      str = char + str;
      return str;
    }, "");

  return normalizedUrl;
};

export const constructRouteStack = () => {
  return (dispatch, getState) => {
    const { event, order, giftCards, merchandise } = getState();
    const url = normalizeUrl(getCurrentUrl());
    const isGiftCard = url.substr(1, url.length).split("/")[0] === "gift-cards";
    let routes = isGiftCard
      ? getGiftCardRoutes(giftCards.giftCardPage, order.data)
      : getApplicableRoutes(event.data, order.data, merchandise.merch.data);
    const selectedStep = routes.reduce((index, route, i) => {
      if (index !== -1) {
        return index;
      }
      if (url.includes("zipmoneypay") || url.includes("pin-verify-charge")) {
        return routes.findIndex((route) => route.routeId === "payment");
      }
      if (route.path.includes(url)) {
        return i;
      }
      return index;
    }, -1);
    /**
     * For the moment, just set all routes that exist before the current page to active, all future routes to inactive
     */
    routes = routes.map((route, i) => {
      if (i <= selectedStep) {
        route.active = true;
      } else {
        route.active = false;
      }
      return route;
    });
    dispatch({ type: SET_ROUTE_LIST, routeList: routes, selectedStep });
  };
};

export const onBrowserPushPop = () => {
  return (dispatch, getState) => {
    const url = normalizeUrl(getCurrentUrl());
    onMove(url)(dispatch, getState);
  };
};

export const nextPage = () => {
  return (dispatch, getState) => {
    const state = getState();
    const { routing } = state;
    const { routeList, selectedStep } = routing;
    const routeObj = routeList[selectedStep];
    if (routeObj.transitionCall) {
      routeObj.transitionCall(state);
    }
    const newStep = selectedStep + 1;
    // SEND 'EM TO V2!
    try {
      if (/\/([^/]+)\/complete\/([^/]+)/.test(routeList[newStep].path)) {
        const slug = state.event.data.slug;
        const location = state.event.data.location.toLowerCase();
        const orderId = state.order.data._id;
        TrackingService.complete(state.event.data, state.order.data);
        window.location.href = `/${slug}/${location}/${orderId}/complete`;
        return;
      }
    } catch (e) {}

    route(routeList[newStep].path);
    dispatch({ type: NEXT_ROUTE, selectedStep: newStep });
  };
};

export const onMove = (url) => {
  return (dispatch, getState) => {
    const { routing } = getState();
    const { routeList } = routing;
    const selectedStep = routeList.reduce((index, route, i) => {
      if (index !== -1) {
        return index;
      }
      if (url.includes(route.path)) {
        return i;
      }
      return index;
    }, -1);
    dispatch({ type: ROUTE_MOVE, selectedStep });
  };
};

export const hasAdditionalSteps = (getState) => {
  const { routing } = getState();
  const { routeList, selectedStep } = routing;
  const nextStepId = routeList[selectedStep + 1].routeId;
  return ![COMPLETE, AFTER_CHECKOUT, PAYMENT].includes(nextStepId);
};
