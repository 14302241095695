import axios from "./AxiosWrapper";
import config from "../config";

const AddressService = {
  getCountryInfo(isoCode) {
    const result = axios.get(`${config("API_URL")}address/countries${isoCode ? `?preload-states=${isoCode}` : ""}`);
    return result;
  },
  getStatesForIsoCode(isoCode) {
    const result = axios.get(`${config("API_URL")}address/countries/${isoCode}/states`);
    return result;
  }
};

export default AddressService;
