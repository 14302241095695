import axios from "./AxiosWrapper";
import config from "../config";

const OrganiserService = {
  get(organiserId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${config("API_URL")}organisers/${organiserId}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getCharitiesOrganiserId(organiserId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${config("API_URL")}organisers/charities/${organiserId}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getEventsByOrganiserId(organiserId, page) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${config("API_URL")}organisers/events/${organiserId}`, {
          params: { page }
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  sanitiseURLs(url) {
    let siteLink = url;
    let pattern = /^((http|https|ftp):\/\/)/;
    if (!pattern.test(siteLink)) {
      siteLink = "https://" + siteLink;
    }
    return siteLink;
  }
};

export default OrganiserService;
