import config from "../config";
import { css } from "emotion";
import { EventLocation } from "@hx/utilities";
import { lastOfType } from "../utils/CssUtils";

const Breadcrumbs = ({ event, color }) => {
  const wrapperStyle = css({
    margin: "0 auto",
    padding: "24px 0px",
    borderBottom: "solid 1px #dedede",
    "span:first-child": {
      margin: 0
    },
    span: {
      margin: "0 8px"
    },
    li: {
      margin: 0,
      display: "inline-block",
      a: {
        color: `${color} !important`,
        textDecoration: "underline",
        textTransform: "capitalize",
        fontSize: 14,
        fontWeight: 400
      },
      [lastOfType()]: {
        a: {
          textDecoration: "none"
        }
      }
    },

    "@media(max-width: 500px)": {
      padding: "8px 14px"
    }
  });
  const eventLocation = event.data && event.data.eventLocation ? event.data.eventLocation : {};
  const country = EventLocation.getCountryFromEventLocation(eventLocation);
  const locality = EventLocation.getLocalityFromEventLocation(eventLocation);
  const locale = event.data && event.data.location ? event.data.location.toLowerCase() : false;
  const marketingUrl = config("WEBSITE_URL");

  if (!country || !locality || !locale || !marketingUrl) {
    return null;
  }

  return (
    <ol className={wrapperStyle} itemscope itemtype="https://schema.org/BreadcrumbList">
      <li itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem">
        <a itemprop="item" href={encodeURI(`${marketingUrl}/${locale}/search`)} aria-label="All Events">
          <span itemprop="name">All Events</span>
        </a>
        <meta itemprop="position" content="1" />
        <span aria-hidden>&gt;</span>
      </li>
      <li itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem">
        <a
          itemprop="item"
          href={encodeURI(`${marketingUrl}/${locale}/events/${country}/${locality.split(" ").join("-")}`)}
          aria-label={`${locality.charAt(0).toUpperCase() + locality.slice(1)} Events`}
        >
          <span itemprop="name">{locality.charAt(0).toUpperCase() + locality.slice(1)} Events</span>
        </a>
        <meta itemprop="position" content="2" />
        <span aria-hidden>&gt;</span>
      </li>
      <li itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem">
        <a itemprop="item" href={encodeURI(`/${event.data.slug}`)} aria-label={event.data.name} native>
          <span itemprop="name">{event.data.name}</span>
        </a>
        <meta itemprop="position" content="3" />
      </li>
    </ol>
  );
};

export default Breadcrumbs;
