import { h } from "preact";
import { css } from "emotion";
import config from "../config";
import Logo from "../assets/images/Logo";

const headerStyles = css({
  background: "#ffffff",
  zIndex: 1000,
  height: 70,
  width: "100%",
  position: "relative",
  boxShadow: "0 0 1px 0 #f1f1f1, 0 2px 3px 0 rgba(32, 7, 64, 0.13)",
  padding: "27px 40px",
  "@media(max-width:1100px)": {
    height: 60
  },
  "@media(max-width:1048px)": {
    padding: "27px 14px"
  },
  "@media(max-width:700px)": {
    padding: "27px 12px"
  },
  "a:focus": {
    outlineOffset: 2
  }
});

const Header = () => {
  const websiteURL = config("WEBSITE_URL");
  return (
    <header className={headerStyles}>
      <a href={websiteURL} aria-label="Humanitix" label="Humanitix">
        <Logo footerLogoColor={"black"} height={22} />
      </a>
    </header>
  );
};
export default Header;
