import {
  GET_VIRTUAL_EVENT_HUB_REQUEST,
  GET_VIRTUAL_EVENT_HUB_SUCCESS,
  GET_VIRTUAL_EVENT_HUB_ERROR,
  BEAT_VIRTUAL_EVENT_HUB_SUCCESS,
  BEAT_VIRTUAL_EVENT_HUB_ERROR
} from "../actions/virtualEventHubActions";

const initialState = {
  data: false,
  loading: false,
  error: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_VIRTUAL_EVENT_HUB_REQUEST:
      return {
        ...state,
        id: null,
        loading: true,
        error: false
      };
    case GET_VIRTUAL_EVENT_HUB_SUCCESS:
      return {
        ...state,
        data: {
          message: action.message,
          access: action.access,
          hub: action.virtualEventHub,
          date: action.date
        },
        loading: false,
        error: false
      };
    case GET_VIRTUAL_EVENT_HUB_ERROR:
      return { data: false, loading: false, error: action.error };

    case BEAT_VIRTUAL_EVENT_HUB_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          message: action.message,
          access: action.access
        },
        loading: false,
        error: false
      };
    case BEAT_VIRTUAL_EVENT_HUB_ERROR:
      return {
        ...state,
        data: {
          ...state.data,
          message: action.message,
          access: action.access,
          error: action.error
        },
        loading: false,
        error: false
      };

    default:
      return state;
  }
};
export default {
  initialState,
  reducer
};
