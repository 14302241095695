import {
  CLEAR_ORDER,
  GET_ORDER_REQUEST,
  GET_ORDER_SUCCESS,
  GET_ORDER_ERROR,
  GET_ORDER_REQ_REQUEST,
  GET_ORDER_REQ_SUCCESS,
  GET_ORDER_REQ_ERROR,
  SAVE_ORDER_DATA_REQUEST,
  SAVE_ORDER_DATA_SUCCESS,
  SAVE_ORDER_DATA_ERROR,
  PAYMENT_CLICKED,
  CHECKOUT_ERROR,
  PAYMENT_REQUEST,
  PAYMENT_SUCCESS,
  PAYMENT_ERROR,
  ADD_DISCOUNT_REQUEST,
  ADD_DISCOUNT_SUCCESS,
  ADD_DISCOUNT_ERROR,
  REMOVE_DISCOUNT_REQUEST,
  REMOVE_DISCOUNT_SUCCESS,
  REMOVE_DISCOUNT_ERROR,
  ADD_GIFT_CARD_ERROR,
  ADD_GIFT_CARD_REQUEST,
  ADD_GIFT_CARD_SUCCESS,
  REMOVE_GIFT_CARD_ERROR,
  REMOVE_GIFT_CARD_REQUEST,
  REMOVE_GIFT_CARD_SUCCESS,
  COMPLETE_GIFT_CARD_ORDER_ERROR,
  COMPLETE_GIFT_CARD_ORDER_REQUEST,
  COMPLETE_GIFT_CARD_ORDER_SUCCESS,
  ATTENDEE_ACTIONED_REFUND_ERROR,
  ATTENDEE_ACTIONED_REFUND_REQUEST,
  ATTENDEE_ACTIONED_REFUND_SUCCESS,
  ATTENDEE_REFUND_AMOUNT_REQUEST,
  ATTENDEE_REFUND_AMOUNT_SUCCESS,
  ATTENDEE_REFUND_AMOUNT_ERROR,
  TIME_EXPIRED,
  CHANGE_PAYMENT_METHOD,
  SWITCH_PAYMENT_REQUEST,
  SWITCH_PAYMENT_ERROR,
  SWITCH_PAYMENT_SUCCESS,
  CREDIT_AND_DEBIT,
  INVOICING,
  GET_DUMMY_ORDER_LOADING,
  GET_DUMMY_ORDER_SUCCESS,
  ADD_TO_CALENDAR_REQUEST,
  ADD_TO_CALENDAR_SUCCESS,
  ADD_TO_CALENDAR_ERROR,
  TIP_CHANGE_REQUEST,
  TIP_CHANGE_SUCCESS,
  TIP_CHANGE_ERROR,
  PAYMENT_RESET
} from "../actions/orderActions";

import { STRIPE_CHECKOUT_ERROR } from "../actions/stripeActions";

import { ADD_TICKET_QUANTITY, SELECT_DATE } from "../actions/ticketsActions";

import { ADD_DISCOVER_NSW_SUCCESS, REMOVE_DISCOVER_NSW_SUCCESS } from "../actions/discoverNSWActions";

const initialState = {
  data: null,
  providedOrderData: null,
  paymentMethod: CREDIT_AND_DEBIT,
  timeExpired: false,
  tipFees: false,
  paymentSwitch: {
    gateway: false,
    loading: false,
    error: false,
    data: false
  },
  orderGet: {
    loading: false,
    error: false
  },
  orderRequest: {
    loading: false,
    error: false
  },
  detailsRequest: {
    loading: false,
    error: false,
    additionalQuestionQuantityError: false,
    saved: false
  },
  paymentLoading: false,
  paymentRequest: {
    loading: false,
    error: false
  },
  authRequest: {
    loading: false,
    error: false
  },
  discountRequest: {
    loading: false,
    error: false,
    code: ""
  },
  giftCardsRequest: {
    loading: false,
    error: false,
    isFullPayment: false,
    code: ""
  },
  refundRequest: {
    loading: false,
    error: false,
    success: false
  },
  refundAmount: {
    loading: false,
    error: false,
    success: false,
    data: {}
  },
  addToCalendar: {
    loading: false,
    error: false,
    success: false,
    results: []
  }
};

export function convertOrder(order) {
  const tickets = !order.tickets
    ? []
    : order.tickets.sort((a, b) => {
        const textA = a.ticketTypeName.toUpperCase();
        const textB = b.ticketTypeName.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
  return {
    ...order,
    tickets
  };
}

export const getDiscountCode = (order) => {
  const ticketWithCodes = order?.tickets?.find((t) => {
    // only show discount codes not auto discounts
    const codeDiscounts = t.discountCodes ? t.discountCodes.filter((d) => d.type !== "auto") : [];
    return !!codeDiscounts.length;
  });
  const discountCode = ticketWithCodes?.discountCodes?.find((d) => d.type !== "auto") || { code: "" };
  return discountCode;
};

export const getGiftCardCode = (order) => {
  return order?.giftCard?.code || "";
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_ORDER:
      return initialState;
    case SWITCH_PAYMENT_REQUEST: {
      return {
        ...state,
        paymentSwitch: { loading: true, error: false, data: false, gateway: action.gateway },
        paymentMethod: action.method === "invoice" ? INVOICING : CREDIT_AND_DEBIT
      };
    }
    case SWITCH_PAYMENT_ERROR: {
      return {
        ...state,
        paymentSwitch: { ...state.paymentSwitch, loading: false, error: action.error, data: false }
      };
    }
    case SWITCH_PAYMENT_SUCCESS: {
      return {
        ...state,
        paymentSwitch: { ...state.paymentSwitch, loading: false, error: false, data: action.gatewaySwitchResult }
      };
    }
    case CHANGE_PAYMENT_METHOD: {
      return {
        ...state,
        paymentMethod: action.method
      };
    }
    case TIP_CHANGE_REQUEST: {
      return {
        ...state,
        paymentLoading: true
      };
    }
    case TIP_CHANGE_ERROR:
    case TIP_CHANGE_SUCCESS: {
      return {
        ...state,
        paymentLoading: false
      };
    }
    case ADD_TICKET_QUANTITY: {
      const _id = state.data && state.data._id ? state.data._id : null;
      return {
        ...state,
        data: {
          _id
        },
        orderRequest: {
          loading: false,
          error: false
        }
      };
    }
    case SELECT_DATE:
      return {
        ...state,
        orderRequest: {
          loading: false,
          error: false
        }
      };
    case GET_ORDER_REQUEST:
      return {
        ...state,
        orderGet: {
          loading: true,
          error: false
        }
      };
    case GET_ORDER_SUCCESS: {
      const orderData = convertOrder(action.order);
      return {
        ...state,
        timeExpired: false,
        data: orderData,
        paymentMethod: orderData.paymentGateway === "invoice" ? INVOICING : CREDIT_AND_DEBIT,
        orderGet: {
          loading: false,
          error: false
        },
        discountRequest: {
          loading: false,
          error: false,
          code: getDiscountCode(orderData).code
        },
        giftCardsRequest: {
          loading: false,
          error: false,
          isFullPayment: false,
          code: getGiftCardCode(orderData)
        }
      };
    }
    case GET_ORDER_ERROR:
      return {
        ...state,
        data: false,
        orderGet: {
          loading: false,
          error: action.error
        }
      };

    case ADD_DISCOUNT_REQUEST:
      return {
        ...state,
        discountRequest: {
          loading: true,
          error: false,
          code: ""
        }
      };
    case ADD_DISCOUNT_SUCCESS:
      return {
        ...state,
        discountRequest: {
          code: action.code,
          loading: false,
          error: false
        }
      };
    case ADD_DISCOUNT_ERROR:
      return {
        ...state,
        discountRequest: {
          loading: false,
          error: action.error,
          code: ""
        }
      };
    case REMOVE_DISCOUNT_REQUEST:
      return {
        ...state,
        discountRequest: {
          ...state.discountRequest,
          loading: true,
          error: false
        }
      };
    case REMOVE_DISCOUNT_SUCCESS:
      return {
        ...state,
        discountRequest: {
          loading: false,
          error: false,
          code: ""
        }
      };
    case REMOVE_DISCOUNT_ERROR:
      return {
        ...state,
        discountRequest: {
          ...state.discountRequest,
          loading: false,
          error: action.error
        }
      };

    case ADD_GIFT_CARD_REQUEST:
      return {
        ...state,
        giftCardsRequest: {
          ...state.giftCardsRequest,
          loading: true,
          error: false
        }
      };
    case ADD_GIFT_CARD_SUCCESS:
      return {
        ...state,
        giftCardsRequest: {
          ...state.giftCardsRequest,
          loading: false,
          error: false,
          isFullPayment: action.isFullPayment,
          code: action.code
        }
      };
    case ADD_GIFT_CARD_ERROR:
      return {
        ...state,
        giftCardsRequest: {
          ...state.giftCardsRequest,
          loading: false,
          error: action.error
        }
      };
    case REMOVE_GIFT_CARD_REQUEST:
      return {
        ...state,
        giftCardsRequest: {
          ...state.giftCardsRequest,
          loading: true,
          error: false
        }
      };
    case REMOVE_GIFT_CARD_SUCCESS:
      return {
        ...state,
        giftCardsRequest: {
          loading: false,
          error: false,
          isFullPayment: false,
          code: ""
        }
      };
    case REMOVE_GIFT_CARD_ERROR:
      return {
        ...state,
        giftCardsRequest: {
          ...state.giftCardsRequest,
          loading: false,
          error: action.error
        }
      };
    case COMPLETE_GIFT_CARD_ORDER_REQUEST:
      return {
        ...state,
        giftCardsRequest: {
          ...state.giftCardsRequest,
          loading: true,
          error: false
        }
      };
    case COMPLETE_GIFT_CARD_ORDER_SUCCESS:
      return {
        ...state,
        data: convertOrder(action.order),
        giftCardsRequest: {
          ...state.giftCardsRequest,
          loading: false,
          error: false
        }
      };
    case COMPLETE_GIFT_CARD_ORDER_ERROR:
      return {
        ...state,
        giftCardsRequest: {
          ...state.giftCardsRequest,
          loading: false,
          error: action.error
        }
      };
    case GET_ORDER_REQ_REQUEST:
      return {
        ...state,
        data: null,
        orderRequest: {
          loading: true,
          error: false
        }
      };
    case GET_ORDER_REQ_SUCCESS:
      return {
        ...state,
        discountRequest: {
          loading: false,
          error: false,
          code: ""
        },
        data: convertOrder(action.order),
        timeExpired: false,
        orderRequest: {
          loading: false,
          error: false
        },
        paymentRequest: {
          loading: false,
          error: false
        }
      };
    case GET_DUMMY_ORDER_LOADING:
      return {
        ...state,
        data: null,
        orderRequest: {
          loading: true,
          error: false
        }
      };
    case GET_DUMMY_ORDER_SUCCESS:
      return {
        ...state,
        ...action.order
      };
    case GET_ORDER_REQ_ERROR:
      return {
        ...state,
        data: null,
        orderRequest: {
          loading: false,
          error: action.error
        }
      };
    case SAVE_ORDER_DATA_REQUEST:
      return {
        ...state,
        detailsRequest: {
          loading: true,
          error: false,
          saved: false,
          additionalQuestionQuantityError: false
        }
      };
    case SAVE_ORDER_DATA_SUCCESS:
      return {
        ...state,
        data: convertOrder(action.order),
        detailsRequest: {
          loading: false,
          error: false,
          saved: true,
          additionalQuestionQuantityError: false
        },
        paymentRequest: {
          loading: false,
          error: false
        },
        paymentLoading: false
      };
    case SAVE_ORDER_DATA_ERROR:
      return {
        ...state,
        detailsRequest: {
          loading: false,
          error: action.error,
          saved: false,
          additionalQuestionQuantityError: action.additionalQuestionQuantityError
        }
      };

    case PAYMENT_CLICKED:
      return {
        ...state,
        paymentLoading: true
      };
    case STRIPE_CHECKOUT_ERROR:
    case CHECKOUT_ERROR: {
      const update = {
        ...state,
        paymentLoading: false
      };
      if (action.error) {
        update.paymentRequest = {
          loading: false,
          error: action.error
        };
      }

      return update;
    }

    case PAYMENT_RESET:
      return {
        ...state,
        paymentLoading: false,
        paymentRequest: initialState.paymentRequest
      };
    case PAYMENT_REQUEST:
      return {
        ...state,
        paymentLoading: false,
        paymentRequest: {
          loading: true,
          error: false
        }
      };
    case PAYMENT_SUCCESS:
      return {
        ...state,
        data: convertOrder(action.order),
        paymentLoading: false,
        paymentRequest: {
          loading: false,
          error: false
        }
      };
    case PAYMENT_ERROR:
      return {
        ...state,
        data: action.order ? convertOrder(action.order) : state.data,
        paymentLoading: false,
        paymentRequest: {
          loading: false,
          error: action.error
        }
      };

    case TIME_EXPIRED:
      return {
        ...state,
        timeExpired: true
      };

    case ATTENDEE_ACTIONED_REFUND_REQUEST:
      return {
        ...state,
        refundRequest: {
          loading: true,
          error: false,
          success: false
        }
      };
    case ATTENDEE_ACTIONED_REFUND_SUCCESS:
      return {
        ...state,
        data: convertOrder(action.result),
        refundRequest: {
          loading: false,
          error: false,
          success: true
        }
      };
    case ATTENDEE_ACTIONED_REFUND_ERROR:
      return {
        ...state,
        refundRequest: {
          loading: false,
          error: true,
          success: false
        }
      };

    case ATTENDEE_REFUND_AMOUNT_REQUEST:
      return {
        ...state,
        refundAmount: {
          ...state.refundRequest,
          loading: true,
          error: false,
          success: false
        }
      };
    case ATTENDEE_REFUND_AMOUNT_SUCCESS:
      return {
        ...state,
        refundAmount: {
          ...state.refundRequest,
          data: action.refundAmount,
          loading: false,
          error: false,
          success: true
        }
      };
    case ATTENDEE_REFUND_AMOUNT_ERROR:
      return {
        ...state,
        refundAmount: {
          ...state.refundRequest,
          loading: false,
          error: true,
          success: false
        }
      };

    case ADD_TO_CALENDAR_REQUEST:
      return {
        ...state,
        addToCalendar: {
          loading: true,
          error: false,
          success: false,
          result: []
        }
      };
    case ADD_TO_CALENDAR_SUCCESS:
      return {
        ...state,
        addToCalendar: {
          loading: false,
          error: false,
          success: true,
          result: action.result
        }
      };
    case ADD_TO_CALENDAR_ERROR:
      return {
        ...state,
        addToCalendar: {
          loading: false,
          error: true,
          success: false,
          result: []
        }
      };
    case REMOVE_DISCOVER_NSW_SUCCESS:
      return {
        ...state,
        data: { ...state.data, vouchers: undefined }
      };
    case ADD_DISCOVER_NSW_SUCCESS:
      return {
        ...state,
        data: { ...state.data, vouchers: [action.voucher] }
      };

    default:
      return state;
  }
};

export default {
  initialState,
  reducer
};
