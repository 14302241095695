import {
  LOAD_CHECKOUT_SCRIPT_REQUEST,
  LOAD_CHECKOUT_SCRIPT_SUCCESS,
  LOAD_CHECKOUT_SCRIPT_ERROR
} from "../actions/payPalActions";

const initialState = {
  checkoutScript: {
    loading: false,
    error: false,
    loaded: false
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_CHECKOUT_SCRIPT_REQUEST:
      return {
        ...state,
        checkoutScript: {
          loading: true,
          error: false
        }
      };
    case LOAD_CHECKOUT_SCRIPT_SUCCESS:
      return {
        ...state,
        checkoutScript: {
          loading: false,
          loaded: true,
          error: false
        }
      };
    case LOAD_CHECKOUT_SCRIPT_ERROR:
      return {
        ...state,
        checkoutScript: {
          loading: false,
          error: true
        }
      };
    default:
      return state;
  }
};
export default {
  initialState,
  reducer
};
