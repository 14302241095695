import {
  GET_SEATING_MAP_REQUEST,
  GET_SEATING_MAP_SUCCESS,
  GET_SEATING_MAP_ERROR,
  GET_SEATING_MAPS_REQUEST,
  GET_SEATING_MAPS_SUCCESS,
  GET_SEATING_MAPS_ERROR,
  SAVE_TICKET_MAPPING_ERROR,
  SAVE_TICKET_MAPPING_REQUEST,
  SAVE_TICKET_MAPPING_SUCCESS,
  SAVE_TICKET_MAPPING_CONFLICT,
  SEATING_MAP_REQUIRED,
  RESET_SEATING_MAP_EVAL,
  GET_SEATING_MAP_PREVIEWS_ERROR,
  GET_SEATING_MAP_PREVIEWS_SUCCESS,
  GET_SEATING_MAP_PREVIEWS_REQUEST
} from "../actions/seatingMapActionDefintions";

const initialState = {
  selectedSeatingMap: false,
  seatingMaps: [],
  seatingMapPreviews: [],
  loading: false,
  error: false,
  message: "",
  orderContainsMap: false,
  seatingMapRequired: false,
  seatingMapEvaluated: false,
  save: {
    loading: false,
    error: false,
    conflicts: []
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SEATING_MAP_REQUEST:
      return {
        ...state,
        loading: true,
        error: false
      };
    case GET_SEATING_MAP_SUCCESS:
      return {
        ...state,
        orderContainsMap: true,
        loading: false,
        error: false,
        selectedSeatingMap: action.seatingMap
      };
    case GET_SEATING_MAP_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case GET_SEATING_MAPS_REQUEST:
      return {
        ...state,
        loading: true,
        error: false
      };
    case GET_SEATING_MAPS_SUCCESS:
      return {
        ...state,
        orderContainsMap: true,
        loading: false,
        error: false,
        seatingMaps: action.seatingMaps
      };
    case GET_SEATING_MAPS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case GET_SEATING_MAP_PREVIEWS_REQUEST:
      return {
        ...state,
        loading: true,
        error: false
      };
    case GET_SEATING_MAP_PREVIEWS_SUCCESS:
      return {
        ...state,
        orderContainsMap: true,
        loading: false,
        error: false,
        seatingMapPreviews: action.seatingMaps
      };
    case GET_SEATING_MAP_PREVIEWS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case SAVE_TICKET_MAPPING_REQUEST:
      return {
        ...state,
        save: {
          loading: true,
          error: false,
          conflicts: []
        }
      };
    case SAVE_TICKET_MAPPING_SUCCESS:
      return {
        ...state,
        save: {
          message: "Saved",
          loading: false,
          error: false,
          conflicts: []
        }
      };
    case SAVE_TICKET_MAPPING_CONFLICT:
      return {
        ...state,
        save: {
          message: "Failed to save due to conflicting seat choices",
          loading: false,
          error: false,
          conflicts: action.conflicts
        }
      };
    case SAVE_TICKET_MAPPING_ERROR:
      return {
        ...state,
        save: {
          message: "Failed to save",
          loading: false,
          error: true,
          conflicts: []
        }
      };
    case SEATING_MAP_REQUIRED:
      return {
        ...state,
        seatingMapRequired: action.required,
        seatingMapEvaluated: true
      };
    case RESET_SEATING_MAP_EVAL:
      return {
        ...state,
        seatingMapEvaluated: false
      };
    default:
      return state;
  }
};

export default {
  initialState,
  reducer
};
