import EventsService from "../../services/EventsService";
import OrderService from "../../services/OrderService";
import LocationService from "../../services/LocationService";

export const GET_EVENT_REQUEST = "GET_EVENT_REQUEST";
export const GET_EVENT_SUCCESS = "GET_EVENT_SUCCESS";
export const GET_EVENT_ERROR = "GET_EVENT_ERROR";

export const GET_USER_EVENTS_REQUEST = "GET_USER_EVENTS_REQUEST";
export const GET_USER_EVENTS_SUCCESS = "GET_USER_EVENTS_SUCCESS";
export const GET_USER_EVENTS_ERROR = "GET_USER_EVENTS_ERROR";

export const GET_EVENT_DATES_REQUEST = "GET_EVENT_DATES_REQUEST";
export const GET_EVENT_DATES_SUCCESS = "GET_EVENT_DATES_SUCCESS";
export const GET_EVENT_DATES_ERROR = "GET_EVENT_DATES_ERROR";

export const GET_DATE_REQUEST = "GET_DATE_REQUEST";
export const GET_DATE_SUCCESS = "GET_DATE_SUCCESS";
export const GET_DATE_ERROR = "GET_DATE_ERROR";

export const GET_NEAREST_EVENT_DATES_REQUEST = "GET_NEAREST_EVENT_DATES_REQUEST";
export const GET_NEAREST_EVENT_DATES_SUCCESS = "GET_NEAREST_EVENT_DATES_SUCCESS";
export const GET_NEAREST_EVENT_DATES_ERROR = "GET_NEAREST_EVENT_DATES_ERROR";

export const SET_WIDGET = "SET_WIDGET";

export const GET_ADDITIONAL_QUESTION_WITH_QUANTITY_SUCCESS = "GET_ADDITIONAL_QUESTION_WITH_QUANTITY_SUCCESS";

export const CLEAR_EVENT = "CLEAR_EVENT";

import { loadTickets } from "./ticketsActions";

import { getOrder, GET_ORDER_SUCCESS, GET_ORDER_REQUEST, GET_ORDER_ERROR, payV2 } from "./orderActions";
import { constructRouteStack } from "./routingActions";

import { convertDateForOnlineEvent, getDayOfMonthForDate } from "../../utils/DateUtils";

const convertDatesForOnlineEvent = (getState, dates) => {
  const { event } = getState();
  if (event?.data?.eventLocation?.type !== "online") {
    return dates;
  }

  const combinedDatesArray = Object.keys(dates).reduce((acc, key) => {
    return acc.concat(dates[key]);
  }, []);
  const convertedDates = combinedDatesArray.map((date) => {
    date.display = convertDateForOnlineEvent(date.startDate, date.endDate, event.data.location);
    date.dayKey = getDayOfMonthForDate(date.startDate);
    return date;
  });
  return convertedDates.reduce((acc, date) => {
    if (acc[date.dayKey]) {
      acc[date.dayKey].push(date);
    } else {
      acc[date.dayKey] = [date];
    }
    return acc;
  }, {});
};

export const initialiseEventAndOrderAndPay = (slug, orderId, paymentGateway, paymentData) => {
  return async (dispatch, getState) => {
    const state = getState();
    let event = state.event.data;
    if (!event) {
      dispatch({ type: GET_EVENT_REQUEST, slug });
      try {
        event = await EventsService.get(slug);
        LocationService.setEventLocation(event.location);
        dispatch({ type: GET_EVENT_SUCCESS, event });
      } catch (err) {
        dispatch({ type: GET_EVENT_ERROR, error: err });
      }
    }
    if (!state.order.data) {
      try {
        dispatch({ type: GET_ORDER_REQUEST, id: orderId });
        const order = await OrderService.get(orderId);
        LocationService.setEventLocation(order.location);
        dispatch({ type: GET_ORDER_SUCCESS, order });
      } catch (err) {
        dispatch({ type: GET_ORDER_ERROR, error: err });
      }
    }
    dispatch(constructRouteStack());
    payV2(orderId, { gateway: paymentGateway, data: paymentData }, event._id)(dispatch, getState);
  };
};

export const getEvent = (slug, token, getTickets, orderId, getMerch = false, eventDateId = null) => {
  return (dispatch, getState) => {
    dispatch({ type: GET_EVENT_REQUEST, slug });
    EventsService.get(slug, token, orderId, eventDateId)
      .then((event) => {
        LocationService.setEventLocation(event.location);
        dispatch({ type: GET_EVENT_SUCCESS, event });
        if (getTickets) {
          const state = getState();
          loadTickets(state.event.id, state.ticketSelection.selectedDateId)(dispatch);
        }
        if (orderId) {
          getOrder(orderId, getMerch)(dispatch);
        }
      })
      .catch((err) => {
        dispatch({ type: GET_EVENT_ERROR, error: err });
      });
  };
};

export const getEventPublic = (eventId, orderId, token) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_EVENT_REQUEST });
      const event = await EventsService.getEventPublic(eventId, orderId, token);
      LocationService.setEventLocation(event.location);
      dispatch({ type: GET_EVENT_SUCCESS, event });
    } catch (err) {
      dispatch({ type: GET_EVENT_ERROR, error: err });
    }
  };
};
// slug can be either userId or organiserId
export const getUserEvents = (userId, organiserId) => {
  return (dispatch) => {
    dispatch({ type: GET_USER_EVENTS_REQUEST, userId, organiserId });
    EventsService.getAllByUser(userId, organiserId)
      .then((events) => {
        dispatch({ type: GET_USER_EVENTS_SUCCESS, events });
      })
      .catch((err) => {
        dispatch({ type: GET_USER_EVENTS_ERROR, error: err });
      });
  };
};

export const getDates = (eventId, month, year, date) => {
  return (dispatch, getState) => {
    dispatch({ type: GET_EVENT_DATES_REQUEST, month: date });
    EventsService.getDates(eventId, month, year)
      .then((dates) => {
        const convertedDates = convertDatesForOnlineEvent(getState, dates);
        dispatch({ type: GET_EVENT_DATES_SUCCESS, dates: convertedDates });
      })
      .catch((err) => {
        dispatch({ type: GET_EVENT_DATES_ERROR, error: err });
      });
  };
};

export const getDate = (eventSlug, eventDateId) => {
  return (dispatch) => {
    dispatch({ type: GET_DATE_REQUEST });
    EventsService.getDate(eventSlug, eventDateId)
      .then((date) => {
        dispatch({ type: GET_DATE_SUCCESS, date });
      })
      .catch((err) => {
        dispatch({ type: GET_DATE_ERROR, error: err });
      });
  };
};

export const getNextAvailableDates = (eventId) => {
  return (dispatch, getState) => {
    dispatch({ type: GET_NEAREST_EVENT_DATES_REQUEST });
    EventsService.getNearestDate(eventId)
      .then((data) => {
        const dates = convertDatesForOnlineEvent(getState, data.dates);
        dispatch({
          type: GET_NEAREST_EVENT_DATES_SUCCESS,
          month: new Date(data.month),
          dates
        });
      })
      .catch((err) => {
        dispatch({ type: GET_NEAREST_EVENT_DATES_ERROR, error: err });
      });
  };
};

export const getAdditionalQuestionsPreview = (eventSlug) => {
  return (dispatch) => {
    dispatch({ type: GET_EVENT_REQUEST });
    EventsService.getAdditionalQuestionsPreview(eventSlug)
      .then((event) => {
        dispatch({
          type: GET_EVENT_SUCCESS,
          event
        });
      })
      .catch((err) => {
        dispatch({ type: GET_EVENT_ERROR, error: err });
      });
  };
};

export const getCompleteMessagePreview = (eventSlug) => {
  return (dispatch) => {
    dispatch({ type: GET_EVENT_REQUEST });
    EventsService.getCompleteMessagePreview(eventSlug)
      .then((event) => {
        dispatch({
          type: GET_EVENT_SUCCESS,
          event
        });
      })
      .catch((err) => {
        dispatch({ type: GET_EVENT_ERROR, error: err });
      });
  };
};

export const clearEvent = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_EVENT });
  };
};
