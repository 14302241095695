import {
  GET_ORGANISER_SUCCESS,
  GET_ORGANISER_FAILURE,
  GET_ORGANISER_LOADING,
  GET_EVENTS_SUCCESS,
  GET_EVENTS_FAILURE,
  GET_EVENTS_LOADING
} from "../actions/organiserActions";

const initialState = {
  organiserLoading: true,
  organiser: null,
  organiserFailure: false,
  eventsResult: {
    page: 0,
    pageSize: 20,
    events: [],
    total: 0,
    loading: false,
    errors: false
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ORGANISER_FAILURE:
      return {
        ...state,
        organiserFailure: true
      };
    case GET_ORGANISER_LOADING:
      return {
        ...state,
        organiserLoading: true
      };
    case GET_ORGANISER_SUCCESS:
      return {
        ...state,
        organiser: action.organiser,
        organiserLoading: false
      };

    case GET_EVENTS_LOADING:
      return {
        ...state,
        eventsResult: {
          ...state.eventsResult,
          page: action.page,
          loading: true
        }
      };
    case GET_EVENTS_SUCCESS:
      return {
        ...state,
        eventsResult: {
          ...state.eventsResult,
          events: action.events,
          page: action.page,
          pageSize: action.pageSize,
          total: action.total,
          loading: false
        }
      };
    case GET_EVENTS_FAILURE:
      return {
        ...state
      };

    default:
      return state;
  }
};
export default { initialState, reducer };
