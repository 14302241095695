import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import thunk from "redux-thunk";
import LocationService from "../services/LocationService";
//reducers
import contactReducer from "./reducers/contactReducer";
import addressReducer from "./reducers/addressReducer";
import eventReducer from "./reducers/eventReducer";
import orderReducer, { getDiscountCode, getGiftCardCode } from "./reducers/orderReducer";
import ticketsReducer from "./reducers/ticketsReducer";
import orderSummaryReducer from "./reducers/orderSummaryReducer";
import payPalReducer from "./reducers/payPalReducer";
import stripeReducer from "./reducers/stripeReducer";
import tillReducer from "./reducers/tillReducer";
import pinReducer from "./reducers/pinReducer";
import googlePayReducer from "./reducers/googlePayReducer";
import seatingMapReducer from "./reducers/seatingMapReducer";
import organiserReducer from "./reducers/organiserReducer";
import waitlistReducer from "./reducers/waitlistReducer";
import giftCardReducer from "./reducers/giftCardReducer";
import dateReducer from "./reducers/dateReducer";
import merchandiseReducer from "./reducers/merchandiseReducer";
import tourReducer from "./reducers/tourReducer";
import attendeeProfileReducer from "./reducers/attendeeProfileReducer";
import routingReducer from "./reducers/routingReducer";
import virtualEventHubReducer from "./reducers/virtualEventHubReducer";
import discoverNSWReducer from "./reducers/discoverNSWReducer";
import followHostReducer from "./reducers/followHostReducer";
import braintreeReducer from "./reducers/braintreeReducer";
import { reducer as formReducer } from "redux-form";
import { parseQueryString } from "../utils/Format";
import config from "../config";
import postHook from "./postHook/postHook";
import { constructRouteStack } from "./actions/routingActions";

const unorderedReducers = {
  contact: contactReducer.reducer,
  event: eventReducer.reducer,
  order: orderReducer.reducer,
  ticketSelection: ticketsReducer.reducer,
  orderSummary: orderSummaryReducer.reducer,
  payPal: payPalReducer.reducer,
  stripe: stripeReducer.reducer,
  till: tillReducer.reducer,
  pin: pinReducer.reducer,
  googlePay: googlePayReducer.reducer,
  seatingMap: seatingMapReducer.reducer,
  organiser: organiserReducer.reducer,
  eventDates: dateReducer.reducer,
  merchandise: merchandiseReducer.reducer,
  waitlist: waitlistReducer.reducer,
  tours: tourReducer.reducer,
  giftCards: giftCardReducer.reducer,
  attendeeProfile: attendeeProfileReducer.reducer,
  virtualEventHub: virtualEventHubReducer.reducer,
  discoverNSW: discoverNSWReducer.reducer,
  followHost: followHostReducer.reducer,
  form: formReducer,
  routing: routingReducer.reducer,
  address: addressReducer.reducer,
  braintree: braintreeReducer.reducer,
  lastAction: (state, action) => action
};

const orderedReducers = {};
Object.keys(unorderedReducers)
  .sort()
  .forEach(function (key) {
    orderedReducers[key] = unorderedReducers[key];
  });

const reducer = combineReducers(orderedReducers);

const composeEnhancers =
  (config("DEV") === true && typeof window !== "undefined" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const create = (eventData, orderData, providedOrderData, eventDateIds, merchData, tourData, giftCardPageData) => {
  let widget = eventData ? eventData.widget : false;
  if (typeof window !== "undefined") {
    const params = parseQueryString(location.search.replace("?", ""));
    widget = !!params.w;
  }

  let eventId = null;
  let ticketData = null;
  let eventDate = null;

  if (eventData) {
    eventData = eventReducer.parseEvent(eventData);
    eventId = eventData._id;
    LocationService.setEventLocation(eventData.location);
    if (eventData.validDates && eventData.validDates.length) {
      // select first valid date
      if (eventDateIds) {
        // id a list of eventDateIds was passed select the first one
        const eventDateList = eventDateIds.split(",");
        eventDate = eventData.validDates.find((date) => {
          if (eventDateList.indexOf(String(date._id)) !== -1) {
            return true;
          }
          return false;
        });
        if (!eventDate) {
          eventDate = eventData.validDates[0];
        }
      } else {
        eventDate = eventData.validDates[0];
      }
    }

    ticketData = ticketsReducer.eventReduce(eventData, ticketsReducer.initialState, eventDate);
  }

  if (orderData) {
    const tickets = !orderData.tickets ? [] : orderData.tickets.sort((a, b) => a.ticketTypeName > b.ticketTypeName);
    orderData = {
      ...orderData,
      tickets
    };
    if (ticketData) {
      ticketData.tickets = ticketsReducer.ticketsFromOrder(orderData, ticketData.tickets);
      ticketData.clientDonation = orderData.clientDonation;
    }
  }

  const eventIntState = {
    ...eventReducer.initialState,
    data: eventData,
    id: eventId,
    widget
  };
  const orderIntState = {
    ...orderReducer.initialState,
    data: orderData,
    providedOrderData,
    discountRequest: {
      loading: false,
      error: false,
      code: getDiscountCode(orderData).code
    },
    giftCardsRequest: {
      loading: false,
      error: false,
      isFullPayment: false,
      code: getGiftCardCode(orderData)
    }
  };
  const ticketSelectionIntState = {
    ...ticketsReducer.initialState,
    ...ticketData
  };

  const orderSummaryIntDate = {
    ...orderSummaryReducer.initialState,
    eventDate: eventDate ? eventDate.display : null
  };

  const merchandiseIntState = {
    ...merchandiseReducer.initialState,
    includesMerch: merchData && merchData.length > 0,
    merch: {
      ...merchandiseReducer.initialState.merch,
      data: merchData,
      display: merchandiseReducer.createMerchDisplay(merchData ? merchData : [])
    }
  };

  const giftCardInitialState = {
    ...giftCardReducer.initialState
  };
  if (giftCardPageData) {
    giftCardReducer.setStyling(giftCardPageData.page);
    giftCardInitialState.giftCardPage = giftCardPageData.page;
    giftCardInitialState.location = giftCardPageData.page.location;
    giftCardInitialState.giftCards = giftCardPageData.giftCards;
  }
  if (orderData && orderData.giftCards) {
    giftCardInitialState.giftCardsOrder = giftCardReducer.giftCardsFromOrder(orderData);
  }

  if (orderData && orderData.location) {
    LocationService.setEventLocation(orderData.location);
  }

  let tour = tourReducer.constructInitTour(tourData, tourReducer.initialState.tour);
  tour = tourReducer.parseTour(tour);
  const tourEvents = tour && tour.events ? tour.events : [];
  const featuredEvents = tour && tour.featuredEvents ? tour.featuredEvents : [];
  const totalEventSize = tour && tour.totalEventSize ? tour.totalEventSize : 0;
  const tourState = {
    ...tourReducer.initialState,
    tour,
    tourEvents,
    featuredEvents,
    totalEventSize
  };

  const store = createStore(
    reducer,
    {
      event: eventIntState,
      order: orderIntState,
      ticketSelection: ticketSelectionIntState,
      orderSummary: orderSummaryIntDate,
      merchandise: merchandiseIntState,
      tours: tourState,
      giftCards: giftCardInitialState
    },
    composeEnhancers(applyMiddleware(thunk))
  );
  store.dispatch(constructRouteStack());
  postHook(store);
  return store;
};

export default create;
