import { h } from "preact";

import Page from "./Page";
import Content from "./Content";

const PageLoading = () => {
  return (
    <Page>
      <Content style={{ padding: 30 }}>
        <div class="loading loading-lg" />
      </Content>
    </Page>
  );
};

export default PageLoading;
