import AttendeeProfileService from "../../services/AttendeeProfileService";
import LocationService from "../../services/LocationService";
import { GET_ORDER_SUCCESS } from "./orderActions";
import { nextPage } from "./routingActions";
import { GET_EVENT_SUCCESS } from "./eventActions";
export const CHECK_ATTENDEE_PROFILE_REQUEST = "CHECK_ATTENDEE_PROFILE_REQUEST";
export const CHECK_ATTENDEE_PROFILE_ERROR = "CHECK_ATTENDEE_PROFILE_SUCCESS";
export const CHECK_ATTENDEE_PROFILE_SUCCESS = "CHECK_ATTENDEE_PROFILE_ERROR";

export const CREATE_ATTENDEE_PROFILE_REQUEST = "CREATE_ATTENDEE_PROFILE_REQUEST";
export const CREATE_ATTENDEE_PROFILE_ERROR = "CREATE_ATTENDEE_PROFILE_ERROR";
export const CREATE_ATTENDEE_PROFILE_SUCCESS = "CREATE_ATTENDEE_PROFILE_SUCCESS";

export const ASSIGN_ATTENDEE_PROFILE_REQUEST = "ASSIGN_ATTENDEE_PROFILE_REQUEST";
export const ASSIGN_ATTENDEE_PROFILE_ERROR = "ASSIGN_ATTENDEE_PROFILE_ERROR";
export const ASSIGN_ATTENDEE_PROFILE_SUCCESS = "ASSIGN_ATTENDEE_PROFILE_SUCCESS";

export const ADD_EXISTING_ATTENDEE = "ADD_EXISTING_ATTENDEE";
export const CLEAR_EXISTING_ATTENDEE = "CLEAR_EXISTING_ATTENDEE";

export const OPEN_ATTENDEE_MODAL = "OPEN_ATTENDEE_MODAL";
export const CLOSE_ATTENDEE_MODAL = "CLOSE_ATTENDEE_MODAL";

export const GET_ASSIGNED_PROFILES_REQUEST = "GET_ASSIGNED_PROFILES_REQUEST";
export const GET_ASSIGNED_PROFILES_SUCCESS = "GET_ASSIGNED_PROFILES_SUCCESS";
export const GET_ASSIGNED_PROFILES_ERROR = "GET_ASSIGNED_PROFILES_ERROR";

export const UPDATE_PROFILE_REQUEST = "UPDATE_PROFILE_REQUEST";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_ERROR = "UPDATE_PROFILE_ERROR";

export const UNASSIGN_TICKET_REQUEST = "UNASSIGN_TICKET_REQUEST";
export const UNASSIGN_TICKET_SUCCESS = "UNASSIGN_TICKET_SUCCESS";
export const UNASSIGN_TICKET_ERROR = "UNASSIGN_TICKET_ERROR";

export const GET_ATTENDEE_PROFILE_REQUEST = "GET_ATTENDEE_PROFILE_REQUEST";
export const GET_ATTENDEE_PROFILE_SUCCESS = "GET_ATTENDEE_PROFILE_SUCCESS";
export const GET_ATTENDEE_PROFILE_ERROR = "GET_ATTENDEE_PROFILE_ERROR";

export const UPDATE_ASSIGNED_ADDITIONAL_QUESTIONS_REQUEST = "UPDATE_ASSIGNED_ADDITIONAL_QUESTIONS_REQUEST";
export const UPDATE_ASSIGNED_ADDITIONAL_QUESTIONS_SUCCESS = "UPDATE_ASSIGNED_ADDITIONAL_QUESTIONS_SUCCESS";
export const UPDATE_ASSIGNED_ADDITIONAL_QUESTIONS_ERROR = "UPDATE_ASSIGNED_ADDITIONAL_QUESTIONS_ERROR";

export const UPDATE_ADDITIONAL_QUESTIONS_REQUEST = "UPDATE_ADDITIONAL_QUESTIONS_REQUEST";
export const UPDATE_ADDITIONAL_QUESTIONS_SUCCESS = "UPDATE_ADDITIONAL_QUESTIONS_SUCCESS";
export const UPDATE_ADDITIONAL_QUESTIONS_ERROR = "UPDATE_ADDITIONAL_QUESTIONS_ERROR";

export const OPEN_ADDITIONAL_QUESTIONS = "OPEN_ADDITIONAL_QUESTIONS";
export const CLOSE_ADDITIONAL_QUESTIONS = "CLOSE_ADDITIONAL_QUESTIONS";

export const CHECK_ORDER_COMPLETE_REQUEST = "CHECK_ORDER_COMPLETE_REQUEST";
export const CHECK_ORDER_COMPLETE_SUCCESS = "CHECK_ORDER_COMPLETE_SUCCESS";
export const CHECK_ORDER_COMPLETE_ERROR = "CHECK_ORDER_COMPLETE_ERROR";

export const SET_TOKEN = "SET_TOKEN";

const getToken = (getState) => {
  const state = getState();
  return state.attendeeProfile.token;
};

export const createIdentifier = (profileData) => {
  const normalisedFirstName = String(profileData.firstName).toLowerCase().trim();
  const normalisedLastName = String(profileData.lastName).toLowerCase().trim();
  const normalisedEmail = String(profileData.email).toLowerCase().trim();
  return `${normalisedFirstName}${normalisedLastName}${normalisedEmail}`;
};

export const loadAttendeeProfileData = (orderId, eventId, attendeeProfileId, token) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_ATTENDEE_PROFILE_REQUEST });
      const { tickets, attendeeProfile, event } = await AttendeeProfileService.getAttendeeData(
        orderId,
        eventId,
        attendeeProfileId,
        token
      );
      LocationService.setEventLocation(event.location);
      dispatch({
        type: GET_ATTENDEE_PROFILE_SUCCESS,
        profile: attendeeProfile
      });
      dispatch({ type: GET_EVENT_SUCCESS, event });
      dispatch({ type: GET_ORDER_SUCCESS, order: { _id: orderId, tickets } });
    } catch (err) {
      dispatch({ type: GET_ATTENDEE_PROFILE_ERROR, error: err });
    }
  };
};

export const updateAttendeeProfilePublic = (routeParams, profileData) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_PROFILE_REQUEST });
      const profile = await AttendeeProfileService.updateAttendeeProfilePublic(routeParams, profileData);
      dispatch({ type: GET_ATTENDEE_PROFILE_SUCCESS, profile });
      dispatch({ type: UPDATE_PROFILE_SUCCESS });
    } catch (err) {
      dispatch({ type: UPDATE_PROFILE_ERROR, error: err });
    }
  };
};

export const updateAdditionalQuestionPublic = (routeParams, tickets) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_ADDITIONAL_QUESTIONS_REQUEST });
      const updatedTickets = await AttendeeProfileService.updateAdditionalQuestionPublic(routeParams, tickets);
      dispatch({ type: GET_ORDER_SUCCESS, order: { tickets: updatedTickets } });
      dispatch({ type: UPDATE_ADDITIONAL_QUESTIONS_SUCCESS });
    } catch (err) {
      dispatch({ type: UPDATE_ADDITIONAL_QUESTIONS_ERROR, error: err });
    }
  };
};

export const finishAssigning = (orderId, ticketAssigningData) => {
  return async (dispatch, state) => {
    try {
      const token = getToken(state);
      dispatch({ type: CHECK_ORDER_COMPLETE_REQUEST });
      const additionalQuestionsData = Object.keys(ticketAssigningData).map((ticketId) => {
        const normalisedAdditionalQuestions = Object.keys(ticketAssigningData[ticketId]).map((key) => {
          return {
            questionId: key,
            value: ticketAssigningData[ticketId][key]
          };
        });
        return {
          ticketId,
          additionalQuestions: normalisedAdditionalQuestions
        };
      });
      await AttendeeProfileService.addAdditionalQuestions(orderId, additionalQuestionsData, token);
      dispatch({ type: CHECK_ORDER_COMPLETE_SUCCESS });
      dispatch(nextPage());
    } catch (err) {
      dispatch({ type: CHECK_ORDER_COMPLETE_ERROR, error: err });
    }
  };
};

export const updateAdditionalQuestions = (eventId, orderId, ticketAssigningData, token, attendeeProfileId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_ASSIGNED_ADDITIONAL_QUESTIONS_REQUEST });
      const additionalQuestionsData = Object.keys(ticketAssigningData).map((ticketId) => {
        const normalisedAdditionalQuestions = Object.keys(ticketAssigningData[ticketId]).map((key) => {
          return {
            questionId: key,
            value: ticketAssigningData[ticketId][key]
          };
        });
        return {
          ticketId,
          additionalQuestions: normalisedAdditionalQuestions
        };
      });
      const updatedOrder = await AttendeeProfileService.updateProfileAdditionalQuestions(
        eventId,
        orderId,
        attendeeProfileId,
        additionalQuestionsData,
        token
      );
      dispatch({ type: GET_ORDER_SUCCESS, order: updatedOrder });
      dispatch({
        type: UPDATE_ASSIGNED_ADDITIONAL_QUESTIONS_SUCCESS,
        order: updatedOrder
      });
    } catch (err) {
      dispatch({
        type: UPDATE_ASSIGNED_ADDITIONAL_QUESTIONS_ERROR,
        error: err
      });
    }
  };
};

export const setToken = (token) => {
  return (dispatch) => {
    dispatch({ type: SET_TOKEN, token });
  };
};

export const openAdditionalQuestions = (ticketId) => {
  return (dispatch) => {
    dispatch({ type: OPEN_ADDITIONAL_QUESTIONS, ticketId });
  };
};

export const closeAdditionalQuestions = (ticketId) => {
  return (dispatch) => {
    dispatch({ type: CLOSE_ADDITIONAL_QUESTIONS, ticketId });
  };
};

export const updateAssignedAdditionalQuestions = (ticketId, additionalQuestionsObj) => {
  return async (dispatch, getState) => {
    try {
      const normalisedAdditionalQuestions = Object.keys(additionalQuestionsObj).map((key) => {
        return {
          questionId: key,
          value: additionalQuestionsObj[key]
        };
      });
      dispatch({
        type: UPDATE_ASSIGNED_ADDITIONAL_QUESTIONS_REQUEST,
        ticketId
      });
      const order = await AttendeeProfileService.updateAssignedAdditionalQuestions(
        ticketId,
        normalisedAdditionalQuestions,
        getToken(getState)
      );
      dispatch({
        type: UPDATE_ASSIGNED_ADDITIONAL_QUESTIONS_SUCCESS,
        ticketId
      });
      dispatch({ type: GET_ORDER_SUCCESS, order });
    } catch (err) {
      dispatch({
        type: UPDATE_ASSIGNED_ADDITIONAL_QUESTIONS_ERROR,
        error: err,
        ticketId
      });
    }
  };
};

export const openModal = (defaultValues, ticketId, isNew = true) => {
  return (dispatch) => {
    dispatch({ type: OPEN_ATTENDEE_MODAL, defaultValues, ticketId, isNew });
  };
};

export const closeModal = () => {
  return (dispatch) => {
    dispatch({ type: CLOSE_ATTENDEE_MODAL });
  };
};

export const updateProfile = (orderId, attendeeProfileId, profile) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: UPDATE_PROFILE_REQUEST });
      const profiles = await AttendeeProfileService.updateProfile(
        orderId,
        attendeeProfileId,
        profile,
        getToken(getState)
      );
      if (profiles.error) {
        dispatch({ type: UPDATE_PROFILE_ERROR, error: profiles.message });
      } else {
        dispatch({ type: UPDATE_PROFILE_SUCCESS, profiles });
        closeModal()(dispatch);
      }
    } catch (err) {
      dispatch({ type: UPDATE_PROFILE_ERROR, error: err });
    }
  };
};

export const getAssignedProfiles = (orderId) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: GET_ASSIGNED_PROFILES_REQUEST });
      const profiles = await AttendeeProfileService.getAssignedProfiles(orderId, getToken(getState));
      dispatch({ type: GET_ASSIGNED_PROFILES_SUCCESS, profiles });
    } catch (err) {
      dispatch({ type: GET_ASSIGNED_PROFILES_ERROR, error: err });
    }
  };
};

export const addExistingAttendee = (ticketId, profile) => {
  return (dispatch, getState) => {
    assignAttendee(ticketId, profile._id)(dispatch, getState);
    dispatch({ type: ADD_EXISTING_ATTENDEE, profile });
  };
};

export const checkForExistingAttendee = (profile, ticketId) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const profiles = state.attendeeProfile.profiles;
      const identifier = createIdentifier(profile);
      const attendeeProfile = profiles.find((profile) => profile.profileIdentifier === identifier);
      if (attendeeProfile) {
        assignAttendee(ticketId, attendeeProfile._id)(dispatch, getState);
        closeModal()(dispatch);
      } else {
        dispatch({ type: CHECK_ATTENDEE_PROFILE_REQUEST });
        const profileCheck = await AttendeeProfileService.check(identifier);
        dispatch({
          type: CHECK_ATTENDEE_PROFILE_SUCCESS,
          profile: profileCheck.profile,
          ticketId
        });
        if (!profileCheck.profileFound) {
          createAndAssignAttendee(ticketId, profile)(dispatch, getState);
        } else {
          closeModal()(dispatch);
        }
      }
    } catch (err) {
      dispatch({ type: CHECK_ATTENDEE_PROFILE_ERROR, error: err });
    }
  };
};

export const createAndAssignAttendee = (ticketId, profileData) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: CREATE_ATTENDEE_PROFILE_REQUEST });
      const { order, profile } = await AttendeeProfileService.createAndAssign(
        ticketId,
        profileData,
        getToken(getState)
      );
      dispatch({ type: CREATE_ATTENDEE_PROFILE_SUCCESS, order, profile });
      dispatch({ type: GET_ORDER_SUCCESS, order });
      closeModal()(dispatch);
    } catch (err) {
      dispatch({ type: CREATE_ATTENDEE_PROFILE_ERROR, error: err });
    }
  };
};

export const unAssignTicket = (ticketId, closeModalAfter) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: UNASSIGN_TICKET_REQUEST });
      const order = await AttendeeProfileService.unAssignTicket(ticketId, getToken(getState));
      dispatch({ type: UNASSIGN_TICKET_SUCCESS, order, ticketId });
      dispatch({ type: GET_ORDER_SUCCESS, order });
      if (closeModalAfter) {
        closeModal()(dispatch);
      }
    } catch (err) {
      dispatch({ type: UNASSIGN_TICKET_ERROR, error: err });
    }
  };
};

export const assignAttendee = (ticketId, profileId) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: ASSIGN_ATTENDEE_PROFILE_REQUEST, ticketId });
      const order = await AttendeeProfileService.assign(ticketId, profileId, getToken(getState));
      dispatch({ type: ASSIGN_ATTENDEE_PROFILE_SUCCESS, order, ticketId });
      dispatch({ type: GET_ORDER_SUCCESS, order });
    } catch (err) {
      dispatch({ type: ASSIGN_ATTENDEE_PROFILE_ERROR, error: err });
    }
  };
};

export const clearAttendee = () => {
  return async (dispatch) => {
    dispatch({ type: CLEAR_EXISTING_ATTENDEE });
  };
};
