import { h, Component } from "preact";
import { Field, reduxForm } from "redux-form";
import OptionsField from "../fields/OptionsField";
import { connect } from "react-redux";
import { addToCalendar } from "../../state/actions/orderActions";
import Button from "../Button";
import { css } from "emotion";
import { lastOfType, max } from "../../utils/CssUtils";

const optionFields = [
  { label: "Apple", value: "apple" },
  { label: "Google", value: "google" },
  { label: "Yahoo", value: "yahoo" },
  { label: "Outlook", value: "outlook" }
];

const allowedCalendars = ["apple", "google", "yahoo", "outlook"];

const dateBlock = css({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "8px 0px",
  borderBottom: "1px solid #dedede",
  [lastOfType()]: {
    borderBottom: "none"
  },
  div: {
    marginRight: 24,
    display: "flex",
    flexDirection: "column"
  }
});

const formWrapper = css({
  minWidth: 456,
  [max(560)]: {
    minWidth: 0
  }
});

const formName = "dateSelectorForm";
class DateSelectorForm extends Component {
  render() {
    const { calendarInformation, eventName } = this.props;
    return (
      <form className={formWrapper}>
        <Field
          label="Select a calendar"
          name="calendar"
          required
          component={OptionsField}
          options={optionFields}
          formName={formName}
        />
        {calendarInformation.success ? (
          <>
            <label style={{ marginTop: 8 }}>
              <b>Select dates you want to add to calendar</b>
            </label>
            {calendarInformation.result
              .sort((a, b) => new Date(a.start) - new Date(b.start))
              .map((res) => (
                <div className={dateBlock}>
                  <div>
                    <label>{res.display.combined}</label>
                    <b>{res.name ? `(${res.name})` : eventName}</b>
                  </div>
                  <Button type="secondary" size="small" link={res.link} target="_blank">
                    Add
                  </Button>
                </div>
              ))}
          </>
        ) : null}
      </form>
    );
  }
}
DateSelectorForm = reduxForm({
  form: formName,
  destroyOnUnmount: false,
  onChange: (values, dispatch, props, previousValues) => {
    values.token = window.token;
    if (values.calendar) {
      props.addToCalendar(values);
    }
  }
})(DateSelectorForm);

export default connect(
  (state, ownProps) => {
    return {
      initialValues: {
        eventId: state.event.id,
        orderId: state.order && state.order.data && state.order.data._id,
        calendar:
          ownProps && ownProps.matches && ownProps.matches.c && allowedCalendars.includes(ownProps.matches.c)
            ? ownProps.matches.c
            : undefined,
        isPublic: !!(ownProps && ownProps.isPublic)
      },
      calendarInformation: state.order.addToCalendar,
      eventName: state.event.data.name,
      userId: state.event.data.userId
    };
  },
  { addToCalendar }
)(DateSelectorForm);
