import { h } from "preact";
import { useEffect } from "preact/hooks";
import { css } from "emotion";
import { connect } from "react-redux";

const wrapper = css({
  width: "100% !important",
  maxHeight: 314,
  paddingLeft: 12,
  border: "1px solid #DEDEDE",
  borderRadius: 4,
  iframe: {
    maxHeight: 312,
    width: "100% !important",
    marginBottom: 40
  }
});

const linkedInWrapper = css({
  maxWidth: 396,
  width: "100% !important",
  marginBottom: 8
});

const LinkedInScriptTag = () => (
  <div className={wrapper}>
    <script
      type="text/javascript"
      async="true"
      data-name="li-quickbind-frame"
      data-api-key="86n3x91onf3of6"
      data-event-id="${currentEventLinkedInSettings.linkedInEventId}"
      data-registrant-first-name="Max"
      data-registrant-last-name="Mustermann"
      data-registrant-email="max.mustermann@example.com"
      data-registrant-title="Principal Analyst"
      data-registrant-company="Business Co."
      data-locale="en_US"
      data-success-callback="success_callback"
    ></script>
  </div>
);

const LinkedInAttending = ({ currentEventLinkedInSettings, completePage }) => {
  useEffect(() => {
    let js,
      fjs = document.querySelector('script[data-name="li-quickbind-frame"]');
    if (!fjs) {
      return;
    }
    js = document.createElement("script");
    js.src = "https://www.linkedin.com/quickbind/init";
    fjs.parentNode.insertBefore(js, fjs);
  }, []);
  if (!currentEventLinkedInSettings?.linkedInEventEnabled || !currentEventLinkedInSettings?.linkedInEventId)
    return null;

  return completePage ? (
    <div className={linkedInWrapper}>
      <LinkedInScriptTag />
    </div>
  ) : (
    <>
      <LinkedInScriptTag />
    </>
  );
};

export default connect((state) => {
  const currentEventLinkedInSettings = state?.event?.data?.linkedInSettings;
  return {
    currentEventLinkedInSettings
  };
}, {})(LinkedInAttending);
