import OrganiserService from "../../services/OrganiserService";
import LocationService from "../../services/LocationService";

export const GET_ORGANISER_SUCCESS = "GET_ORGANISER_SUCCESS";
export const GET_ORGANISER_FAILURE = "GET_ORGANISER_FAILURE";
export const GET_ORGANISER_LOADING = "GET_ORGANISER_LOADING";
export const GET_EVENTS_SUCCESS = "GET_EVENTS_SUCCESS";
export const GET_EVENTS_FAILURE = "GET_EVENTS_FAILURE";
export const GET_EVENTS_LOADING = "GET_EVENTS_LOADING";

export const getOrganiser = (id) => {
  return (dispatch) => {
    dispatch({ type: GET_ORGANISER_LOADING });
    OrganiserService.get(id)
      .then((organiser) => {
        LocationService.setEventLocation(organiser.location);
        dispatch({ type: GET_ORGANISER_SUCCESS, organiser });
      })
      .catch((err) => {
        dispatch({ type: GET_ORGANISER_FAILURE });
      });
  };
};

export const loadEventsFromOrganiser = (organiserId, page = 0) => {
  return (dispatch) => {
    dispatch({ type: GET_EVENTS_LOADING, page });
    OrganiserService.getEventsByOrganiserId(organiserId, page)
      .then((response) => {
        dispatch({
          type: GET_EVENTS_SUCCESS,
          events: response.events,
          page: response.page,
          pageSize: response.pageSize,
          total: response.total
        });
      })
      .catch((err) => {
        dispatch({ type: GET_EVENTS_FAILURE });
      });
  };
};
