import DiscoverNSWService from "../../services/DiscoverNSWService";

export const ADD_DISCOVER_NSW_REQUEST = "ADD_DISCOVER_NSW_REQUEST";
export const ADD_DISCOVER_NSW_SUCCESS = "ADD_DISCOVER_NSW_SUCCESS";
export const ADD_DISCOVER_NSW_ERROR = "ADD_DISCOVER_NSW_ERROR";
export const REMOVE_DISCOVER_NSW_REQUEST = "REMOVE_DISCOVER_NSW_REQUEST";
export const REMOVE_DISCOVER_NSW_SUCCESS = "REMOVE_DISCOVER_NSW_SUCCESS";
export const REMOVE_DISCOVER_NSW_ERROR = "REMOVE_DISCOVER_NSW_ERROR";

import { pay, payClicked } from "./orderActions";
import { loadSummary } from "./orderSummaryActions";

export const add = (voucherCode, orderId) => {
  return async (dispatch, getState) => {
    dispatch({ type: ADD_DISCOVER_NSW_REQUEST });
    try {
      const voucher = await DiscoverNSWService.add(voucherCode, orderId);
      if (!voucher) {
        dispatch({
          type: ADD_DISCOVER_NSW_ERROR,
          error: { message: "Failed to add voucher" }
        });
      }
      if (!voucher.code) {
        dispatch({
          type: ADD_DISCOVER_NSW_ERROR,
          error: { message: "Failed to add voucher" }
        });
        return;
      }
      dispatch({ type: ADD_DISCOVER_NSW_SUCCESS, voucher });
      loadSummary()(dispatch, getState);
    } catch (err) {
      dispatch({ type: ADD_DISCOVER_NSW_ERROR, error: err });
    }
  };
};

export const remove = (orderId) => {
  return async (dispatch, getState) => {
    dispatch({ type: REMOVE_DISCOVER_NSW_REQUEST });
    try {
      const order = await DiscoverNSWService.remove(orderId);
      dispatch({ type: REMOVE_DISCOVER_NSW_SUCCESS, order });
      loadSummary()(dispatch, getState);
    } catch (err) {
      dispatch({ type: REMOVE_DISCOVER_NSW_ERROR, error: err });
    }
  };
};

export const payVoucher = (order, event) => {
  return (dispatch) => {
    dispatch(payClicked());
    dispatch(pay(order.data._id, "till", {}, event.data._id, false));
  };
};
