import axios from "./AxiosWrapper";
import config from "../config";

const GifCardService = {
  async getPage(slug) {
    return new Promise((res, rej) => {
      axios
        .get(`${config("API_URL")}gift-cards/page/${slug}`)
        .then((response) => {
          res(response.data);
        })
        .catch((err) => {
          rej(err);
        });
    });
  },
  async getEvents(giftCardTypeId, page) {
    return new Promise((res, rej) => {
      axios
        .get(`${config("API_URL")}gift-cards/events/${giftCardTypeId}`, {
          params: { page }
        })
        .then((response) => {
          res(response.data);
        })
        .catch((err) => {
          rej(err);
        });
    });
  }
};

export default GifCardService;
