import axios from "./AxiosWrapper";
import config from "../config";

const TipFeesService = {
  async add(orderId, paymentIntentId) {
    try {
      const response = await axios.post(`${config("API_URL")}payments/tipfees/add`, {
        orderId,
        paymentIntentId
      });
      return response.data;
    } catch (err) {
      throw err.response.data;
    }
  },
  async remove(orderId, paymentIntentId) {
    try {
      const response = await axios.post(`${config("API_URL")}payments/tipfees/remove`, {
        orderId,
        paymentIntentId
      });
      return response.data;
    } catch (err) {
      throw err.response.data;
    }
  }
};

export default TipFeesService;
