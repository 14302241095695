export default (key, location) => {
  const getValue = (fullKey) => {
    let value = defaults[fullKey];
    if (typeof window !== "undefined") {
      value = window.config && window.config[fullKey] !== undefined ? window.config[fullKey] : defaults[fullKey];
    }
    return value;
  };
  const regionEnvName = `${key}${location ? `_${location}` : ""}`;
  const globalEnvName = key;
  const regionValue = getValue(regionEnvName);
  return regionValue ? regionValue : getValue(globalEnvName);
};

const defaults = {
  API_URL: "http://localhost:3000/api/",
  TICKETING_URL: "http://localhost:8080",
  WEBSITE_URL: "http://localhost:7070",
  CONSOLE_URL: "http://localhost:3001",
  NODE_ENV: "staging",
  DEV: true,
  PAYPAL_ENV: "production",
  STRIPE_APK_KEY: "pk_test_xtsRcoIILJ6EWSxRgSEgnosm",
  STRIPE_APK_KEY_US:
    "pk_test_51LHjA3JccaCXnkY6O9AVoUvtaFedPE5pYT7HQRd32ANM7KwsDgcDZwmn663gGwNf87hA6chmTaVANYwvyHhhYWL400JVb3CK5B",
  STRIPE_PAYMENTS_APK_KEY: "pk_test_ypnO6rkaWXTx2279MA2P8evA",
  STRIPE_PAYMENTS_APK_KEY_NZ: "pk_test_ypnO6rkaWXTx2279MA2P8evA",
  CURRENCY: "AUD",
  ZIPMONEY_LIBARY_URL: "https://static.zipmoney.com.au/checkout/checkout-v1.min.js",
  ZIPMONEY_ENV: "sandbox",
  ORDER_COMPLETE_TIME: 20,
  GOOGLE_API_KEY: "AIzaSyBrLKaAEokK10u3KCRKI0WJyXUN6iAT6AI",
  FILESTACK_KEY: "AeJa0mS9cRHyQaj6WeE3yz",
  REDIRECT_TIME: 7000,
  TILL_PUBLIC_INTEGRATION: "ga4TB41hkFyCOh8LsGr0",
  DISCOVER_NSW_ENABLED: "true",
  LOCATION_SEARCH_DISABLE_DATE: "2021-05-14T00:00:00.000Z",
  AFTERPAY_MAX_ORDER_VALUE_AU: 2000,
  AFTERPAY_MAX_ORDER_VALUE_US: 1000,
  AFTERPAY_MAX_ORDER_VALUE_NZ: 2000,
  TILL_GATEWAY_GOOGLE_PAY_MERCHANT_ID: "ME-0700-5ac0-b47e-bcc5-9215-81f7",
  GOOGLE_PAY_MERCHANT_ID: "12345678901234567890"
};
