import { h } from "preact";
import { Link } from "preact-router/match";

import { checkObjNotEmpty } from "../utils/ObjectUtils";

const EventLinks = ({ event }) => {
  const links = [];

  if (event.accessibility) {
    links.push({
      label: "ACCESSIBILITY",
      path: `/${event.slug}/accessibility`
    });
  }

  const displayLink = checkObjNotEmpty(event.accessibility);

  if (!links.length || !displayLink) {
    return null;
  }

  return (
    <div style={{ marginBottom: 40, marginTop: 40 }}>
      <h3 style={{ marginBottom: 16, fontWeight: 600 }}>Accessibility Information</h3>
      {links.map((link) => {
        const linkStyle = {
          marginRight: 20,
          fontWeight: 600,
          color: "#196097"
        };
        if (link.icon) {
          linkStyle.padding = 2;
          linkStyle.paddingLeft = 26;
          // TODO: 5871 follow up - investigate potential bugs/cleanup
          // eslint-disable-next-line no-undef
          linkStyle.background = `url(${iconAccessibility}) no-repeat`;
          linkStyle.backgroundSize = 22;
        }
        return (
          <Link style={linkStyle} href={link.path}>
            {link.label}
          </Link>
        );
      })}
    </div>
  );
};
export default EventLinks;
