import { SET_ROUTE_LIST, NEXT_ROUTE, ROUTE_MOVE } from "../actions/routingActions";

const initialState = {
  routeList: [],
  loading: false,
  selectedStep: 0
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ROUTE_LIST: {
      return {
        ...state,
        loading: false,
        routeList: action.routeList,
        selectedStep: action.selectedStep
      };
    }
    case NEXT_ROUTE: {
      return {
        ...state,
        selectedStep: action.selectedStep
      };
    }
    case ROUTE_MOVE: {
      return {
        ...state,
        selectedStep: action.selectedStep < 0 ? 0 : action.selectedStep
      };
    }
    default: {
      return state;
    }
  }
};

export default {
  initialState,
  reducer
};
