const IconFollower = ({ color = "#3F3E41", style = {} }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <path
        d="M13.854 0.584229C6.60541 0.584229 0.707886 6.48176 0.707886 13.7303C0.707886 20.9788 6.60541 26.8764 13.854 26.8764C21.1025 26.8764 27 20.9788 27 13.7303C27 6.48176 21.1025 0.584229 13.854 0.584229ZM13.854 2.6067C20.0083 2.6067 24.9775 7.57591 24.9775 13.7303C24.9775 19.8847 20.0083 24.8539 13.854 24.8539C7.69957 24.8539 2.73036 19.8847 2.73036 13.7303C2.73036 7.57591 7.69957 2.6067 13.854 2.6067ZM13.854 5.64041C11.0731 5.64041 8.79777 7.91569 8.79777 10.6966C8.79777 12.2296 9.51272 13.5918 10.5988 14.5201C9.44648 15.1174 8.48046 16.0199 7.80618 17.129C7.1319 18.238 6.7753 19.511 6.7753 20.8089H8.79777C8.79777 18.0048 11.0498 15.7528 13.854 15.7528C16.6581 15.7528 18.9101 18.0048 18.9101 20.8089H20.9326C20.9326 18.0786 19.3844 15.7022 17.1091 14.5201C18.1952 13.5918 18.9101 12.2296 18.9101 10.6966C18.9101 7.91569 16.6349 5.64041 13.854 5.64041ZM13.854 7.66288C15.5407 7.66288 16.8877 9.00985 16.8877 10.6966C16.8877 12.3833 15.5407 13.7303 13.854 13.7303C12.1672 13.7303 10.8202 12.3833 10.8202 10.6966C10.8202 9.00985 12.1672 7.66288 13.854 7.66288Z"
        fill={color}
      />
    </svg>
  );
};

export default IconFollower;
