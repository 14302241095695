import axios from "./AxiosWrapper";
import config from "../config";
import { fetchManualOrderSettings } from "../utils/ManualOrderUtils";

export const getHashParam = () => {
  const h = ((window.location.search.split("?")[1] || "").split("h=")[1] || "").split("&")[0];
  return h;
};

const SeatingMapService = {
  saveTicketMapping(ticketMappingData, seatingMapId, eventId, eventDateId, token) {
    const manualOrderSettings = fetchManualOrderSettings();
    const hashParam = getHashParam();
    const editingSeatingMap = hashParam !== "";
    const skipEmails = manualOrderSettings?.skipEmails || editingSeatingMap || false;
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${config("API_URL")}seatingmaps/ticketmapping/${eventId}/${eventDateId}/${seatingMapId}?h=${hashParam}`,
          {
            ticketMapping: ticketMappingData,
            skipEmails
          },
          { params: { token } }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });
  },

  async getPreview(eventId) {
    const response = await axios.get(`${config("API_URL")}seatingmaps/preview/${eventId}`);
    return response.data;
  },
  getAll(eventId, eventDateId, orderId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${config("API_URL")}seatingmaps/all/${eventId}/${eventDateId}/${orderId}`)
        .then((response) => resolve(response.data))
        .catch((err) => reject(err));
    });
  },
  async getPublic(eventId, token, orderId) {
    const response = await axios.get(`${config("API_URL")}public/seating-map/${eventId}/${orderId}/${token}`);
    return response.data;
  }
};

export default SeatingMapService;
