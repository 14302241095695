import { h, Component } from "preact";
import DateModal from "./DateModal";
import { css } from "emotion";

const linkClass = css({
  textDecoration: "none",
  fontWeight: 600
});

const Date = ({ date, singleLine = false }) => {
  const styles = {
    container: {
      margin: "0px 0"
    },
    date: {
      marginBottom: 2
    }
  };
  return (
    <div style={styles.container}>
      {date && date.display ? (
        <div>
          <div style={styles.date}>{date.display.combined}</div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

class DatesDisplay extends Component {
  constructor() {
    super();
    // set initial time:
    this.state = {
      showAllDates: false,
      singleLine: false
    };
    this.togglesDates = this.togglesDates.bind(this);
  }

  togglesDates() {
    this.setState({ showAllDates: !this.state.showAllDates });
  }

  render({ dates, singleLine, eventSlug }, { showAllDates }) {
    // create valid dates array
    const validDates = dates.filter((date) => {
      return date.valid;
    });
    const displayDate = validDates && validDates.length ? validDates[0] : dates[0];
    // select the first one and if null use first date
    return (
      <div>
        <span>
          <Date date={displayDate} singleLine={singleLine} />
        </span>

        {dates.length > 1 && !singleLine ? (
          <p style={{ marginBottom: 4 }}>
            <a data-cy="more-dates" className={linkClass} onClick={this.togglesDates} href="#">
              Show more dates for this event
            </a>
          </p>
        ) : (
          ""
        )}
        <DateModal
          open={showAllDates}
          closeHandler={this.togglesDates}
          title={"More dates"}
          dates={dates}
          eventSlug={eventSlug}
        />
      </div>
    );
  }
}
export default DatesDisplay;
