import * as ax from "axios";
import { HumaniDate } from "@hx/dates";

class axios {
  static axiosInstance = null;

  static getInstance() {
    if (!axios.axiosInstance) {
      axios.axiosInstance = ax.create({
        headers: {
          "x-browser-timezone": HumaniDate.getBrowserTimezone()
        }
      });
    }
    return axios.axiosInstance;
  }
}

export default axios.getInstance();
