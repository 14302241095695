import { h, Component } from "preact";
import { css } from "emotion";
const dateIcon = "https://static.humanitix.com/ticketing/ic_date.svg";
const accessibilityIcon = "https://static.humanitix.com/ticketing/accessibility/ic_accessibility.svg";

import { checkObjNotEmpty } from "../utils/ObjectUtils";
import DateModal from "./DateModal";
import PriceRangeDisplay from "./PriceRangeDisplay";

const wrapper = css({
  width: 300,
  height: 360,
  float: "right",
  display: "inline",
  overflow: "hidden",
  position: "relative",
  backgroundColor: "#fafafa",
  borderRadius: 4,
  padding: "32px 16px 12px 16px",
  "@media (max-width: 1030px)": {
    display: "none"
  }
});

const eventOrganiser = css({
  fontSize: 12,
  fontWeight: 400,
  color: "#5a626c",
  marginBottom: 12,
  ":hover": {
    color: "#5a626c",
    cursor: "pointer"
  }
});

const eventTitle = css({
  fontSize: 20,
  fontWeight: 600,
  color: "#323232",
  marginBottom: 16,
  overflow: "hidden",
  textOverflow: "ellipsis",
  lineHeight: 1.5,
  maxHeight: 150
});

const datesWrapper = css({
  fontSize: 14,
  background: `url(${dateIcon})`,
  backgroundSize: "20px 20px",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "top left",
  paddingLeft: 24,
  fontWeight: 400,
  color: "#323232",
  marginBottom: 8
});

const accessibilityLink = css({
  background: `url(${accessibilityIcon})`,
  backgroundSize: "20px 20px",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "top left",
  paddingLeft: 24,
  fontWeight: 400,
  fontSize: 14,
  color: "#196097",
  ":hover": {
    color: "#196097"
  }
});

const priceContainer = css({
  width: "100%",
  height: 41,
  position: "absolute",
  left: 0,
  bottom: 0,
  borderTop: "solid 1px #dedede",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "0px 14px"
});

const priceLabel = css({
  fontSize: 16,
  color: "black",
  bottom: 9,
  left: 16,
  marginBottom: 0
});

const priceAmount = css({
  fontSize: 16,
  color: "black",
  float: "right",
  fontWeight: 600,
  marginBottom: 0
});

class EventInfoBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datesModalOpen: false
    };
  }

  toggleDateModal = () => {
    const { datesModalOpen } = this.state;
    this.setState({
      datesModalOpen: !datesModalOpen
    });
  };

  render() {
    const { datesModalOpen } = this.state;
    const { event } = this.props;
    if (!event) {
      return;
    }
    const displayPrice = !!(event && event.settings.ticketPriceRangeDisplay);
    const organiserName = event.organiser ? event.organiser.name : null;
    const eventDates = event.validDates && event.validDates.length && !event.suspendSales ? event.dates : false;
    const validDates = eventDates
      ? eventDates.filter((date) => {
          return date.valid;
        })
      : [];
    let displayDate = false;
    let multipleDates = false;
    if (validDates && validDates.length) {
      displayDate = `${validDates[0].display.combined}`;
      multipleDates = validDates.length > 1;
    }

    const displayAccessibility = event.accessibility ? checkObjNotEmpty(event.accessibility) : false;
    return (
      <div className={wrapper}>
        <div style={{ marginBottom: 8 }}>
          {organiserName ? (
            <a href="#organiserInfoBox" className={eventOrganiser}>
              {organiserName}
            </a>
          ) : null}
        </div>
        <div>
          <h2 className={eventTitle}>{event.name}</h2>
        </div>
        <div className={datesWrapper}>
          {displayDate}
          {multipleDates ? (
            <div>
              <a style={{ textDecoration: "underline" }} href="#" onClick={this.toggleDateModal}>
                + {validDates.length - 1} more dates
              </a>
            </div>
          ) : null}
        </div>
        {displayAccessibility ? (
          <div>
            <a href={`/${event.slug}/accessibility`} className={accessibilityLink}>
              Accessibility
            </a>
          </div>
        ) : null}
        {displayPrice && event.ticketsPriceStr ? (
          <div className={priceContainer}>
            <p className={priceLabel}>Price</p>
            <p className={priceAmount}>
              <PriceRangeDisplay event={event} />
            </p>
          </div>
        ) : (
          ""
        )}
        <DateModal
          open={datesModalOpen}
          closeHandler={this.toggleDateModal}
          title={"More dates"}
          dates={validDates}
          eventSlug={event.slug}
        />
      </div>
    );
  }
}

export default EventInfoBox;
