import axios from "./AxiosWrapper";
import config from "../config";

const TourService = {
  getTour(slug, query, token) {
    const params = { params: { query, token } };
    const result = axios.get(`${config("API_URL")}tours/${slug}`, params);
    return result;
  },
  getTourPreview(slug, query) {
    const params = { params: query };
    const result = axios.get(`${config("API_URL")}tours/preview/${slug}`, params);
    return result;
  },
  getTourFilterOptions(slug, query) {
    const params = { params: query };
    const result = axios.get(`${config("API_URL")}tours/tour-options/${slug}`, params);
    return result;
  }
};

export default TourService;
