import errorTracking from "../../utils/ErrorTracking";

const GoogleTagManagerService = {
  ticketsAdded(event, order) {
    try {
      if (typeof window !== "undefined" && window.dataLayer) {
        window.dataLayer.push({
          event: "TicketsAdded",
          currency: event.currency,
          totalTickets: order.tickets.length
        });
      }
    } catch (e) {
      errorTracking.track(e);
    }
  },
  enteredDetails(event, order) {
    try {
      if (typeof window !== "undefined" && window.dataLayer) {
        window.dataLayer.push({
          event: "EnteredDetails",
          value: order.totals.total,
          currency: event.currency,
          totalTickets: order.tickets ? order.tickets.length : 0
        });
      }
    } catch (e) {
      errorTracking.track(e);
    }
  },
  checkout(event, order) {},
  complete(event, order) {
    try {
      if (typeof window !== "undefined" && window.dataLayer) {
        window.dataLayer.push({
          event: "Purchase",
          value: order.totals.total,
          currency: event.currency,
          totalTickets: order.tickets.length
        });
      }
    } catch (e) {
      errorTracking.track(e);
    }
  },
  pageViewCustom(title) {
    try {
      if (typeof window !== "undefined" && window.dataLayer) {
        window.dataLayer.push({
          event: "Pageview",
          pagePath: window.location.href,
          pageTitle: title
        });
      }
    } catch (err) {
      errorTracking.track(err);
    }
  }
};
export default GoogleTagManagerService;
