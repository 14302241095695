import queryString from "query-string";
import { route } from "preact-router";
import { fetchManualOrderSettings, createManualOrderTicketOverride } from "../../utils/ManualOrderUtils";
import OrderService from "../../services/OrderService";
import LocationService from "../../services/LocationService";
import TipFeesService from "../../services/TipFeesService";
import { destroy } from "redux-form";
import { getMerchandise } from "./merchandiseActions";
import { nextPage, hasAdditionalSteps } from "./routingActions";
import { normaliseTicketSelectionData } from "../../utils/normaliseTicketSelectionData";
import { GET_EVENT_SUCCESS, GET_EVENT_REQUEST, GET_EVENT_ERROR } from "./eventActions";
import TrackingService from "../../services/tracking/TrackingService";
export const CLEAR_ORDER = "CLEAR_ORDER";

export const GET_ORDER_REQ_REQUEST = "GET_ORDER_REQ_REQUEST";
export const GET_ORDER_REQ_SUCCESS = "GET_ORDER_REQ_SUCCESS";
export const GET_ORDER_REQ_ERROR = "GET_ORDER_REQ_ERROR";

export const SAVE_ORDER_DATA_REQUEST = "SAVE_ORDER_DATA_REQUEST";
export const SAVE_ORDER_DATA_SUCCESS = "SAVE_ORDER_DATA_SUCCESS";
export const SAVE_ORDER_DATA_ERROR = "SAVE_ORDER_DATA_ERROR";

export const PAYMENT_CLICKED = "PAYMENT_CLICKED";
export const CHECKOUT_ERROR = "CHECKOUT_ERROR";

export const PAYMENT_RESET = "PAYMENT_RESET";
export const PAYMENT_REQUEST = "PAYMENT_REQUEST";
export const PAYMENT_SUCCESS = "PAYMENT_SUCCESS";
export const PAYMENT_ERROR = "PAYMENT_ERROR";

export const AUTH_KEY_REQUEST = "AUTH_KEY_REQUEST";
export const AUTH_KEY_SUCCESS = "AUTH_KEY_SUCCESS";
export const AUTH_KEY_ERROR = "AUTH_KEY_ERROR";

export const GET_ORDER_REQUEST = "GET_ORDER_REQUEST";
export const GET_ORDER_SUCCESS = "GET_ORDER_SUCCESS";
export const GET_ORDER_ERROR = "GET_ORDER_ERROR";

export const GET_DUMMY_ORDER_LOADING = "GET_DUMMY_ORDER_LOADING";
export const GET_DUMMY_ORDER_SUCCESS = "GET_DUMMY_ORDER_SUCCESS";
export const GET_DUMMY_ORDER_ERROR = "GET_DUMMY_ORDER_ERROR";

export const ADD_DISCOUNT_REQUEST = "ADD_DISCOUNT_REQUEST";
export const ADD_DISCOUNT_SUCCESS = "ADD_DISCOUNT_SUCCESS";
export const ADD_DISCOUNT_ERROR = "ADD_DISCOUNT_ERROR";

export const REMOVE_DISCOUNT_REQUEST = "REMOVE_DISCOUNT_REQUEST";
export const REMOVE_DISCOUNT_SUCCESS = "REMOVE_DISCOUNT_SUCCESS";
export const REMOVE_DISCOUNT_ERROR = "REMOVE_DISCOUNT_ERROR";

export const ADD_GIFT_CARD_REQUEST = "ADD_GIFT_CARD_REQUEST";
export const ADD_GIFT_CARD_SUCCESS = "ADD_GIFT_CARD_SUCCESS";
export const ADD_GIFT_CARD_ERROR = "ADD_GIFT_CARD_ERROR";

export const COMPLETE_GIFT_CARD_ORDER_REQUEST = "COMPLETE_GIFT_CARD_ORDER_REQUEST";
export const COMPLETE_GIFT_CARD_ORDER_ERROR = "COMPLETE_GIFT_CARD_ORDER_ERROR";
export const COMPLETE_GIFT_CARD_ORDER_SUCCESS = "COMPLETE_GIFT_CARD_ORDER_SUCCESS";

export const REMOVE_GIFT_CARD_REQUEST = "REMOVE_GIFT_CARD_REQUEST";
export const REMOVE_GIFT_CARD_SUCCESS = "REMOVE_GIFT_CARD_SUCCESS";
export const REMOVE_GIFT_CARD_ERROR = "REMOVE_GIFT_CARD_ERROR";

export const TIME_EXPIRED = "TIME_EXPIRED";

export const CHANGE_PAYMENT_METHOD = "CHANGE_PAYMENT_METHOD";

export const SWITCH_PAYMENT_REQUEST = "SWITCH_PAYMENT_REQUEST";
export const SWITCH_PAYMENT_ERROR = "SWITCH_PAYMENT_ERROR";
export const SWITCH_PAYMENT_SUCCESS = "SWITCH_PAYMENT_SUCCESS";

export const CREDIT_AND_DEBIT = "credit_and_debit";
export const INVOICING = "invoice";

export const QUANTITY_VALIDATION_ERROR = "QUANTITY_VALIDATION_ERROR";
export const QUANTITY_VALIDATION_REQUEST = "QUANTITY_VALIDATION_REQUEST";

export const ATTENDEE_ACTIONED_REFUND_REQUEST = "ATTENDEE_ACTIONED_REFUND_REQUEST";
export const ATTENDEE_ACTIONED_REFUND_SUCCESS = "ATTENDEE_ACTIONED_REFUND_SUCCESS";
export const ATTENDEE_ACTIONED_REFUND_ERROR = "ATTENDEE_ACTIONED_REFUND_ERROR";

export const ATTENDEE_REFUND_AMOUNT_REQUEST = "ATTENDEE_REFUND_AMOUNT_REQUEST";
export const ATTENDEE_REFUND_AMOUNT_SUCCESS = "ATTENDEE_REFUND_AMOUNT_SUCCESS";
export const ATTENDEE_REFUND_AMOUNT_ERROR = "ATTENDEE_REFUND_AMOUNT_ERROR";

export const ADD_TO_CALENDAR_REQUEST = "ADD_TO_CALENDAR_REQUEST";
export const ADD_TO_CALENDAR_SUCCESS = "ADD_TO_CALENDAR_SUCCESS";
export const ADD_TO_CALENDAR_ERROR = "ADD_TO_CALENDAR_ERROR";

export const TIP_CHANGE_REQUEST = "TIP_CHANGE_REQUEST";
export const TIP_CHANGE_SUCCESS = "TIP_CHANGE_SUCCESS";
export const TIP_CHANGE_ERROR = "TIP_CHANGE_ERROR";

import { loadSummary, loadGlobalSummary } from "./orderSummaryActions";
import { addPaymentInfo, addShippingInfo, beginCheckout, purchase } from "./dataLayerActions";

export const tipChange = (orderId, tipFees) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      dispatch({ type: TIP_CHANGE_REQUEST });
      const tipAction = tipFees === "true" ? TipFeesService.add : TipFeesService.remove;
      const result = await tipAction(orderId, state.stripe.paymentIntentId);

      dispatch({ type: TIP_CHANGE_SUCCESS, summary: result.summary });
    } catch (err) {
      console.error(err);
      dispatch({ type: TIP_CHANGE_ERROR, error: err });
    }
  };
};

export const timeExpired = () => {
  return (dispatch) => {
    dispatch({ type: TIME_EXPIRED });
  };
};

export const clearOrder = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_ORDER });
  };
};

export const switchGateways = (gateway) => {
  return async (dispatch, getState) => {
    const { order } = getState();
    try {
      dispatch({ type: SWITCH_PAYMENT_REQUEST, gateway });
      const gatewaySwitchResult = await OrderService.switchGateways(order.data._id, gateway);
      dispatch({ type: SWITCH_PAYMENT_SUCCESS, gatewaySwitchResult });
      loadSummary()(dispatch, getState);
    } catch (err) {
      dispatch({ type: SWITCH_PAYMENT_ERROR, error: err });
    }
  };
};

export const getOrder = (id, getMerch = false) => {
  return (dispatch) => {
    dispatch({ type: GET_ORDER_REQUEST, id });
    OrderService.get(id)
      .then((order) => {
        LocationService.setEventLocation(order.location);
        dispatch({ type: GET_ORDER_SUCCESS, order });
        if (getMerch) {
          getMerchandise(id)(dispatch);
        }
      })
      .catch((err) => {
        dispatch({ type: GET_ORDER_ERROR, error: err });
      });
  };
};

export const loadEditOrder = (location, orderId, h) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_EVENT_REQUEST });
      dispatch({ type: GET_ORDER_REQUEST });
      const { event, order } = await OrderService.loadEditOrder(location, orderId);
      LocationService.setEventLocation(event.location);
      dispatch({ type: GET_EVENT_SUCCESS, event });
      dispatch({ type: GET_ORDER_SUCCESS, order });
    } catch (err) {
      dispatch({ type: GET_ORDER_ERROR, error: err });
      dispatch({ type: GET_EVENT_ERROR, error: err });
    }
  };
};

export const getOrderPublic = (eventId, orderId, token) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_ORDER_REQUEST });
      const order = await OrderService.getPublicOrder(eventId, orderId, token);
      LocationService.setEventLocation(order.location);
      dispatch({ type: GET_ORDER_SUCCESS, order });
    } catch (err) {
      dispatch({ type: GET_ORDER_ERROR, error: err });
    }
  };
};

export const saveOrderPublic = (eventId, orderId, token, orderData) => {
  return async (dispatch) => {
    try {
      dispatch({ type: SAVE_ORDER_DATA_REQUEST });
      const order = await OrderService.savePublicOrder(eventId, orderId, token, orderData);
      dispatch({ type: SAVE_ORDER_DATA_SUCCESS, order });
    } catch (err) {
      dispatch({ type: SAVE_ORDER_DATA_ERROR, error: err });
    }
  };
};

export const addDiscount = (eventId, orderId, discountCode) => {
  return (dispatch, getState) => {
    dispatch({ type: ADD_DISCOUNT_REQUEST });
    OrderService.addDiscount(eventId, orderId, discountCode)
      .then((result) => {
        if (result && result.error && result.error === "Discount code not valid.") {
          dispatch({ type: ADD_DISCOUNT_ERROR, error: result.error });
          return;
        }
        dispatch({ type: ADD_DISCOUNT_SUCCESS, code: discountCode });
        loadSummary()(dispatch, getState);
      })
      .catch((err) => {
        const error =
          err && err.response && err.response.data && err.response.data.error ? err.response.data.error : err;
        dispatch({ type: ADD_DISCOUNT_ERROR, error });
      });
  };
};

export const removeDiscount = (orderId) => {
  return (dispatch, getState) => {
    dispatch({ type: REMOVE_DISCOUNT_REQUEST });
    OrderService.removeDiscount(orderId)
      .then((result) => {
        dispatch({ type: REMOVE_DISCOUNT_SUCCESS });
        loadSummary()(dispatch, getState);
      })
      .catch((err) => {
        const error =
          err && err.response && err.response.data && err.response.data.error ? err.response.data.error : err;
        dispatch({ type: REMOVE_DISCOUNT_ERROR, error });
      });
  };
};

export const completeGiftCardOrder = (orderId) => {
  return (dispatch, getState) => {
    dispatch({ type: COMPLETE_GIFT_CARD_ORDER_REQUEST });
    OrderService.completeOrderWithGiftCard(orderId)
      .then((result) => {
        dispatch({
          type: COMPLETE_GIFT_CARD_ORDER_SUCCESS,
          order: result.order
        });
        dispatch(nextPage());
      })
      .catch((err) => {
        const error =
          err && err.response && err.response.data && err.response.data.error ? err.response.data.error : err;
        dispatch({ type: COMPLETE_GIFT_CARD_ORDER_ERROR, error });
      });
  };
};

export const addGiftCard = (eventId, orderId, giftCardCode) => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch({ type: ADD_GIFT_CARD_REQUEST });
    OrderService.addGiftCard(eventId, orderId, giftCardCode, state.stripe.paymentIntentId)
      .then((result) => {
        if (!result.success) {
          dispatch({ type: ADD_GIFT_CARD_ERROR, error: result.error });
        } else {
          dispatch({
            type: ADD_GIFT_CARD_SUCCESS,
            isFullPayment: result.isFullPayment,
            code: giftCardCode
          });
        }
        loadSummary()(dispatch, getState);
      })
      .catch((err) => {
        const error =
          err && err.response && err.response.data && err.response.data.error ? err.response.data.error : err;
        dispatch({ type: ADD_GIFT_CARD_ERROR, error });
      });
  };
};

export const removeGiftCard = (orderId) => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch({ type: REMOVE_GIFT_CARD_REQUEST });
    OrderService.removeGiftCard(orderId, state.stripe.paymentIntentId)
      .then((result) => {
        dispatch({ type: REMOVE_GIFT_CARD_SUCCESS });
        loadSummary()(dispatch, getState);
      })
      .catch((err) => {
        const error =
          err && err.response && err.response.data && err.response.data.error ? err.response.data.error : err;
        dispatch({ type: REMOVE_GIFT_CARD_ERROR, error });
      });
  };
};

export const generateDummyOrder = (event) => {
  let ticketTypes = event.data.ticketTypes.map((ticket, index) => {
    ticket.ticketTypeName = ticket.name;
    ticket.ticketTypeId = ticket._id;
    ticket.order = index + 1;
    return ticket;
  });
  const names = [
    "Ryan",
    "Brendon",
    "Joel",
    "Arsalan",
    "Jake",
    "Ameen",
    "Raph",
    "Pierre",
    "David",
    "Shak",
    "Scott",
    "Sam"
  ];
  return (dispatch) => {
    dispatch({ type: GET_DUMMY_ORDER_LOADING });
    const dummyOrder = {
      timeExpired: false,
      data: {
        createdAt: new Date().getTime(),
        incompleteAt: new Date().getTime() + 10000000,
        tickets: ticketTypes,
        totals: {
          donation: 0,
          total: 0
        },
        eventDateId: event.data.dates[0]._id,
        firstName: names[Math.floor(Math.random() * names.length)],
        orderName: "A12345",
        completedDisplayDate: "Order date"
      }
    };
    dispatch({ type: GET_DUMMY_ORDER_SUCCESS, order: dummyOrder });
  };
};

export const requestOrder = (ticketSelection) => {
  return async (dispatch, getState) => {
    dispatch({ type: GET_ORDER_REQ_REQUEST });
    const state = getState();
    const event = state.event;
    const order = state.order;

    const eventDateId = ticketSelection.selectedDateId;
    const lastOrderId = order.data ? order.data._id : null;
    const parsed = queryString.parse(window.location.search);
    const discountCode = parsed.discountcode ? parsed.discountcode : order.discountCode;
    const manualOrderValues = {};
    if (typeof window !== "undefined") {
      manualOrderValues.token = window.token;
      manualOrderValues.isManualOrder = window.isManualOrder;
      manualOrderValues.manualOrderSettings = fetchManualOrderSettings();
      manualOrderValues.manualOrderTicketOverride = createManualOrderTicketOverride(ticketSelection);
    }
    const { requestedPackageTickets, requestedTickets, clientDonation } = normaliseTicketSelectionData(ticketSelection);
    try {
      const orderRequestResult = await OrderService.request({
        eventId: event.data._id,
        eventDateId,
        requestedTickets,
        requestedPackageTickets,
        clientDonation,
        discountCode,
        lastOrderId,
        manualOrderValues
      });
      const order = orderRequestResult.order;
      const additionalQuestions = orderRequestResult.additionalQuestions || false;
      dispatch({
        type: GET_ORDER_REQ_SUCCESS,
        order,
        additionalQuestions
      });
      if (orderRequestResult.addedDiscountCode && discountCode) {
        dispatch({ type: ADD_DISCOUNT_SUCCESS, code: discountCode });
      }
      // destroy details form on order request to ensure
      // default additional question answers are set
      beginCheckout(order._id);
      dispatch(destroy("details"));
      dispatch(nextPage());
      loadSummary()(dispatch, getState);
    } catch (err) {
      const error = err && err.response && err.response.data && err.response.data.error ? err.response.data.error : err;
      dispatch({ type: GET_ORDER_REQ_ERROR, error });
    }
  };
};

export const saveOrderData = (slug, eventId, orderData, tickets, ticketData, isGlobal) => {
  return (dispatch, getState) => {
    dispatch({ type: SAVE_ORDER_DATA_REQUEST });
    const state = getState();
    const hasMoreSteps = hasAdditionalSteps(getState);
    OrderService.saveDetails(orderData, tickets, ticketData, hasMoreSteps, isGlobal, state?.event?.data)
      .then((result) => {
        const order = result.order;
        const authKey = result.authKey;
        dispatch({ type: SAVE_ORDER_DATA_SUCCESS, order, authKey });
        if (order.status === "complete") {
          // the order is complete show thank you
          addPaymentInfo(order._id).then(() => {
            purchase(order._id);
          });
          dispatch(nextPage());
        } else {
          if (isGlobal) {
            loadGlobalSummary()(dispatch, getState);
          } else {
            loadSummary()(dispatch, getState);
          }
          dispatch(nextPage());
        }
        addShippingInfo(order._id);
      })
      .catch((err) => {
        console.error(err);
        const error =
          err && err.response && err.response.data && err.response.data.error ? err.response.data.error : err;
        const additionalQuestionQuantityError =
          err &&
          err.response &&
          err.response.data &&
          err.response.data.error &&
          err.response.data.error.additionalQuestionQuantityError
            ? err.response.data.error.additionalQuestionQuantityError
            : false;
        dispatch({
          type: SAVE_ORDER_DATA_ERROR,
          error,
          additionalQuestionQuantityError
        });
      });
  };
};

export const saveAfterCheckoutQuestions = (event, order, orderData, ticketData) => {
  const tickets = order.data.tickets;
  return (dispatch) => {
    OrderService.saveAfterCheckoutQuestions(event.data, orderData, tickets, ticketData)
      .then((result) => {
        dispatch(nextPage());
      })
      .catch((err) => {
        const error =
          err && err.response && err.response.data && err.response.data.error ? err.response.data.error : err;
        const additionalQuestionQuantityError =
          err &&
          err.response &&
          err.response.data &&
          err.response.data.error &&
          err.response.data.error.additionalQuestionQuantityError
            ? err.response.data.error.additionalQuestionQuantityError
            : false;
        dispatch({
          type: SAVE_ORDER_DATA_ERROR,
          error,
          additionalQuestionQuantityError
        });
      });
  };
};

export const payClicked = () => {
  return (dispatch, getState) => {
    dispatch({ type: PAYMENT_CLICKED });
    const { order } = getState();
  };
};

export const checkoutError = (error) => {
  return (dispatch) => {
    dispatch({ type: CHECKOUT_ERROR, error });
  };
};

export const resetPayment = () => {
  return (dispatch) => {
    dispatch({ type: PAYMENT_RESET });
  };
};

export const payV2 = (orderId, details, eventId) => {
  return async (dispatch, getState) => {
    const state = getState();
    dispatch({ type: PAYMENT_REQUEST });
    try {
      const result = await OrderService.payV2(orderId, details, eventId);

      const order = result.order;
      if (result.status === "completed") {
        // the order is complete show thank you
        try {
          await addPaymentInfo(order._id);
          await purchase(order._id);
          TrackingService.complete(state.event.data, state.order.data);
          const slug = state.event.data.slug;
          const location = state.event.data.location.toLowerCase();
          const orderId = state.order.data._id;
          const { routing } = state;
          const { routeList, selectedStep } = routing;
          const newStep = selectedStep + 1;
          if (/\/([^/]+)\/complete\/([^/]+)/.test(routeList[newStep].path)) {
            window.location.href = `/${slug}/${location}/${orderId}/complete`;
            return;
          }
          route(routeList[newStep].path);
          dispatch({ type: "NEXT_ROUTE", selectedStep: newStep });
          return;
        } catch (e) {}
        return;
      }
      if (result.status === "redirect") {
        window.top.location = result.redirectUrl;
        return;
      }
      dispatch({ type: PAYMENT_ERROR, order, error: result.errorMessage });
    } catch (err) {
      const error = err && err.response && err.response.data && err.response.data.error ? err.response.data.error : err;
      dispatch({ type: PAYMENT_ERROR, error });
    }
  };
};

export const pay = (orderId, gateway, details, eventId, isGlobal) => {
  return (dispatch) => {
    dispatch({ type: PAYMENT_REQUEST });
    OrderService.pay(orderId, gateway, details, eventId, isGlobal)
      .then((result) => {
        const order = result.order;
        if (result.success && order.status === "complete") {
          // the order is complete show thank you
          dispatch({ type: PAYMENT_SUCCESS, order });
          dispatch(nextPage());
          addPaymentInfo(order._id).then(() => {
            purchase(order._id);
          });
          return;
        }
        dispatch({ type: PAYMENT_ERROR, order, error: result.error });
      })
      .catch((err) => {
        const error =
          err && err.response && err.response.data && err.response.data.error ? err.response.data.error : err;
        dispatch({ type: PAYMENT_ERROR, error });
      });
  };
};

export const attendeeActionedRefund = (refundRequestObj, token) => {
  return (dispatch, getState) => {
    dispatch({ type: ATTENDEE_ACTIONED_REFUND_REQUEST });
    OrderService.attendeeActionedRefund(refundRequestObj, token)
      .then((result) => {
        dispatch({ type: ATTENDEE_ACTIONED_REFUND_SUCCESS, result });
      })
      .catch((err) => {
        dispatch({ type: ATTENDEE_ACTIONED_REFUND_ERROR });
      });
  };
};

export const getAttendeeRefundAmount = (orderId, token) => {
  return async (dispatch) => {
    dispatch({ type: ATTENDEE_REFUND_AMOUNT_REQUEST });
    try {
      const refundAmount = await OrderService.attendeeRefundAmount(orderId, token);
      dispatch({ type: ATTENDEE_REFUND_AMOUNT_SUCCESS, refundAmount });
    } catch (err) {
      dispatch({ type: ATTENDEE_REFUND_AMOUNT_ERROR, err });
    }
  };
};

export const addToCalendar = (values) => {
  return (dispatch, getState) => {
    dispatch({ type: ADD_TO_CALENDAR_REQUEST });
    OrderService.addToCalendar(values)
      .then((result) => {
        dispatch({ type: ADD_TO_CALENDAR_SUCCESS, result });
      })
      .catch((err) => {
        dispatch({ type: ADD_TO_CALENDAR_ERROR });
      });
  };
};
