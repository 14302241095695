import { h, Component } from "preact";
import { css } from "emotion";
import { Link } from "preact-router/match";
import config from "../config";

import PriceRangeDisplay from "./PriceRangeDisplay";
import ActionButton from "./ActionButton";

const buttonStyling = css({
  height: "40px !important",
  minWidth: 150,
  fontWeight: "600 !important"
});

const EventBtn = ({ ticketButtonText, disabled }) => (
  <ActionButton disabled={disabled} dataCY="GetTickets" aria-hidden tabIndex={-1} classNames={buttonStyling}>
    {ticketButtonText}
  </ActionButton>
);

const EventLink = ({ event, eventSlug, theme, hasValidDates, accessCode, discountCode }) => {
  const extraParameters = [];
  if (accessCode) {
    extraParameters.push(`accesscode=${encodeURIComponent(accessCode)}`);
  }
  if (discountCode) {
    extraParameters.push(`discountcode=${encodeURIComponent(discountCode)}`);
  }
  const isBrowser = typeof window !== "undefined";
  if (isBrowser && window.fb_oea) {
    extraParameters.push(`fb_oea=${encodeURIComponent(window.fb_oea)}`);
  }

  const ticketsLink =
    extraParameters.length > 0
      ? `${config("TICKETING_URL")}/${eventSlug}/tickets?${extraParameters.join("&")}`
      : `/${eventSlug}/tickets`;

  let ticketButtonText = theme && theme.ticketButtonText ? theme.ticketButtonText : "Get Tickets";

  const waitlistEnabled =
    hasValidDates && !event.suspendSales && event.waitlistSettings ? event.waitlistSettings.enabled : false;

  ticketButtonText = event.soldOut && waitlistEnabled ? "Join waitlist" : ticketButtonText;

  const disabled = !hasValidDates || event.suspendSales || (event.soldOut && !waitlistEnabled);

  if (disabled) {
    return <EventBtn ticketButtonText={ticketButtonText} disabled={disabled} />;
  }

  return (
    <Link
      href={ticketsLink}
      style={{
        maxWidth: 268,
        width: "100%"
      }}
      aria-label={ticketButtonText}
    >
      <EventBtn ticketButtonText={ticketButtonText} />
    </Link>
  );
};

const SalesWarning = ({ event, hasValidDates }) => {
  let eventWarningMessage = !hasValidDates ? "This event has passed" : false;
  eventWarningMessage = event.suspendSales ? "Ticket sales have ended" : eventWarningMessage;
  eventWarningMessage = event.soldOut ? "Sold Out" : eventWarningMessage;

  if (!eventWarningMessage) {
    return null;
  }
  const eventWarningMessageColor = event.soldOut ? "#dd1c60" : "inherit";
  const SalesWarningStyle = css({
    color: eventWarningMessageColor,
    marginRight: 12,
    width: "max-content",
    lineHeight: "40px",
    "@media(max-width: 700px)": {
      display: "none"
    }
  });
  return <div className={SalesWarningStyle}>{eventWarningMessage}</div>;
};

const SalesWarningMobile = ({ event, hasValidDates }) => {
  let eventWarningMessage = !hasValidDates ? "This event has passed" : false;
  eventWarningMessage = event.suspendSales ? "Ticket sales have ended" : eventWarningMessage;
  eventWarningMessage = event.soldOut ? "Sold Out" : eventWarningMessage;

  if (!eventWarningMessage) {
    return null;
  }
  const eventWarningMessageColor = event.soldOut ? "#dd1c60" : "inherit";
  const SalesWarningMobileStyle = css({
    display: "none",
    "@media(max-width: 700px)": {
      display: "block",
      color: eventWarningMessageColor,
      paddingTop: "2px",
      paddingBottom: "3px",
      backgroundColor: "#eeecf0",
      height: "24px",
      width: "100%",
      fontSize: "14px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.33px",
      // TODO: 5871 follow up - investigate potential bugs/cleanup
      // eslint-disable-next-line no-dupe-keys
      color: "#323232",
      textAlign: "center"
    }
  });
  return <div className={SalesWarningMobileStyle}>{eventWarningMessage}</div>;
};

class TicketsBar extends Component {
  constructor() {
    super();
    this.state = {
      showPriceStr: true,
      displayString: false
    };
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll, { passive: true });
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll() {
    const isBrowser = typeof window !== "undefined";
    if (isBrowser && window.pageYOffset > 400) {
      this.setState({ displayString: true });
    } else {
      this.setState({ displayString: false });
    }
  }

  render() {
    const { event, eventSlug, theme, accessCode, discountCode } = this.props;
    const { displayString } = this.state;
    const wrapper = css({
      borderRadius: "0 0 2px 2px",
      width: "100%",
      maxWidth: "100vw",
      top: "-1px",
      zIndex: 10000,
      boxShadow: "0 1px 3px 0 rgba(9,30,66,0.13), 0 0 1px 0 #F1F1F1",
      position: "-webkit-sticky",
      // TODO: 5871 follow up - investigate potential bugs/cleanup
      // eslint-disable-next-line no-dupe-keys
      position: "sticky",
      background: "#f9f9f9",
      "@media(min-width: 1015px)": {
        "& button": {
          width: 250
        }
      },
      "@media(max-width: 700px)": {
        top: "initial",
        bottom: 0,
        position: "fixed !important",
        paddingBottom: "6px",
        borderRadius: "0 0 2px 2px",
        width: "100%",
        zIndex: 10000,
        boxShadow: "0 1px 3px 0 rgba(9,30,66,0.13), 0 0 1px 0 #F1F1F1",
        background: "#fff",
        borderTop: "1px solid #dedede"
      }
    });

    const bar = css({
      display: "flex",
      padding: "10px 14px",
      alignItems: "center",
      verticalAlign: "middle",
      justifyContent: "space-between",
      width: "100%",
      minHeight: 36,
      margin: 0,
      "@media(max-width: 700px)": {
        padding: "10px 12px"
      },
      "& h3": {
        margin: 0
      }
    });

    const displayPriceStr = displayString || event.useOldBannerStyle;
    const priceStr = css({
      display: displayPriceStr ? "block" : "none",
      "@media(max-width: 1015px)": {
        display: "block !important",
        paddingRight: 16
      }
    });

    const hasValidDates = !!(event.validDates && event.validDates.length);

    return (
      <div className={wrapper} id="tickets-bar">
        <SalesWarningMobile event={event} hasValidDates={hasValidDates} />
        <div className={bar}>
          <div style={{ flex: 1 }}>
            <h3 className={priceStr}>
              <PriceRangeDisplay event={event} />
            </h3>
          </div>
          <div style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}>
            <SalesWarning event={event} hasValidDates={hasValidDates} />
            <EventLink
              event={event}
              eventSlug={eventSlug}
              theme={theme}
              hasValidDates={hasValidDates}
              accessCode={accessCode}
              discountCode={discountCode}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default TicketsBar;
