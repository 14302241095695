import axios from "./AxiosWrapper";
import queryString from "query-string";
import config from "../config";
import { determineManualOrder, fetchManualOrderSettings } from "../utils/ManualOrderUtils";
import { getHashParam } from "./SeatingMapService";

function getReferenceId() {
  //ahoy Userflux
}

const mapAdditionalFields = (fieldsObject, event) => {
  return Object.keys(fieldsObject)
    .map((key) => {
      const value = fieldsObject[key];
      const question = event?.additionalQuestions.find((question) => question._id === key);
      return {
        questionId: key,
        type: question?.type,
        inputType: question?.inputType,
        showOnTickets: question?.showOnTickets,
        question: question?.question,
        value
      };
    })
    .filter((q) => q.value !== null);
};

const OrderService = {
  get(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${config("API_URL")}order/${id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  addDiscount(eventId, orderId, discountCode) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${config("API_URL")}discounts/add`, {
          eventId,
          orderId,
          discountCode
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  async loadEditOrder(location, orderId, h) {
    const response = await axios.get(
      `${config("API_URL")}public/load-order/${location}/${orderId}/?h=${getHashParam()}`
    );
    return response.data;
  },
  async getPublicOrder(eventId, orderId, token) {
    const response = await axios.get(`${config("API_URL")}public/edit-order/order/${orderId}/${eventId}/${token}`);
    return response.data;
  },
  async savePublicOrder(eventId, orderId, token, orderData) {
    const response = await axios.post(
      `
    ${config("API_URL")}public/edit-order/order/${orderId}/${eventId}/${token}`,
      orderData
    );
    return response.data;
  },
  async addGiftCard(eventId, orderId, giftCardCode, paymentIntentId) {
    const response = await axios.post(`${config("API_URL")}gift-cards/add`, {
      eventId,
      orderId,
      giftCardCode,
      paymentIntentId
    });
    return response.data;
  },

  async completeOrderWithGiftCard(orderId) {
    const response = await axios.post(`${config("API_URL")}gift-cards/complete`, {
      orderId
    });
    return response.data;
  },

  async removeGiftCard(orderId, paymentIntentId) {
    const response = await axios.post(`${config("API_URL")}gift-cards/remove`, {
      orderId,
      paymentIntentId
    });
    return response.data;
  },
  removeDiscount(orderId) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${config("API_URL")}discounts/remove`, {
          orderId
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  async summary(orderId) {
    const response = await axios.get(`${config("API_URL")}order/summary/order/${orderId}`);
    return response.data;
  },

  async ticketRequestSummary(
    eventId,
    requestedTickets,
    requestedPackageTickets,
    manualOrderTicketOverride,
    clientDonation
  ) {
    const response = await axios.post(`${config("API_URL")}order/summary/order/ticket-request`, {
      eventId,
      requestedTickets,
      requestedPackageTickets,
      manualOrderTicketOverride,
      clientDonation
    });

    return response.data;
  },
  async addOnRequestSummary(orderId, requestedAddons) {
    const response = await axios.post(`${config("API_URL")}order/summary/order/${orderId}/addon-request`, {
      requestedAddons
    });
    return response.data;
  },
  async globalSummary(orderId) {
    const response = await axios.get(`${config("API_URL")}order/global/summary/order/${orderId}`);
    return response.data;
  },
  async giftCardSummary(giftCardPageId, requestedGiftCards) {
    const response = await axios.post(`${config("API_URL")}order/global/summary/order/gift-card-request`, {
      requestedGiftCards,
      giftCardPageId
    });
    return response.data;
  },
  request({
    eventId,
    eventDateId,
    requestedTickets,
    requestedPackageTickets,
    discountCode,
    clientDonation,
    lastOrderId,
    manualOrderValues,
    accessCode
  }) {
    const isBrowser = typeof window !== "undefined";
    let referenceId;
    let offerId;
    if (isBrowser) {
      const parsed = queryString.parse(window.location.search);
      // get the offer id for waitlist clients
      offerId = parsed.offerid;
      referenceId = getReferenceId();
    }
    /**
     * eventId,
      eventDateId,
      requestedTickets,
      requestedPackageTickets,
      clientDonation,
      lastOrderId,
      notes,
      paymentType,
      waitlistOfferId
     */
    return new Promise((resolve, reject) => {
      axios
        .put(
          `${config("API_URL")}order/request`,
          {
            eventId,
            eventDateId,
            requestedTickets,
            requestedPackageTickets,
            discountCode,
            clientDonation,
            lastOrderId,
            waitlistOfferId: offerId,
            accessCode,
            referenceId
          },
          { params: manualOrderValues }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  async requestGlobalOrder({ giftCardPageId, requestedGiftCards, lastOrderId }) {
    const response = await axios.put(`${config("API_URL")}order/global/request`, {
      giftCardPageId,
      requestedGiftCards,
      lastOrderId
    });
    return response.data;
  },
  saveDetails(orderData, tickets, ticketsData, hasAdditionalSteps, isGlobal, event) {
    let manualOrderSettings;
    const isManualOrder = determineManualOrder();
    if (isManualOrder) {
      manualOrderSettings = fetchManualOrderSettings();
    }
    orderData.additionalFields = orderData.additionalFields
      ? mapAdditionalFields(orderData.additionalFields, event)
      : orderData.additionalFields;
    // merge ticket additional fields
    const ticketLookUp = {};
    tickets.forEach((ticket, index) => {
      ticketLookUp[ticket._id] = index;
    });
    if (isGlobal) {
      ticketsData.forEach((giftCard) => {
        const index = ticketLookUp[giftCard.giftCardId];
        let giftCardLookup = tickets[index];
        if (giftCardLookup) {
          tickets[index] = { ...giftCardLookup, ...giftCard };
        }
      });
    } else if (ticketsData) {
      Object.keys(ticketsData).map((key, index) => {
        let ticket = tickets[ticketLookUp[key]];
        if (ticket) {
          ticket.additionalFields = mapAdditionalFields(ticketsData[key], event);
        }
      });
    }
    return new Promise((resolve, reject) => {
      axios
        .post(`${config("API_URL")}order/${isGlobal ? "global/" : ""}details`, {
          orderData,
          [isGlobal ? "giftCardData" : "ticketData"]: tickets,
          hasAdditionalSteps,
          manualOrderSettings
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  saveAfterCheckoutQuestions(event, orderData, tickets, ticketsData) {
    orderData.additionalFields = orderData.additionalFields
      ? mapAdditionalFields(orderData.additionalFields, event)
      : orderData.additionalFields;
    const ticketLookUp = {};
    tickets.forEach((ticket, index) => {
      ticketLookUp[ticket._id] = index;
    });
    if (ticketsData) {
      Object.keys(ticketsData).map((key, index) => {
        let ticket = tickets[ticketLookUp[key]];
        if (ticket) {
          ticket.additionalFields = mapAdditionalFields(ticketsData[key], event);
        }
      });
    }
    return new Promise((resolve, reject) => {
      axios
        .post(`${config("API_URL")}order/afterCheckout`, {
          eventId: event._id,
          orderData,
          ticketData: tickets
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  pay(orderId, gateway, details, eventId, isGlobal) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${config("API_URL")}order/pay`, {
          orderId,
          details: { gateway, data: details },
          eventId,
          isGlobal
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  payV2(orderId, details, eventId) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${config("API_URL")}order/pay-v2`, {
          orderId,
          details,
          eventId
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  async attendeeActionedRefund(refundRequestObj, token) {
    return (
      await axios.post(`${config("API_URL")}public/attendee-actioned-refund/${token}`, {
        ...refundRequestObj
      })
    ).data;
  },
  async attendeeRefundAmount(orderId, token) {
    return (await axios.get(`${config("API_URL")}public/attendee-actioned-refund/available/${orderId}/${token}`)).data;
  },
  addToCalendar(values) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${config("API_URL")}order/add-to-calendar`, { ...values })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  async switchGateways(orderId, requestingGatewayType) {
    const response = await axios.post(`${config("API_URL")}order/${orderId}/gateway-switch-v2`, {
      requestingGatewayType,
      skipPaymentData: requestingGatewayType === "stripe"
    });
    return response.data;
  }
};
export default OrderService;
