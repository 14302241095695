import FacebookPixelService from "./FacebookPixelService";
import GoogleTagManagerService from "./GoogleTagManagerService";

const TrackingService = {
  ticketsViewed(event) {
    FacebookPixelService.ticketsViewed(event);
  },
  ticketsAdded(event, order) {
    FacebookPixelService.ticketsAdded(event, order);
    GoogleTagManagerService.ticketsAdded(event, order);
  },
  enteredDetails(event, order) {
    FacebookPixelService.enteredDetails(event, order);
    GoogleTagManagerService.enteredDetails(event, order);
    FacebookPixelService.checkout(event, order);
  },
  complete(event, order) {
    FacebookPixelService.complete(event, order);
    GoogleTagManagerService.complete(event, order);
  }
};
export default TrackingService;
