import axios from "./AxiosWrapper";

const eventLocationHeader = "x-event-level-location";
const userLocationHeader = "x-user-level-location";

const LocationService = {
  setEventLocation(userLocation) {
    axios.defaults.headers.common[eventLocationHeader] = userLocation;
    axios.defaults.headers.post[eventLocationHeader] = userLocation;
    axios.defaults.headers.common[userLocationHeader] = userLocation;
    axios.defaults.headers.post[userLocationHeader] = userLocation;
  }
};

export default LocationService;
