import { CONTACT_RESET, CONTACT_REQUEST, CONTACT_SUCCESS, CONTACT_ERROR } from "../actions/contactActions";

const initialState = {
  loading: false,
  error: false,
  sent: false,
  message: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CONTACT_RESET:
      return initialState;
    case CONTACT_REQUEST:
      return {
        ...state,
        loading: true,
        error: false
      };
    case CONTACT_SUCCESS:
      return {
        ...state,
        loading: false,
        sent: true,
        error: false,
        message: action.message
      };
    case CONTACT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    default:
      return state;
  }
};
export default { initialState, reducer };
