import { h } from "preact";
import Required from "./Required";
import Description from "./Description";

const InputField = (props) => {
  const {
    input,
    label,
    description,
    inputType,
    required,
    placeholder,
    type,
    disabled,
    style,
    inputStyle,
    autocomplete,
    inputmode = null,
    autofocus = false,
    manualError,
    maxlength,
    meta: { touched, error }
  } = props;
  const hasError = manualError || error;

  const inputFieldType = inputType ? inputType : type;
  let invalidObj = {};
  if (required) {
    invalidObj = {
      "aria-invalid": hasError && touched ? "true" : "false"
    };
  }
  return (
    <div class={touched && hasError ? "form-group has-error" : "form-group"} style={style}>
      {label ? (
        <label for={input.name} class="form-label">
          <Required required={required} />
          {label}
        </label>
      ) : (
        ""
      )}
      <Description description={description} />
      <input
        class="form-input"
        id={input.name}
        disabled={disabled}
        {...input}
        type={inputFieldType}
        inputmode={inputmode}
        placeholder={placeholder}
        autocomplete={autocomplete}
        style={inputStyle}
        maxlength={maxlength}
        {...invalidObj}
        aria-required={required ? "true" : "false"}
        autofocus={autofocus}
        aria-describedby={`${input.name}Error`}
      />
      {touched && hasError && (
        <p class="form-input-hint" id={`${input.name}Error`}>
          {error ? error : manualError}
        </p>
      )}
    </div>
  );
};

export default InputField;
