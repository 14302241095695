import { GET_SUMMARY_REQUEST, GET_SUMMARY_SUCCESS, GET_SUMMARY_ERROR } from "../actions/orderSummaryActions";

import { CLEAR_ORDER, TIP_CHANGE_REQUEST, TIP_CHANGE_SUCCESS, TIP_CHANGE_ERROR } from "../actions/orderActions";

import { SELECT_DATE } from "../actions/ticketsActions";
import { GET_EVENT_SUCCESS } from "../actions/eventActions";

const initialState = {
  data: {
    tickets: [],
    packageTickets: [],
    addOns: [],
    giftCards: [],
    subtotal: 0,
    discount: 0,
    discountDescription: "",
    bookingFees: 0,
    total: 0,
    tax: 0,
    tipAmount: 0
  },
  eventDate: null,
  loading: false,
  error: false,
  loaded: false
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_ORDER: {
      return initialState;
    }
    case GET_SUMMARY_REQUEST:
    case TIP_CHANGE_REQUEST:
      return {
        ...state,
        loading: true,
        error: false
      };
    case GET_SUMMARY_SUCCESS:
    case TIP_CHANGE_SUCCESS:
      return {
        ...state,
        data: action.summary,
        loading: false,
        error: false,
        loaded: true
      };
    case GET_SUMMARY_ERROR:
    case TIP_CHANGE_ERROR:
      return {
        ...state,
        data: null,
        loading: false,
        error: action.error,
        loaded: false
      };
    case SELECT_DATE: {
      return {
        ...state,
        eventDate: action.dateDisplay
      };
    }
    case GET_EVENT_SUCCESS:
      if (!action.event.validDates || !action.event.validDates.length) {
        return {
          ...state
        };
      }
      return {
        ...state,
        eventDate: action.event.validDates[0].display
      };
    default:
      return state;
  }
};
export default { initialState, reducer };
