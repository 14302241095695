import { h, Component } from "preact";
import Required from "./Required";
import Description from "./Description";
import { change, touch } from "redux-form";

class OptionsField extends Component {
  constructor() {
    super();
    this.changeValue = this.changeValue.bind(this);
    this.changeDescription = this.changeDescription.bind(this);
  }
  changeValue(index) {
    const { meta, input, options, formName } = this.props;
    meta.dispatch(touch(formName, input.name));

    if (Number(index) === -1) {
      meta.dispatch(change(formName, input.name, null));
      return;
    }
    const option = options[index];
    meta.dispatch(change(formName, input.name, option.value));
  }
  changeDescription(value) {
    const { meta, input, formName } = this.props;
    const option = this.getOption(input.value);
    if (option) {
      meta.dispatch(change(formName, input.name, `${option.value}: ${value}`));
    }
  }

  getOption(value) {
    const { options } = this.props;
    let option = options.find((opt) => opt.value === value);
    if (!option) {
      const valueArray = (value || "").split(":");
      const subValue = valueArray.length > 0 ? valueArray[0] : "";
      option = options.find((opt) => opt.value === subValue);
    }
    return option;
  }

  render({
    input,
    options,
    label,
    description,
    required,
    defaultValue,
    trackQuantity,
    includeSelectOption = true,
    disabled,
    meta: { touched, error, warning }
  }) {
    const option = this.getOption(input.value);
    let invalidObj = {};
    if (required) {
      invalidObj = {
        "aria-invalid": error && touched ? "true" : "false"
      };
    }
    return (
      <div class={touched && error ? "form-group has-error" : "form-group"}>
        <label for={`${input.name}-select`} class="form-label">
          <Required required={required} /> {label}
        </label>
        <Description description={description} />
        <input type="hidden" id={input.name} {...input} aria-label={`${input.name}`} />
        <select
          class="form-select"
          id={`${input.name}-select`}
          aria-required={required ? "true" : "false"}
          onChange={(event) => this.changeValue(event.target.value)}
          disabled={disabled}
        >
          {includeSelectOption ? <option value={-1}>Select</option> : null}
          {options.map((opt, index) => {
            const quantityLeft = opt.quantityLeft || 0;
            const disabled = !(!trackQuantity || quantityLeft > 0);

            return (
              <option
                value={index}
                selected={!!(defaultValue === opt.value || (option && opt.value === option.value))}
                disabled={disabled}
              >
                {opt.label ? opt.label : opt.value}{" "}
                {quantityLeft > 0 && quantityLeft < 11 && <span>({quantityLeft} available)</span>}
                {trackQuantity && disabled && <span>(Not available)</span>}
              </option>
            );
          })}
        </select>
        {option && option.descriptionRequired ? (
          <div style={{ margin: "8px 0 0 0" }}>
            <label for={`${input.name}-description`} class="form-label">
              Please specify
              <Required required={required} />
            </label>{" "}
            <Description description={description} />
            <input
              class="form-input"
              type="text"
              id={`${input.name}-description`}
              onChange={(event) => this.changeDescription(event.target.value)}
              aria-label={`Other description for ${label}`}
              {...invalidObj}
              aria-describedby={`${input.name}Error`}
            />
          </div>
        ) : null}
        {touched && error && (
          <p class="form-input-hint" id={`${input.name}Error`}>
            {error}
          </p>
        )}
      </div>
    );
  }
}

export default OptionsField;
