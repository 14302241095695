import { h } from "preact";
import Page from "../../components/Page";
import Content from "../../components/Content";
import EventError from "../../components/EventError";

const NoTickets = () => (
  <Page border={false}>
    <Content>
      <EventError type="noTickets" />
    </Content>
  </Page>
);

export default NoTickets;
