import { h } from "preact";
import { css } from "emotion";

const img = css({
  maxWidth: "280px",
  height: "120px",
  width: "100%",
  borderRadius: "4px"
});

const EventMap = ({ location }) => {
  const markerPos =
    location && location.latLng && location.latLng[0]
      ? {
          lat: location.latLng[0],
          lng: location.latLng[1]
        }
      : false;

  const showMap = markerPos && location.type === "address";
  const hasStaticMap = location.mapUrl;
  if (typeof window !== "undefined" && window.innerWidth < 700) {
    return null;
  }
  return (
    <div>
      {showMap && hasStaticMap ? (
        <a
          href={`https://www.google.com/maps/search/?api=1&query=${location.latLng[0]},${location.latLng[1]}`}
          target="_blank"
          rel="noreferrer"
        >
          <img src={location.mapUrl} className={img} alt="Event location map" />
        </a>
      ) : null}
    </div>
  );
};
export default EventMap;
