import {
  LOAD_GOOGLE_PAY_REQUEST,
  LOAD_GOOGLE_PAY_SUCCESS,
  LOAD_GOOGLE_PAY_ERROR,
  GOOGLE_PAY_IS_READY_TO_PAY
} from "../actions/googlePayActions";

const initialState = {
  checkoutScript: {
    loading: false,
    error: false,
    loaded: false
  },
  isReadyToPay: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_GOOGLE_PAY_REQUEST:
      return {
        ...state,
        checkoutScript: {
          loading: true,
          error: false,
          loaded: false
        }
      };
    case LOAD_GOOGLE_PAY_SUCCESS:
      return {
        ...state,
        checkoutScript: {
          loading: false,
          error: false,
          loaded: true
        }
      };
    case LOAD_GOOGLE_PAY_ERROR:
      return {
        ...state,
        checkoutScript: {
          loading: false,
          error: true,
          loaded: true
        }
      };
    case GOOGLE_PAY_IS_READY_TO_PAY:
      return {
        ...state,
        isReadyToPay: true
      };
    default:
      return state;
  }
};
export default {
  initialState,
  reducer
};
