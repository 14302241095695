import React, { useState } from "react";
import Button from "../Button";
import { connect } from "react-redux";
import { css } from "emotion";
import Modal from "../Modal";
import Picture from "../Picture";
import { follow } from "../../state/actions/followHostActions";
import FollowHostModalForm from "./FollowHostModalForm";

let primaryTextColor = "#323232";

const pictureStyle = css({
  width: 100,
  height: 100,
  display: "inline-block",
  marginBottom: 16,
  overflow: "hidden",
  borderRadius: "50%",
  "@media(max-width: 700px)": {
    marginBottom: 12,
    width: 80,
    height: 80
  }
});

const noPicture = css({
  backgroundColor: "#353337",
  fontSize: 26,
  borderRadius: 4,
  width: "100%",
  height: "100%",
  paddingTop: 30,
  "@media(max-width: 700px)": {
    fontSize: 18
  }
});

const wrapperStyle = css({
  textAlign: "left"
});

const Follow = ({ follow, organiser, followHost }) => {
  if (!organiser) {
    return null;
  }

  // ----- /!\ ----
  // WARNING THIS IS DUPLICATED FROM OrganiserInfos.js
  //getting acronym from event organisers name if no picture supplied
  const organiserName = organiser ? organiser.name : false;
  const acronym = organiserName
    ? organiserName.split(/\s/).reduce((response, word) => (response += word.slice(0, 1)), "")
    : false;

  let eventDescText = organiser && organiser.description ? organiser.description : null;

  const showReadMore = !!(eventDescText && eventDescText.length > 290);
  eventDescText = showReadMore ? eventDescText.substring(0, 290) : eventDescText;
  const organiserLogo = organiser && organiser.logo ? organiser.logo : false;

  const showPicture = !!(organiserLogo || organiserName);
  const hostPicture = (
    <>
      {showPicture ? (
        <div className={pictureStyle}>
          {organiserLogo ? (
            <Picture img={organiserLogo} alt={`${organiserName} logo`} width={100} ratio={1} />
          ) : organiserName ? (
            <div className={noPicture}>
              <div style={{ display: "inline-block", color: primaryTextColor }}>{acronym}</div>
            </div>
          ) : null}
        </div>
      ) : null}
    </>
  );
  // ----- /!\ ---- End of duplicate section

  const [open, toggle] = useState(false);
  const { success, alreadyFollowing } = followHost.result;
  const handleSave = (values) => {
    follow(organiser._id, values);
  };

  return (
    <>
      <Button size="small" onClick={() => toggle(true)} style={{ margin: "auto" }}>
        FOLLOW
      </Button>
      <Modal
        showModal={open}
        closeHandler={() => toggle(false)}
        maxWidth={640}
        contents={
          success ? (
            <div className={wrapperStyle}>
              <div style={{ textAlign: "center" }}>{hostPicture}</div>
              <h3>
                {alreadyFollowing ? "You're already following!" : `Great! You're now following ${organiser.name}`}
              </h3>
              <p>
                {alreadyFollowing
                  ? `We love your passion, lovely human! But you're already a follower of ${organiser.name}.`
                  : "Keep an eye on your emails. You'll now be kept up-to-date on the latest news, events, and exclusive offers from the event host."}
              </p>
            </div>
          ) : (
            <FollowHostModalForm
              organiser={organiser}
              hostPicture={hostPicture}
              hostTitle={organiser.name}
              followHost={followHost}
              follow={follow}
              handleSave={handleSave}
            />
          )
        }
        padding={"50px"}
      />
    </>
  );
};

export default connect(
  (state) => {
    return {
      initialValues: {},
      followHost: state.followHost
    };
  },
  { follow }
)(Follow);
