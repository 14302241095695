import loadScript from "load-script";

export const LOAD_TILL_REQUEST = "LOAD_TILL_REQUEST";
export const LOAD_TILL_SUCCESS = "LOAD_TILL_SUCCESS";
export const LOAD_TILL_ERROR = "LOAD_TILL_ERROR";

export const TILL_INIT = "TILL_INIT";
export const UPDATED_FIELDS = "UPDATED_FIELDS";

import config from "../../config";

import { checkoutError, pay, payClicked } from "./orderActions";

let payment;

export const loadCheckOutScript = () => {
  return (dispatch, getState) => {
    const state = getState();
    const checkoutScript = state.till.checkoutScript;
    dispatch({ type: LOAD_TILL_REQUEST });
    if (!checkoutScript.loaded && !checkoutScript.loading) {
      loadScript(
        `https://gateway.tillpayments.com/js/integrated/payment.1.2.min.js`,
        {
          attrs: {
            "data-main": "payment-js"
          }
        },
        function (err) {
          if (err) {
            dispatch({ type: LOAD_TILL_ERROR });
            return;
          }
          dispatch({ type: LOAD_TILL_SUCCESS });
        }
      );
    } else {
      dispatch({ type: LOAD_TILL_SUCCESS });
    }
  };
};

export const initTill = (inputStyle, location) => {
  return (dispatch) => {
    payment = new PaymentJs();
    const TILL_PUBLIC_INTEGRATION = config("TILL_PUBLIC_INTEGRATION", location);
    payment.init(TILL_PUBLIC_INTEGRATION, "number_div", "cvv_div", (payment) => {
      payment.setNumberStyle(inputStyle);
      payment.setCvvStyle(inputStyle);
      payment.numberOn("input", (data) => {
        dispatch(updateFields({ ...data }));
      });
      payment.numberOn("focus", (data) => {
        dispatch(updateFields({ ...data, numberFocused: true }));
      });
      payment.numberOn("blur", (data) => {
        dispatch(updateFields({ ...data, numberFocused: false, numberTouched: true }));
      });
      payment.cvvOn("input", (data) => {
        dispatch(updateFields({ ...data }));
      });
      payment.cvvOn("focus", (data) => {
        dispatch(updateFields({ ...data, cvvFocused: true }));
      });
      payment.cvvOn("blur", (data) => {
        dispatch(updateFields({ ...data, cvvFocused: false, cvvTouched: true }));
      });
      dispatch({ type: TILL_INIT });
    });
  };
};

export const updateFields = (data) => {
  return (dispatch) => {
    dispatch({ type: UPDATED_FIELDS, data });
  };
};

export const payTill = (values, order, event, isGlobal) => {
  return (dispatch) => {
    dispatch(payClicked());
    const data = {
      ...values,
      email: order.data.email
    };
    const paymentId = isGlobal ? order.userId : event.data._id;
    payment.tokenize(
      data,
      function (token, cardData) {
        dispatch(pay(order.data._id, "till", { ...cardData, transactionToken: token }, paymentId, isGlobal));
      },
      function (errors) {
        console.error(errors);
        let errorMessages = [];
        if (errors && errors.length) {
          errorMessages = errors.map((error) => error.message);
        }
        dispatch(checkoutError(errorMessages.join(", ")));
      }
    );
  };
};
