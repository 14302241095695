import { h, Component } from "preact";
import { css, keyframes } from "emotion";
import FocusTrap from "focus-trap-react";

class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasOpened: false
    };
  }

  componentDidUpdate() {
    const { showModal } = this.props;
    if (showModal) {
      if (!this.state.hasOpened) {
        this.setState({ hasOpened: true });
      }
      this.addKeyDownListener();
      return;
    }
    this.removeKeyDownListener();
  }

  componentWillUnmount() {
    this.removeKeyDownListener();
  }

  checkDocumentKeyDown = (event) => {
    const { closeHandler } = this.props;
    if (event.key === "Escape" || event.key === "Esc" || event.keyCode === 27) {
      closeHandler(event);
    }
  };

  addKeyDownListener() {
    const isBrowser = typeof window !== "undefined";
    if (isBrowser) {
      document.addEventListener("keydown", this.checkDocumentKeyDown);
    }
  }

  removeKeyDownListener() {
    const isBrowser = typeof window !== "undefined";
    if (isBrowser) {
      document.removeEventListener("keydown", this.checkDocumentKeyDown);
    }
  }
  render({
    showModal,
    closeHandler,
    title,
    contents,
    footer,
    children,
    padding = 20,
    destroy = true,
    maxWidth,
    includeCloseButton = true
  }) {
    const hasOpened = this.state.hasOpened;
    const fadeIn = keyframes({
      from: {
        opacity: 0.5
      },
      to: {
        opacity: 1
      }
    });
    const grow = keyframes({
      from: {
        transform: `scale(0.9)`
      },
      to: {
        transform: `scale(1)`
      }
    });
    /* The Modal (background) */
    const modal = css({
      display: showModal ? "flex" : "none",
      justifyContent: "center",
      alignItems: "flex-start",
      position: "fixed",
      zIndex: 100001,
      paddingTop: 72,
      left: 0,
      top: 0,
      width: "100%",
      height: "100%",
      background: "transparent",
      "@media(max-width: 600px)": {
        paddingTop: 40
      }
    });

    const modalBackground = css({
      backgroundColor: "rgba(0,0,0,0.6)",
      animation: `${fadeIn} 0.3s ease-in-out`,
      left: 0,
      top: 0,
      width: "100%",
      height: "100%",
      overflow: "auto",
      position: "absolute"
    });

    /* Modal Content */
    const modalContent = css({
      position: "relative",
      backgroundColor: "#fefefe",
      border: "1px solid #888",
      borderRadius: 4,
      display: "inline-block",
      animation: `${grow} 0.2s ease-in-out`,
      overflow: "hidden",
      minWidth: 300,
      maxWidth: maxWidth || "calc(100vw - 28px)",
      maxHeight: "calc(100vh - 120px)",
      height: "100%",
      "@media(max-width: 600px)": {
        maxHeight: "100vh",
        paddingBottom: 80,
        minWidth: 200,
        width: "100%",
        borderRadius: 0
      }
    });

    /* The Close Button */
    const close = css({
      border: "none",
      position: "absolute",
      background: "#fff",
      right: 20,
      top: 20,
      width: 24,
      height: 24,
      backgroundImage: "url(https://static.humanitix.com/ticketing/ic_close.svg)",
      zIndex: 11
    });

    const titleStyle = css({
      padding,
      paddingBottom: 0
    });

    const contentStyle = css({
      padding,
      height: "100%"
    });

    const footerStyle = css({
      borderTop: "1px solid #D8D8D8",
      padding: "12px 0 6px",
      margin: "0 0 6px 0",
      display: "flex"
    });

    const chromeSize = footer ? 250 : 200;
    const scrollWarpper = css({
      overflow: "hidden",
      overflowY: "auto",
      minWidth: 300,
      maxWidth: maxWidth || "calc(100vw - 28px)",
      maxHeight: `calc(100vh - ${chromeSize}px)`,
      height: "100%"
    });

    return (
      <div className={modal}>
        <div className={modalBackground} />
        {showModal || (!destroy && hasOpened) ? (
          <FocusTrap>
            <div className={modalContent} aria-modal="true" role="dialog" id="modal-wrapper">
              {includeCloseButton ? (
                <button type="button" className={close} onClick={closeHandler} aria-label="Close" data-cy="close" />
              ) : null}
              {title ? (
                <>
                  <h3 className={titleStyle}>{title}</h3> <hr />
                </>
              ) : null}
              <div className={scrollWarpper}>
                {contents ? <div className={contentStyle}>{contents}</div> : children}
              </div>
              {footer ? <div className={footerStyle}>{footer}</div> : null}
            </div>
          </FocusTrap>
        ) : null}
      </div>
    );
  }
}

export default Modal;
