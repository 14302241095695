import {
  GET_EVENT_REQUEST,
  GET_EVENT_SUCCESS,
  GET_EVENT_ERROR,
  GET_USER_EVENTS_REQUEST,
  GET_USER_EVENTS_SUCCESS,
  GET_USER_EVENTS_ERROR,
  CLEAR_EVENT
} from "../actions/eventActions";

import { GET_ORDER_REQ_SUCCESS } from "../actions/orderActions";

import cssVars from "../../utils/css-vars-ponyfill";
import { convertDateForOnlineEvent } from "../../utils/DateUtils";

const initialState = {
  id: null,
  widget: false,
  data: false,
  loading: false,
  error: false
};
const parseEvent = (event, widget) => {
  event.dates = event.dates ? event.dates : [];
  if (event?.eventLocation?.type === "online") {
    event.dates = event.dates.map((date) => {
      date.display = convertDateForOnlineEvent(date.startDate, date.endDate, event.location);
      return date;
    });
  }
  event.validDates = event.dates.filter((date) => date.valid);
  // add theme
  let variables = {};
  const theme = event.theme;

  // determines which banner to use, either new style or old style
  event.useOldBannerStyle = !!(!event.bannerImage && event.featureImage);

  event.displayImage = event.useOldBannerStyle ? event.featureImage : event.bannerImage;

  if (!theme) {
    return event;
  }

  if (theme.primaryColor) {
    variables["--primary-color"] = theme.primaryColor;
  }
  if (theme.primaryColorHover) {
    variables["--primary-color-hover"] = theme.primaryColorHover;
  } else if (theme.primaryColor) {
    variables["--primary-color-hover"] = theme.primaryColor;
  }
  if (theme.primaryColorFade) {
    variables["--primary-color-fade"] = theme.primaryColorFade;
  }
  if (theme.primaryShadowColour) {
    variables["--primary-shadow-color"] = theme.primaryShadowColour;
  }
  if (theme.primaryTextColor) {
    variables["--primary-text-color"] = theme.primaryTextColor;
  }
  if (theme.linkColor || theme.primaryColor) {
    variables["--link-color"] = theme.linkColor ? theme.linkColor : "#196097";
  }
  if (theme.footerColor) {
    variables["--footer-color"] = theme.footerColor;
  }
  cssVars({ variables });
  return event;
};

const changeFooterColor = (trueOrFalse, footerColor) => {
  let variables = {};
  footerColor = footerColor || "#fff";
  if (trueOrFalse) variables["--footer-color"] = footerColor;
  cssVars({ variables });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_EVENT:
      return {
        ...state,
        data: null
      };
    case GET_EVENT_REQUEST:
      return {
        ...state,
        id: null,
        loading: true,
        error: false
      };
    case GET_EVENT_SUCCESS:
      return {
        ...state,
        id: action.event._id,
        data: parseEvent(action.event, state.widget),
        loading: false,
        error: false
      };
    case GET_EVENT_ERROR:
      return { data: false, loading: false, error: action.error };

    case GET_USER_EVENTS_REQUEST:
      return {
        ...state,
        id: null,
        loading: true,
        error: false
      };
    case GET_USER_EVENTS_SUCCESS:
      changeFooterColor(true, "#fff");
      return {
        ...state,
        data: action.events,
        loading: false,
        error: false
      };
    case GET_USER_EVENTS_ERROR:
      return { data: false, loading: false, error: action.error };

    case GET_ORDER_REQ_SUCCESS:
      if (action.additionalQuestions && action.additionalQuestions.length) {
        const event = state.data;
        event.additionalQuestions = action.additionalQuestions;
        return {
          ...state,
          data: event
        };
      }
      return state;

    default:
      return state;
  }
};
export default {
  initialState,
  reducer,
  parseEvent
};
