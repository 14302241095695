import { h } from "preact";
import Image from "../components/Image";
import { css } from "emotion";
import config from "../config";

const ic_plane = "https://static.humanitix.com/ticketing/ic_airplane.svg";
const ic_binoculars = "https://static.humanitix.com/ticketing/ic_binoculars.svg";
const ic_notPublishedClouds = "https://static.humanitix.com/ticketing/ic_clouds.svg";
const ic_notFoundClouds = "https://static.humanitix.com/ticketing/ic_clouds_small.svg";

const styleImage = css({
  width: "137px",
  height: "165px",
  marginBottom: "8px"
});

const EventError = ({ type = "notFound", eventId }) => {
  const websiteURL = config("WEBSITE_URL");
  const consoleURL = config("CONSOLE_URL");
  let clouds = null;
  let icon = null;
  let heading = null;
  let message = null;
  switch (type) {
    case "notFound":
      clouds = ic_notFoundClouds;
      icon = ic_binoculars;
      heading = "Event not found";
      message = "Check if your events URL is correct or continue exploring events";
      break;
    case "noTickets":
      clouds = ic_notFoundClouds;
      icon = ic_binoculars;
      heading = "Tickets not found!";
      message = "Tickets not found, please check if your ticket URL is correct.";
      break;
    case "noEvents":
      clouds = ic_notFoundClouds;
      icon = ic_binoculars;
      heading = "No events found";
      message = "";
      break;
    default:
      clouds = ic_notPublishedClouds;
      icon = ic_plane;
      heading = "This event is yet to launch";
      message = (
        <>
          <a href={`${consoleURL}/console/my-events/${eventId}`}>Publish</a> your event or contact your event host
        </>
      );
  }
  const styleDiv = {
    maxWidth: "800px",
    height: "454px",
    background: `url(${clouds}) no-repeat`,
    backgroundSize: "contain",
    backgroundPosition: "center 9%",
    textAlign: "center",
    backgroundColor: "#F7FAFF",
    paddingTop: "80px",
    margin: "0 auto"
  };

  if (type === "notFound") {
    styleDiv.maxWidth = "487px";
    styleDiv.padding = "80px 14px";
  }

  const styleHeading = {
    fontWeight: "700",
    marginBottom: "6px"
  };

  const styleMessage = {
    color: "#6F7782",
    fontWeight: "700",
    marginBottom: "20px"
  };

  return (
    <div style={{ backgroundColor: "#F7FAFF" }}>
      <div style={styleDiv}>
        <Image src={icon} className={styleImage} alt="Error Image" />
        <h2 style={styleHeading}>{heading}</h2>
        <h3 style={styleMessage}>{message}</h3>
        {type !== "noEvents" ? (
          <a href={`${websiteURL}/search`}>
            <button class="btn btn-primary" aria-label="Search Events" aria-hidden>
              Search Events
            </button>
          </a>
        ) : null}
      </div>
    </div>
  );
};
export default EventError;
