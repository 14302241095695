import { h, Component } from "preact";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ScrollLock from "react-scrolllock";
import Image from "./Image";
import Button from "./Button";
import { css, keyframes } from "emotion";
import { max } from "../utils/CssUtils";

const animations = {
  fadeIn: keyframes`
from {
  background-color: rgba(0, 0, 0, 0);
}
to {
  background-color: rgba(0, 0, 0, 0.4);
}
`
};

const styles = {
  fadeDiv: css`
    position: fixed;
    z-index: 1000;
    width: 100%;
    height: calc(100% + 1000px) !important;
    top: 0;
    left: 0;
    animation: ${animations.fadeIn} 0.8s forwards;
    padding: 0px !important;
    @media (max-width: 850px) {
      z-index: 999999;
    }
  `,

  shareWrapper: css`
    position: fixed;
    z-index: 999999;
    width: 480px;
    height: 250px;
    background-color: #f9f9fa;
    top: calc(50% - 125px);
    left: calc(50% - 240px);
    border-radius: 4px;
    @media (max-width: 850px) {
      width: 100%;
      height: 152px;
      bottom: 0 !important;
      top: calc(100% - 152px);
      left: 0;
    }
  `,

  shareHeading: css`
    width: 100%;
    height: 55px;
    padding: 14px;
    border-bottom: solid #dedede 1px;
    text-align: center;
    margin-bottom: 40px;
    font-weight: 600;
    @media (max-width: 850px) {
      margin-bottom: 30px;
    }
  `,

  heading: css`
    font-size: 20px;
    font-weight: 600;
  `,

  closeButton: css`
    position: absolute;
    border: none;
    background: none;
    right: 14px;
    top: 14px;
  `,

  iconWrapper: css`
    margin: 0 auto 40px auto;
    margin-right: 25%;
    margin-left: 25%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: none;

    @media (max-width: 400px) {
      margin-right: 10%;
      margin-left: 10%;
    }
    @media (max-width: 300px) {
      margin-right: 0.5%;
      margin-left: 0.5%;
    }
  `,

  buttonSocial: css`
    border: none;
    background: none;
  `,

  shareLogo: css`
    @media (max-width: 850px) {
      width: 44px;
      height: 44px;
    }
  `,

  shareBody: css`
    padding: 0 14px;
  `,

  copyBubble: css`
    display: none;

    @media (max-width: 850px) {
      display: inline;
      border: none;
      background: none;
    }
  `,

  shareButton: css`
    padding: 0px;
  `
};

const inputWrapper = css({
  padding: "0 14px",
  display: "flex",
  input: {
    paddingLeft: "12px",
    fontSize: "16px",
    border: "1px solid #8D949E",
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    width: "100%",
    height: "40px"
  },
  Button: {
    borderTopLeftRadius: "0px !important",
    borderBottomLeftRadius: "0px !important",
    backgroundColor: "#353337",
    fontColor: "#fff"
  },
  [max(850)]: {
    display: "none"
  }
});

class Share extends Component {
  constructor() {
    super();
    this.state = {
      open: false,
      copied: false
    };
    this.shareClicked = this.shareClicked.bind(this);
    this.closeClicked = this.closeClicked.bind(this);
    this.killClick = this.killClick.bind(this);
  }

  killClick(evt) {
    evt.stopPropagation();
    evt.preventDefault();
  }

  shareClicked(evt) {
    evt.stopPropagation();
    evt.preventDefault();
    this.setState({
      open: true
    });
  }

  closeClicked(evt) {
    evt.stopPropagation();
    evt.preventDefault();
    this.setState({
      open: false
    });
  }

  render() {
    const { open } = this.state;
    const { iconPosition, eventLink, icon = true } = this.props;

    return (
      <div class={iconPosition}>
        {icon ? (
          <button className={styles.shareButton} onClick={this.shareClicked}>
            <Image src={"https://static.humanitix.com/ticketing/ic_share.svg"} alt="Share Icon" />
          </button>
        ) : (
          <Button type="primary" onClick={this.shareClicked}>
            Share
          </Button>
        )}
        {open ? (
          <div className={styles.fadeDiv} onClick={this.killClick}>
            <ScrollLock />
            <div className={styles.shareWrapper}>
              <div className={styles.shareHeading}>
                <h2 className={styles.heading}>Share Event</h2>
                <button className={styles.closeButton} onClick={this.closeClicked} aria-label="Close button">
                  <Image src={"https://static.humanitix.com/ticketing/ic_close.svg"} alt="Close Icon" />
                </button>
              </div>
              <div className={styles.shareBody}>
                <div className={styles.iconWrapper}>
                  <button
                    className={styles.buttonSocial}
                    onClick={() => window.open(`http://www.facebook.com/sharer.php?u=${eventLink}`, "_blank")}
                    aria-label="Share - Facebook"
                  >
                    <Image
                      className={styles.shareLogo}
                      src={"https://static.humanitix.com/ticketing/ic_facebook_colour.svg"}
                      alt="Facebook Icon"
                    />
                  </button>
                  <button
                    className={styles.buttonSocial}
                    onClick={() =>
                      window.open(`https://twitter.com/share?url=${eventLink}&amp;hashtags=humanitix`, "_blank")
                    }
                    aria-label="Share - Twitter"
                  >
                    <Image
                      className={styles.shareLogo}
                      src={"https://static.humanitix.com/ticketing/ic_twitter_colour.svg"}
                      alt="Twitter Icon"
                    />
                  </button>
                  <button
                    className={styles.buttonSocial}
                    onClick={() =>
                      window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${eventLink}`, "_blank")
                    }
                    aria-label="Share - Linked In"
                  >
                    <Image
                      className={styles.shareLogo}
                      src={"https://static.humanitix.com/ticketing/ic_linkedin_colour.svg"}
                      alt="LinkedIn Icon"
                    />
                  </button>
                  <div className={styles.copyBubble}>
                    <CopyToClipboard text={eventLink}>
                      <button className={styles.buttonSocial} aria-label="Copy Button" aria-hidden>
                        <Image
                          className={styles.shareLogo}
                          src={"https://static.humanitix.com/ticketing/ic_copy_link.svg"}
                          alt="Copy Button"
                        />
                      </button>
                    </CopyToClipboard>
                  </div>
                </div>
                <div className={inputWrapper}>
                  <input type="text" value={eventLink} readonly />
                  <CopyToClipboard text={eventLink}>
                    <Button type="primary">Copy</Button>
                  </CopyToClipboard>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div />
        )}
      </div>
    );
  }
}

export default Share;
