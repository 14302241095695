import axios from "./AxiosWrapper";
import config from "../config";

const FollowHostService = {
  follow(organiserId, followerInfo) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${config("API_URL")}follow-host/${organiserId}`, followerInfo)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
};

export default FollowHostService;
