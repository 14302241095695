import { h } from "preact";
import { css } from "emotion";
import { max } from "../utils/CssUtils";
import { getCurrencySymbol } from "../utils/Format";

const feesStr = css({
  color: "#5A626C",
  fontSize: 11,
  fontWeight: "normal",
  display: "inline-block",
  marginBottom: 0,
  [max(700)]: {
    display: "block"
  }
});

const PriceRangeDisplay = ({ event }) => {
  const symbol = getCurrencySymbol(event.currency);
  const ticketsPriceStr = event.settings.ticketPriceRangeDisplay
    ? event.ticketsPriceStr.replace("From ", "").replace("To", "-").replace("$", symbol)
    : "";

  const priceParts = ticketsPriceStr.split("+");
  const ticketsPriceRange = priceParts[0];
  const feesList = priceParts.splice(1).map((fee) => `+${fee}`);
  return (
    <div>
      {ticketsPriceRange}
      <p className={feesStr}>{feesList}</p>
    </div>
  );
};

export default PriceRangeDisplay;
