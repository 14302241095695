import axios from "./AxiosWrapper";
import config from "../config";
import { determineManualOrder } from "../utils/ManualOrderUtils";

const MerchandiseService = {
  list(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${config("API_URL")}merchandise/${id}/list`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  create(orderId, merchandiseData) {
    const isManualOrder = determineManualOrder();

    return new Promise((resolve, reject) => {
      axios
        .put(`${config("API_URL")}merchandise/${orderId}/create`, { merchandiseData, isManualOrder })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getMerch(orderId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${config("API_URL")}merchandise/${orderId}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
};
export default MerchandiseService;
