import { h } from "preact";
import { connect } from "react-redux";
const ActionButton = ({
  onClick,
  loading,
  disabled,
  children,
  style,
  classNames,
  dataCY = "default",
  routing,
  isRouteButton,
  id
}) => {
  style = style
    ? style
    : {
        float: "right"
      };
  classNames = classNames ? classNames : "btn-primary btn-lg";
  let contents = children;
  if (isRouteButton) {
    const { routeList, selectedStep } = routing;
    const route = routeList[selectedStep];
    if (route) {
      contents = route.buttonLabel;
    }
  }
  return (
    <button
      id={id}
      disabled={disabled}
      onClick={onClick}
      class={loading ? `btn btn-primary btn-lg ${classNames} loading` : `btn btn-primary btn-lg ${classNames}`}
      style={style}
      data-cy={dataCY}
    >
      {contents}
    </button>
  );
};
export default connect(
  (state) => ({
    routing: state.routing
  }),
  {}
)(ActionButton);
