import {
  OPEN_FOLLOW_HOST_MODAL,
  CLOSE_FOLLOW_HOST_MODAL,
  FOLLOW_HOST_REQUEST,
  FOLLOW_HOST_SUCCESS,
  FOLLOW_HOST_ERROR
} from "../actions/followHostActions";

const initialState = {
  showModal: false,
  loading: false,
  error: false,
  result: {
    success: null,
    alreadyFollowing: null
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_FOLLOW_HOST_MODAL:
      return {
        ...state,
        showModal: true
      };
    case CLOSE_FOLLOW_HOST_MODAL:
      return {
        ...state,
        showModal: false
      };
    case FOLLOW_HOST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FOLLOW_HOST_SUCCESS:
      return {
        ...state,
        loading: false,
        result: { ...initialState.result, ...action.result }
      };
    case FOLLOW_HOST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
        result: {
          ...initialState.result,
          success: false
        }
      };
    default:
      return state;
  }
};
export default {
  initialState,
  reducer
};
