import { css, cx } from "emotion";
import { connect } from "react-redux";

const Button = ({
  type = "secondary",
  buttonType = "button",
  size = "default",
  link,
  onClick = (_) => {},
  event,
  tourTheme = false,
  children,
  ariaLabel,
  fullWidth = false,
  height = 40,
  form,
  dataCY = "default",
  style = {},
  target = "_self",
  className
}) => {
  let theme = event && event.data && event.data.theme ? event.data.theme : false;
  theme = tourTheme ? tourTheme : theme;
  const buttonDefaults = {
    //anchor's display needs to be set to block or inline-block to accept the width and height values.
    //https://stackoverflow.com/a/4743269/6829750
    display: "block",
    textAlign: "center",
    width: fullWidth ? "100%" : "auto",
    fontWeight: 600,
    fontSize: size === "small" ? 14 : 16,
    borderRadius: 4,
    paddingLeft: 16,
    paddingRight: 16,
    height: size === "small" ? 36 : height,
    paddingTop: link ? 2 : 0,
    paddingBottom: 0,
    lineHeight: 2.2,
    textDecoration: "none !important",
    border: "solid 1px transparent"
  };
  const styles = {
    primary: {
      backgroundColor: theme && theme.primaryColor ? theme.primaryColor : "#353337",
      border: "solid 1px transparent",
      "&, &:visited": {
        color: theme && theme.primaryTextColor ? theme.primaryTextColor : "#fff"
      },
      "&:focus, &:hover": {
        backgroundColor: theme && theme.primaryTextColor ? theme.primaryTextColor : "#353337",
        border: theme && theme.primaryColor ? `solid 1px ${theme.primaryColor}` : "solid 1px #41157a",
        color: theme && theme.primaryColor ? `${theme.primaryColor}` : "#fff"
      }
    },
    secondary: {
      backgroundColor: "transparent",
      border: theme && theme.primaryColor ? `solid 1px ${theme.primaryColor}` : "solid 1px #353337",
      "&, &:visited": {
        color: theme && theme.primaryColor ? `${theme.primaryColor}` : "#353337"
      },
      "&:focus, &:hover": {
        backgroundColor: theme && theme.primaryColor ? theme.primaryColor : "#353337",
        border: "solid 1px transparent",
        color: theme && theme.primaryTextColor ? theme.primaryTextColor : "#fff"
      }
    },
    tertiary: {
      backgroundColor: "transparent",
      border: "solid 1px #788391",
      "&, &:visited": {
        color: "#323232"
      },
      "&:focus, &:hover": {
        backgroundColor: "#788391",
        border: "solid 1px transparent",
        color: "#fff"
      }
    },
    imageInButton: {
      backgroundColor: "transparent",
      border: "solid 1px #dedede",
      color: "#323232",
      "&:hover": {
        color: "#fff"
      }
    }
  };

  return link ? (
    <a
      className={cx(
        css`
          ${buttonDefaults};
          ${styles[type]};
          ${style};
        `,
        className
      )}
      href={link}
      target={target}
    >
      {children}
    </a>
  ) : (
    <button
      className={cx(
        css`
          ${buttonDefaults};
          ${styles[type]};
          ${style};
        `,
        className
      )}
      type={buttonType}
      onClick={onClick}
      aria-label={ariaLabel}
      formId={form}
      data-cy={dataCY}
    >
      {children}
    </button>
  );
};

export default connect((state, ownProps) => ({
  event: state.event,
  order: state.order
}))(Button);
