const RefundPolicy = ({ event }) => {
  let refundPolicy =
    event.paymentOptions.refundSettings && event.paymentOptions.refundSettings.refundPolicy !== ""
      ? event.paymentOptions.refundSettings.refundPolicy
      : false;
  refundPolicy = refundPolicy === "Custom" ? event.paymentOptions.refundSettings.customRefundPolicy : refundPolicy;

  if (!refundPolicy) {
    return null;
  }
  return (
    <div style={{ marginTop: 40 }}>
      <h3 style={{ fontWeight: 600 }}>Refund Policy</h3>
      <p>{refundPolicy}</p>
    </div>
  );
};

export default RefundPolicy;
