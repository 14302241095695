import { STRIPE_CHECKOUT_REQUEST, STRIPE_CHECKOUT_SUCCESS, STRIPE_CHECKOUT_ERROR } from "../actions/stripeActions";

const initialState = {
  loading: false,
  error: false,
  loaded: false,
  clientSecret: null,
  paymentIntentId: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case STRIPE_CHECKOUT_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
        clientSecret: null,
        paymentIntentId: null
      };
    case STRIPE_CHECKOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        clientSecret: action.clientSecret,
        paymentIntentId: action.paymentIntentId
      };
    case STRIPE_CHECKOUT_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        clientSecret: null,
        paymentIntentId: null
      };
    default:
      return state;
  }
};
export default {
  initialState,
  reducer
};
