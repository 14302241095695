import { h } from "preact";
import EventMap from "./EventMap";
import LocationStr from "./LocationStr";
import { css } from "emotion";
import Directions from "./Directions";
const icon = "https://static.humanitix.com/ticketing/ic_location_pin.svg";

const Location = ({ location, oldStyle }) => {
  const style = css({
    marginBottom: 40,
    background: oldStyle ? `url(${icon})` : "",
    backgroundSize: oldStyle ? "24px 24px" : "",
    backgroundRepeat: oldStyle ? "no-repeat" : "",
    backgroundPosition: oldStyle ? "left top" : "",
    paddingLeft: oldStyle ? 30 : "",
    wordBreak: "break-word",
    "@media(max-width: 600px)": {
      background: `url(${icon})`,
      backgroundSize: "24px 24px",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "left top",
      paddingLeft: 30,
      paddingBottom: 5,
      marginBottom: 24
    },
    p: {
      marginBottom: 4
    },
    a: {
      marginBottom: 12,
      display: "block",
      fontWeight: 600,
      textDecoration: "none"
    }
  });
  const title = location.type === "online" ? "Online Event" : "Location";
  return (
    <div className={style}>
      <h3 style={{ marginBottom: 12, fontWeight: 600, paddingTop: 3 }}>{title}</h3>
      <LocationStr location={location} />
      <Directions location={location} />
      <EventMap location={location} />
    </div>
  );
};
export default Location;
