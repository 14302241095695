import EventsService from "../../services/EventsService";

export const CLEAR_TICKETS = "CLEAR_TICKETS";

export const GET_TICKETS_REQUEST = "GET_TICKETS_REQUEST";
export const GET_TICKETS_SUCCESS = "GET_TICKETS_SUCCESS";
export const GET_TICKETS_ERROR = "GET_TICKETS_ERROR";

export const CHECK_ACCESS_CODE_REQUEST = "CHECK_ACCESS_CODE_REQUEST";
export const CHECK_ACCESS_CODE_SUCCESS = "CHECK_ACCESS_CODE_SUCCESS";
export const CHECK_ACCESS_CODE_ERROR = "CHECK_ACCESS_CODE_ERROR";

export const ADD_TICKET_QUANTITY = "ADD_TICKET_QUANTITY";
export const ADD_TICKET_PRICE = "ADD_TICKET_PRICE";
export const ADD_USER_TICKET_PRICE = "ADD_USER_TICKET_PRICE";
export const ADD_TICKET_RANGE_INDEX = "ADD_TICKET_RANGE_INDEX";
export const UPDATE_DONATION_AMOUNT = "UPDATE_DONATION_AMOUNT";
export const SELECT_DATE = "SELECT_DATE";
export const REMOVE_ACCESS_CODE = "REMOVE_ACCESS_CODE";
export const SHOW_ACCESS_CODES = "SHOW_ACCESS_CODES";

export const SHOW_CUSTOM_DONATION_FIELD = "SHOW_CUSTOM_DONATION_FIELD";

import { loadTicketRequestSummary } from "./orderSummaryActions";
import { removeURLParameter } from "../../utils/URLs";

export const clearTickets = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_TICKETS });
  };
};

export const showAccessCodes = () => {
  return (dispatch) => {
    dispatch({ type: SHOW_ACCESS_CODES });
  };
};

export const checkAccessCode = (eventId, accessCode, forceLoadTickets = true) => {
  return (dispatch, getState) => {
    dispatch({ type: CHECK_ACCESS_CODE_REQUEST, eventId, accessCode });
    EventsService.checkAccessCode(eventId, accessCode)
      .then(() => {
        const state = getState();
        dispatch({ type: CHECK_ACCESS_CODE_SUCCESS, accessCode });
        if (forceLoadTickets) {
          dispatch(loadTickets(state.event.id, state.ticketSelection.selectedDateId, accessCode));
        }
      })
      .catch((err) => {
        const message = err.response.status === 404 ? "Access password not valid" : "Error apply access password";
        dispatch({ type: CHECK_ACCESS_CODE_ERROR, error: message });
      });
  };
};

export const removeAccessCode = () => {
  return (dispatch, getState) => {
    removeURLParameter("accesscode");
    dispatch({ type: REMOVE_ACCESS_CODE });
    const state = getState();
    dispatch(loadTickets(state.event.id, state.ticketSelection.selectedDateId, null));
  };
};

export const loadTickets = (eventId, dateId, accessCode) => {
  return (dispatch) => {
    dispatch({ type: GET_TICKETS_REQUEST, eventId, dateId });
    EventsService.getTickets(eventId, dateId, accessCode)
      .then((tickets) => {
        dispatch({ type: GET_TICKETS_SUCCESS, tickets });
      })
      .catch((err) => {
        dispatch({ type: GET_TICKETS_ERROR, error: err });
      });
  };
};

export const addQuantity = (ticketTypeId, quantity) => {
  return (dispatch, getState) => {
    dispatch({ type: ADD_TICKET_QUANTITY, ticketTypeId, quantity });
    loadTicketRequestSummary()(dispatch, getState);
  };
};

export const addPrice = (ticketTypeId, price) => {
  return (dispatch, getState) => {
    dispatch({ type: ADD_TICKET_PRICE, ticketTypeId, price });
    loadTicketRequestSummary()(dispatch, getState);
  };
};

export const addRangeIndex = (ticketTypeId, index) => {
  return (dispatch, getState) => {
    dispatch({ type: ADD_TICKET_RANGE_INDEX, ticketTypeId, index });
    loadTicketRequestSummary()(dispatch, getState);
  };
};

export const addUserTicketPrice = (ticketTypeId, price) => {
  return (dispatch, getState) => {
    dispatch({ type: ADD_USER_TICKET_PRICE, ticketTypeId, price });
    loadTicketRequestSummary()(dispatch, getState);
  };
};

export const updateDonationAmount = (amount, error) => {
  return (dispatch, getState) => {
    dispatch({ type: UPDATE_DONATION_AMOUNT, amount, error });
    loadTicketRequestSummary()(dispatch, getState);
  };
};

export const showCustomDonationField = () => {
  return (dispatch) => {
    dispatch({ type: SHOW_CUSTOM_DONATION_FIELD, donationOtherOption: true });
  };
};

export const selectDate = (dateId, dateDisplay, startDate) => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch({ type: SELECT_DATE, dateId, dateDisplay, startDate });
    loadTicketRequestSummary()(dispatch, getState);
    if (dateId && dateId !== "null") {
      loadTickets(state.event.data._id, dateId, state.ticketSelection.accessCode)(dispatch);
    }
  };
};
