import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { css } from "emotion";
import InputField from "../fields/InputField";
import ActionButton from "../ActionButton";
import { email, required } from "../../utils/Validators";
import { max } from "../../utils/CssUtils";

let FollowHostModalForm = ({ hostPicture, hostTitle, handleSubmit, followHost, handleSave }) => {
  const { success } = followHost.result;

  return (
    <form onSubmit={handleSubmit(handleSave)} novalidate>
      <div className={wrapperStyle}>
        <div style={{ textAlign: "center" }}>{hostPicture}</div>
        <h3 style={{ marginBottom: 20 }}>Add your details to follow {hostTitle} and get alerts for new events.</h3>
        <div className={nameFiledsWrapper}>
          <span className={nameFieldStyle}>
            <Field
              label="First name"
              type="text"
              name="firstName"
              placeholder="First name"
              component={InputField}
              required
              validate={required}
              style={{ width: "100%" }}
            />
          </span>
          <span className={nameFieldStyle}>
            <Field
              label="Last name"
              placeholder="Last name"
              type="text"
              name="lastName"
              component={InputField}
              required
              validate={required}
              style={{ width: "100%" }}
            />
          </span>
        </div>

        <Field
          label="Email"
          type="email"
          name="email"
          placeholder="email@example.com"
          component={InputField}
          required
          validate={[required, email]}
        />
        <div style={{ width: "100%", margin: "20px 0px 30px" }}>
          <ActionButton loading={followHost.loading}>Get alerts</ActionButton>
          {success === false ? <p style={{ color: "red" }}>Something went wrong. Try again!</p> : null}
        </div>
      </div>
    </form>
  );
};

FollowHostModalForm = reduxForm({
  enableReinitialize: true,
  form: "followHost"
})(FollowHostModalForm);

export default connect((state) => {
  return {
    initialValues: {},
    followHost: state.followHost
  };
}, {})(FollowHostModalForm);

const wrapperStyle = css({
  textAlign: "left"
});

const nameFiledsWrapper = css({
  display: "flex",
  justifyContent: "space-between",
  [max(600)]: {
    display: "block"
  },
  marginBottom: 10
});

const nameFieldStyle = css({
  width: "49%",
  [max(600)]: {
    width: "100%"
  }
});
