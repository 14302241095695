import { h } from "preact";
import EventInfoBox from "../../components/EventInfoBox";
import Picture from "../../components/Picture";
import { css } from "emotion";

const EventBanner = ({ event, theme }) => {
  const removeBlur = !!(
    theme &&
    theme.background &&
    (theme.type === "custom" || theme.type === "dark") &&
    ((theme.background.image && theme.background.image.handle) || theme.background.color)
  );
  let blurBackgroundColor = theme && theme.background && theme.type === "dark" ? "#1d1d1d" : "#fff";

  const blurDiv = event.useOldBannerStyle
    ? css({
        backgroundImage: !removeBlur
          ? `url(https://cdn.filestackcontent.com/blur=amount:10/resize=width:300/${event.displayImage.handle})`
          : "none",
        backgroundSize: "cover",
        backgroundColor: !removeBlur ? blurBackgroundColor : "transparent",
        zIndex: 99999
      })
    : css({
        backgroundImage: !removeBlur
          ? `url(https://cdn.filestackcontent.com/blur=amount:10/resize=width:300/${
              event.displayImage ? event.displayImage.handle : "VbieTVjAQvq5BTkE9vIi"
            })`
          : "none",
        backgroundSize: "cover",
        backgroundColor: !removeBlur ? blurBackgroundColor : "transparent",
        zIndex: 99999
      });

  const wrapperDiv = css({
    maxWidth: 1040,
    margin: "auto",
    padding: "30px 0",
    "@media(max-width:600px)": {
      padding: 0
    }
  });

  const bannerImageWrapper = css({
    maxWidth: 720,
    border: event.displayImage ? "none" : "1px solid #d7dee6",
    borderRadius: 4,
    float: "left",
    width: "100%",
    margin: 0,
    img: {
      borderRadius: 4
    },
    "@media(max-width:600px)": {
      borderRadius: 0,
      img: {
        borderRadius: 0
      }
    },
    "@media(max-width:1030px)": {
      float: "none",
      margin: "0 auto"
    }
  });

  return event.useOldBannerStyle ? (
    <div className={blurDiv}>
      <div
        style={{
          margin: "0px auto",
          maxWidth: 1000,
          display: "block",
          borderRadius: 4
        }}
      >
        <Picture img={event.displayImage} alt={`${event.name} Event Banner`} width={1000} />
      </div>
    </div>
  ) : (
    <div className={blurDiv}>
      <div className={wrapperDiv}>
        <div className={bannerImageWrapper}>
          <Picture img={event.displayImage} alt={`${event.name} Event Banner`} width={720} />
        </div>
        <EventInfoBox event={event} />
        <div
          style={{
            clear: "both"
          }}
        />
      </div>
    </div>
  );
};

export default EventBanner;
