import {
  GET_ORDER_REQ_SUCCESS,
  SAVE_ORDER_DATA_SUCCESS,
  GET_ORDER_SUCCESS,
  PAYMENT_SUCCESS
} from "../../actions/orderActions";
import { GET_EVENT_SUCCESS } from "../../actions/eventActions";
import { GET_GIFT_CARD_PAGE_SUCCESS } from "../../actions/giftCardActions";
import { CREATE_MERCHANDISE_SUCCESS } from "../../actions/merchandiseActions";

import { constructRouteStack } from "../../actions/routingActions";

const actionMapper = {
  [GET_ORDER_REQ_SUCCESS]: constructRouteStack,
  [GET_EVENT_SUCCESS]: constructRouteStack,
  [SAVE_ORDER_DATA_SUCCESS]: constructRouteStack,
  [GET_ORDER_SUCCESS]: constructRouteStack,
  [GET_GIFT_CARD_PAGE_SUCCESS]: constructRouteStack,
  [CREATE_MERCHANDISE_SUCCESS]: constructRouteStack,
  [PAYMENT_SUCCESS]: constructRouteStack
};

export const navigationPostHook = (store) => {
  const {
    lastAction: { type }
  } = store.getState();
  if (actionMapper[type]) {
    store.dispatch(actionMapper[type]());
  }
};
