export default function normaliseData(merchOrder) {
  const isNotNormalised = merchOrder.length > 0 && merchOrder[0].options;
  if (isNotNormalised) {
    const copiedMerch = JSON.parse(JSON.stringify(merchOrder));
    const merchWithQuantity = copiedMerch.filter((merch) => {
      merch.options = merch.options.filter((opts) => {
        return opts.quantity > 0;
      });
      return merch.options.length > 0;
    });
    const normalisedMerch = merchWithQuantity.reduce((cur, merch) => {
      return cur.concat(
        merch.options.map((opt) => {
          const parentMerch = {
            ...merch
          };
          delete parentMerch.options;
          return {
            ...parentMerch,
            ...opt
          };
        })
      );
    }, []);
    return normalisedMerch;
  }
  return merchOrder;
}
