import {
  GET_EVENT_DATES_ERROR,
  GET_EVENT_DATES_REQUEST,
  GET_EVENT_DATES_SUCCESS,
  GET_NEAREST_EVENT_DATES_REQUEST,
  GET_NEAREST_EVENT_DATES_ERROR,
  GET_NEAREST_EVENT_DATES_SUCCESS,
  GET_DATE_REQUEST,
  GET_DATE_SUCCESS,
  GET_DATE_ERROR
} from "../actions/eventActions";

const initialState = {
  dates: undefined,
  month: new Date(),
  loading: false,
  error: false,
  selectedDate: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_EVENT_DATES_ERROR:
      return {
        ...state,
        error: true,
        loading: false
      };
    case GET_EVENT_DATES_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        dates: action.dates
      };
    case GET_EVENT_DATES_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
        month: action.month
      };
    case GET_NEAREST_EVENT_DATES_REQUEST:
      return {
        ...state,
        error: false,
        loading: true
      };
    case GET_NEAREST_EVENT_DATES_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        dates: action.dates,
        month: action.month
      };
    case GET_NEAREST_EVENT_DATES_ERROR:
      return {
        ...state,
        error: true,
        loading: false
      };
    case GET_DATE_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        selectedDate: action.date
      };
    case GET_DATE_ERROR:
      return {
        ...state,
        error: true,
        loading: false
      };
    case GET_DATE_REQUEST:
      return {
        ...state,
        error: false,
        loading: true
      };
    default:
      return state;
  }
};
export default {
  initialState,
  reducer
};
