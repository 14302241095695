import axios from "./AxiosWrapper";
import config from "../config";

const VirtualEventHubService = {
  get(eventId, ticketId, previewId, accessId) {
    let url = `${config("API_URL")}virtual-event-hub/${eventId}/${ticketId}`;
    if (previewId) {
      url = `${config("API_URL")}virtual-event-hub/preview/${previewId}`;
    }
    const options = { params: {} };
    if (accessId) {
      options.params.accessId = accessId;
    }
    return new Promise((resolve, reject) => {
      axios
        .get(url, options)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  heartbeat(ticketId, accessId) {
    let url = `${config("API_URL")}virtual-event-hub/heartbeat/${ticketId}/${accessId}`;
    return new Promise((resolve, reject) => {
      axios
        .get(url)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
};

export default VirtualEventHubService;
