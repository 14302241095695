import { h } from "preact";
import DatesDisplay from "./DatesDisplay";
import { css } from "emotion";
const icon = "https://static.humanitix.com/ticketing/ic_date.svg";

const Dates = ({ event, eventSlug, oldStyle, addToCalendar }) => {
  const dates = event.dates;
  if (event && event.settings && event.settings.hideAddToCalendar) {
    return "";
  }
  const style = css({
    marginBottom: 40,
    background: oldStyle ? `url(${icon})` : "",
    backgroundSize: oldStyle ? "24px 24px" : "",
    backgroundRepeat: oldStyle ? "no-repeat" : "",
    backgroundPosition: oldStyle ? "left top" : "",
    paddingLeft: oldStyle ? 30 : "",
    "@media(max-width: 600px)": {
      background: `url(${icon})`,
      backgroundSize: "24px 24px",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "left top",
      paddingLeft: 30,
      marginBottom: 32
    }
  });
  if (!dates) {
    return "";
  }
  return (
    <div className={style}>
      <h3 style={{ marginBottom: 12, fontWeight: 600, paddingTop: 3 }}>Date and time</h3>
      <DatesDisplay dates={dates} eventSlug={eventSlug} />
      <div>{addToCalendar}</div>
    </div>
  );
};
export default Dates;
