import GiftCardService from "../../services/GiftCardService";

export const GET_GIFT_CARD_PAGE_REQUEST = "GET_GIFT_CARD_PAGE_REQUEST";
export const GET_GIFT_CARD_PAGE_SUCCESS = "GET_GIFT_CARD_PAGE_SUCCESS";
export const GET_GIFT_CARD_PAGE_ERROR = "GET_GIFT_CARD_PAGE_ERROR";

export const GET_GIFT_CARD_EVENTS_REQUEST = "GET_GIFT_CARD_EVENTS_REQUEST";
export const GET_GIFT_CARD_EVENTS_SUCCESS = "GET_GIFT_CARD_EVENTS_SUCCESS";
export const GET_GIFT_CARD_EVENTS_ERROR = "GET_GIFT_CARD_EVENTS_ERROR";

export const SET_GIFT_CARD_LOCATION = "SET_GIFT_CARD_LOCATION";

export const getGiftCardPage = (slug) => {
  return async (dispatch) => {
    dispatch({ type: GET_GIFT_CARD_PAGE_REQUEST });
    try {
      const pageData = await GiftCardService.getPage(slug);
      dispatch({
        type: GET_GIFT_CARD_PAGE_SUCCESS,
        giftCardPage: pageData.page,
        giftCards: pageData.giftCards
      });
    } catch (err) {
      dispatch({ type: GET_GIFT_CARD_PAGE_ERROR, error: err });
    }
  };
};

export const getGiftCardEvents = (id, page) => {
  return async (dispatch) => {
    dispatch({ type: GET_GIFT_CARD_EVENTS_REQUEST, page });
    try {
      const { events, total } = await GiftCardService.getEvents(id, page);
      dispatch({
        type: GET_GIFT_CARD_EVENTS_SUCCESS,
        events,
        total
      });
    } catch (err) {
      dispatch({ type: GET_GIFT_CARD_EVENTS_ERROR, error: err });
    }
  };
};

export const setGiftCardLocation = (location) => {
  return async (dispatch) => {
    dispatch({ type: SET_GIFT_CARD_LOCATION, location });
  };
};
