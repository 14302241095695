import { h, Component } from "preact";
import Page from "../../components/Page";
import Content from "../../components/Content";
import EventError from "../../components/EventError";

export default class NoEvent extends Component {
  render() {
    return (
      <Page border={false}>
        <Content>
          <EventError type="notFound" />
        </Content>
      </Page>
    );
  }
}
