import { h } from "preact";

const Required = ({ required }) => {
  return required ? (
    <span aria-hidden="true" style={{ color: "#c0212c" }}>
      *
    </span>
  ) : (
    ""
  );
};
export default Required;
