import { HumaniDate, DateFormats, TimeFormats, TimezoneFormats } from "@hx/dates";

export function convertDateForOnlineEvent(startDate, endDate, location) {
  const startDateHumaniDate = new HumaniDate(startDate, "guess", location);
  const endDateHumaniDate = new HumaniDate(endDate, "guess", location);

  return {
    time: `${startDateHumaniDate.formatting.time(TimeFormats.Short)} - ${endDateHumaniDate.formatting.time(
      TimeFormats.Short
    )} ${startDateHumaniDate.formatting.timezone(TimezoneFormats.Name)}`,
    date: startDateHumaniDate.formatting.date(DateFormats.Full),
    timezone: startDateHumaniDate.formatting.timezone(TimezoneFormats.Name),
    combined: startDateHumaniDate.formatting.dateRange(endDateHumaniDate)
  };
}

export function getDayOfMonthForDate(startDate) {
  return new HumaniDate(startDate, "guess").dayJs.get("date");
}
