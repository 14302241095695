import {
  GET_MERCHANDISE_LIST_ERROR,
  GET_MERCHANDISE_LIST_REQUEST,
  GET_MERCHANDISE_LIST_SUCCESS,
  CREATE_MERCHANDISE_ERROR,
  CREATE_MERCHANDISE_REQUEST,
  CREATE_MERCHANDISE_SUCCESS,
  GET_MERCHANDISE_ERROR,
  GET_MERCHANDISE_REQUEST,
  GET_MERCHANDISE_SUCCESS,
  RESET_MERCHANDISE
} from "../actions/merchandiseActions";

const initialState = {
  includesMerch: false,
  listMerch: {
    loading: false,
    error: false,
    data: false
  },
  merch: {
    loading: false,
    error: false,
    data: [],
    display: []
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_MERCHANDISE:
      return {
        ...initialState
      };

    case GET_MERCHANDISE_LIST_REQUEST:
      return {
        ...state,
        listMerch: {
          ...state.listMerch,
          loading: true,
          error: false
        }
      };

    case GET_MERCHANDISE_LIST_SUCCESS:
      return {
        ...state,
        listMerch: {
          ...state.listMerch,
          loading: false,
          error: false,
          data: action.merchList
        }
      };

    case GET_MERCHANDISE_LIST_ERROR:
      return {
        ...state,
        listMerch: {
          ...state.listMerch,
          loading: false,
          error: true
        }
      };
    case CREATE_MERCHANDISE_REQUEST:
      return {
        ...state,
        merch: {
          ...state.merch,
          loading: true,
          error: false
        }
      };
    case CREATE_MERCHANDISE_SUCCESS:
      return {
        ...state,
        merch: {
          ...state.merch,
          loading: false,
          data: action.data,
          display: createMerchDisplay(action.data),
          error: false
        }
      };
    case CREATE_MERCHANDISE_ERROR:
      return {
        ...state,
        merch: {
          ...state.merch,
          loading: false,
          error: action.error
        }
      };
    case GET_MERCHANDISE_REQUEST:
      return {
        ...state,
        merch: {
          ...state.merch,
          loading: true,
          error: false
        }
      };
    case GET_MERCHANDISE_SUCCESS:
      return {
        ...state,
        includesMerch: action.offer,
        merch: {
          ...state.merch,
          loading: false,
          data: action.data,
          display: createMerchDisplay(action.data),
          error: false
        }
      };
    case GET_MERCHANDISE_ERROR:
      return {
        ...state,
        merch: {
          ...state.merch,
          loading: false,
          error: true
        }
      };
    default:
      return state;
  }
};

const createMerchDisplay = (merchandise) => {
  return merchandise.reduce((cur, merch) => {
    const merchFound = cur.find(
      (m) => m.merchandiseTypeId === merch.merchandiseTypeId && m.merchandiseOptionId === merch.merchandiseOptionId
    );
    if (merchFound) {
      merchFound.quantity = merchFound.quantity + 1;
    } else {
      cur.push({
        ...merch,
        quantity: 1
      });
    }
    return cur;
  }, []);
};

export default {
  initialState,
  reducer,
  createMerchDisplay
};
