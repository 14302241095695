import axios from "./AxiosWrapper";
import queryString from "query-string";
import config from "../config";
import { determineManualOrder } from "../utils/ManualOrderUtils";
const EventsService = {
  get(slug, token, orderId, eventDateId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${config("API_URL")}event/${slug}`, {
          params: {
            token,
            orderId,
            eventDateId
          }
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  async getEventPublic(eventId, orderId, token) {
    const response = await axios.get(`${config("API_URL")}public/edit-order/event/${orderId}/${eventId}/${token}`);
    return response.data;
  },
  getAdditionalQuestionsPreview(slug) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${config("API_URL")}public/additional-questions-preview/${slug}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getCompleteMessagePreview(slug) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${config("API_URL")}public/complete-message-preview/${slug}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getAllByUser(userId, organiserId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${config("API_URL")}event/user-events/${userId}/${organiserId}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getTickets(eventId, eventDateId, accessCode) {
    const isBrowser = typeof window !== "undefined";
    let offerId;
    if (isBrowser) {
      const parsed = queryString.parse(window.location.search);
      // get the offer id for waitlist clients
      offerId = parsed.offerid;
    }
    return new Promise((resolve, reject) => {
      axios
        .get(`${config("API_URL")}event/tickets/`, {
          params: {
            eventId,
            eventDateId,
            accessCode,
            offerId,
            isManualOrder: determineManualOrder()
          }
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  checkAccessCode(eventId, accessCode) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${config("API_URL")}access-codes/check`, {
          eventId,
          accessCode
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getDates(eventId, month, year) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${config("API_URL")}event/dates/${eventId}`, {
          params: {
            month,
            year
          }
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getNearestDate(eventId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${config("API_URL")}event/dates/nearest/${eventId}`, {
          params: {
            date: new Date()
          }
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getDate(eventSlug, eventDateId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${config("API_URL")}event/dates/${eventSlug}/${eventDateId}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
};

export default EventsService;
