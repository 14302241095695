import { GET_EVENT_SUCCESS, GET_EVENT_REQUEST, GET_EVENT_ERROR } from "../actions/eventActions";

import { CLEAR_ORDER, GET_ORDER_SUCCESS, PAYMENT_SUCCESS, GET_ORDER_REQ_ERROR } from "../actions/orderActions";

import {
  CLEAR_TICKETS,
  GET_TICKETS_REQUEST,
  GET_TICKETS_SUCCESS,
  GET_TICKETS_ERROR,
  ADD_TICKET_QUANTITY,
  UPDATE_DONATION_AMOUNT,
  SELECT_DATE,
  SHOW_ACCESS_CODES,
  CHECK_ACCESS_CODE_REQUEST,
  CHECK_ACCESS_CODE_SUCCESS,
  CHECK_ACCESS_CODE_ERROR,
  REMOVE_ACCESS_CODE,
  SHOW_CUSTOM_DONATION_FIELD,
  ADD_TICKET_PRICE,
  ADD_TICKET_RANGE_INDEX,
  ADD_USER_TICKET_PRICE
} from "../actions/ticketsActions";

const initialState = {
  tickets: null,
  requestedTickets: false,
  clientDonation: 0,
  clientDonationError: false,
  donationOtherOption: false,
  selectedDateId: null,
  selectedDateDisplay: null,
  accessCode: null,
  showAccessCode: false,
  ticketsRequest: {
    loaded: false,
    loading: false,
    error: false
  },
  accessCodeRequest: {
    loading: false,
    error: false
  }
};

const eventReduce = (event, state, eventDate) => {
  return {
    ...state,
    tickets: null,
    requestedTickets: false,
    selectedDateId: null,
    selectedDateDisplay: null,
    selectedStartDate: null,
    showAccessCode: false,
    accessCode: null,
    clientDonation: 0,
    clientDonationError: false
  };
};

const ticketsFromOrder = (order, selectedTickets) => {
  const selectedTicketsLookUp = {};
  if (selectedTickets) {
    selectedTickets.forEach((selectedTicket) => {
      selectedTicket.quantity = 0;
      selectedTicketsLookUp[selectedTicket._id] = selectedTicket;
    });
  }
  const tickets = order && order.tickets ? order.tickets : [];
  tickets.forEach((orderTicket) => {
    if (!selectedTicketsLookUp[orderTicket.ticketTypeId]) {
      let ticketData = {
        _id: orderTicket.ticketTypeId,
        quantity: 0,
        name: orderTicket.ticketTypeName,
        price: orderTicket.price,
        discountCodes: []
      };
      selectedTicketsLookUp[orderTicket.ticketTypeId] = ticketData;
    }
    if (orderTicket.discountCodes && orderTicket.discountCodes.length) {
      selectedTicketsLookUp[orderTicket.ticketTypeId].discountCodes = orderTicket.discountCodes;
    }
    selectedTicketsLookUp[orderTicket.ticketTypeId].quantity++;
  });
  return Object.keys(selectedTicketsLookUp).map((id) => selectedTicketsLookUp[id]);
};

const resetTicketQuantity = (tickets, state) => {
  const requestedTicketsCount = {};
  const requestedTickets = state.requestedTickets && state.requestedTickets.length > 0 ? state.requestedTickets : [];
  requestedTickets.forEach((ticket) => {
    if (typeof requestedTicketsCount[ticket._id] !== "undefined") {
      requestedTicketsCount[ticket._id] = requestedTicketsCount[ticket._id] + 1;
    } else {
      requestedTicketsCount[ticket._id] = 1;
    }
  });
  tickets = tickets.map((ticket) => {
    const setQuantity = requestedTicketsCount[ticket._id] ? requestedTicketsCount[ticket._id] : 0;
    if (ticket.ticketGroup && ticket.tickets.length) {
      ticket.tickets = ticket.ticketsWithInfo.map((t) => {
        t.quantity = setQuantity;
        return t;
      });
    }
    ticket.quantity = setQuantity;
    return ticket;
  });
  return tickets;
};

const addTicketQuantity = (ticket, ticketTypeId, quantity) => {
  if (ticket.ticketGroup && ticket.tickets) {
    updateTicketGroupQuantity(ticket.ticketsWithInfo, ticketTypeId, quantity);
    updateTicketGroupQuantity(ticket.tickets, ticketTypeId, quantity);
  }
  if (String(ticket._id) === String(ticketTypeId)) {
    ticket.quantity = quantity;
  }
  return ticket;
};

const updateTicketGroupQuantity = (tickets, ticketTypeId, quantity) => {
  for (let i = 0; i < tickets.length; i++) {
    const ticketInGroup = tickets[i];
    if (String(ticketInGroup._id) === String(ticketTypeId)) {
      ticketInGroup.quantity = quantity;
    }
  }
};

const injectUserEnteredPrice = (ticketsArray, matchingId, actionPrice) => {
  return ticketsArray.map((t) => {
    if (t.ticketGroup) {
      return {
        ...t,
        tickets: injectUserEnteredPrice(t.tickets, matchingId, actionPrice),
        ticketsWithInfo: injectUserEnteredPrice(t.ticketsWithInfo, matchingId, actionPrice)
      };
    } else if (t._id === matchingId) {
      const minimumAmount = t.priceRange && t.priceRange.min ? Number(t.priceRange.min) : 0;
      const maximumAmount = t.priceRange && t.priceRange.max ? Number(t.priceRange.max) : 999999999;
      let price = Number(actionPrice);
      if (actionPrice === "") {
        price = "";
      } else if (price < minimumAmount) {
        price = minimumAmount;
      } else if (price > maximumAmount) {
        price = maximumAmount;
      }
      return { ...t, userEnteredPrice: price };
    }
    return t;
  });
};

const addTicketPrice = (ticket, ticketTypeId, price) => {
  if (ticket.ticketGroup && ticket.tickets) {
    for (let i = 0; i < ticket.tickets.length; i++) {
      const ticketInGroup = ticket.tickets[i];
      if (ticketInGroup._id === ticketTypeId) {
        ticketInGroup.price = price;
        break;
      }
    }
  }
  if (ticket._id === ticketTypeId) {
    ticket.price = price;
  }
  return ticket;
};
const addPriceOptionsIndex = (ticket, action) => {
  if (ticket._id === action.ticketTypeId) {
    ticket.priceOptionsIndex = action.index;
  }
  if (ticket.ticketsWithInfo && ticket.ticketsWithInfo.length) {
    ticket.ticketsWithInfo = ticket.ticketsWithInfo.map((twi) => addPriceOptionsIndex(twi, action));
  }
  return ticket;
};

const flattenTicketsForRequest = (tickets) => {
  return tickets.reduce((flattenedList, ticket) => {
    if (ticket.quantity) {
      return flattenedList.concat(...Array(ticket.quantity).fill(ticket));
    }
    return flattenedList;
  }, []);
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_TICKETS:
      return initialState;
    case REMOVE_ACCESS_CODE:
      return {
        ...state,
        accessCode: null
      };
    case SHOW_ACCESS_CODES:
      return {
        ...state,
        showAccessCode: true
      };
    case CHECK_ACCESS_CODE_REQUEST:
      return {
        ...state,
        showAccessCode: true,
        accessCode: null,
        accessCodeRequest: {
          loading: true,
          error: false
        }
      };
    case CHECK_ACCESS_CODE_SUCCESS:
      return {
        ...state,
        accessCode: action.accessCode,
        accessCodeRequest: {
          loading: false,
          error: false
        }
      };
    case CHECK_ACCESS_CODE_ERROR:
      return {
        ...state,
        accessCode: null,
        accessCodeRequest: {
          loading: false,
          error: action.error
        }
      };
    case GET_EVENT_SUCCESS:
      return eventReduce(action.event, state);
    case GET_EVENT_REQUEST:
    case CLEAR_ORDER:
    case GET_EVENT_ERROR:
      return {
        ...state,
        tickets: []
      };

    case GET_TICKETS_REQUEST:
      return {
        ...state,
        tickets: [],
        ticketsRequest: {
          loading: true,
          error: false
        }
      };
    case GET_TICKETS_SUCCESS:
      return {
        ...state,
        tickets: resetTicketQuantity(action.tickets, state),
        ticketsRequest: {
          loaded: true,
          loading: false,
          error: false
        }
      };
    case GET_TICKETS_ERROR:
      return {
        ...state,
        tickets: [],
        ticketsRequest: {
          loading: false,
          error: action.error
        }
      };
    case ADD_TICKET_QUANTITY: {
      const tickets = state.tickets.map((t) => {
        let ticket = {
          ...t
        };
        ticket = addTicketQuantity(ticket, action.ticketTypeId, action.quantity);
        delete ticket.discountCodes;
        ticket.errors = null;
        return ticket;
      });
      return {
        ...state,
        tickets,
        requestedTickets: flattenTicketsForRequest(tickets)
      };
    }
    case ADD_TICKET_PRICE: {
      const updatedTickets = state.tickets.map((t) => {
        let ticket = {
          ...t
        };
        ticket = addTicketPrice(ticket, action.ticketTypeId, action.price);
        delete ticket.discountCodes;
        ticket.errors = null;
        return ticket;
      });
      return {
        ...state,
        tickets: updatedTickets,
        requestedTickets: flattenTicketsForRequest(updatedTickets)
      };
    }
    case ADD_TICKET_RANGE_INDEX: {
      const updatedTickets = state.tickets.map((t) => addPriceOptionsIndex(t, action));

      return {
        ...state,
        tickets: updatedTickets,
        requestedTickets: flattenTicketsForRequest(updatedTickets)
      };
    }
    case ADD_USER_TICKET_PRICE: {
      const updatedTickets = injectUserEnteredPrice(state.tickets, action.ticketTypeId, action.price);
      return {
        ...state,
        tickets: updatedTickets,
        requestedTickets: flattenTicketsForRequest(updatedTickets)
      };
    }
    case UPDATE_DONATION_AMOUNT:
      return {
        ...state,
        clientDonation: Number(action.amount),
        clientDonationError: action.error
      };
    case PAYMENT_SUCCESS:
    case GET_ORDER_SUCCESS: {
      const tickets = ticketsFromOrder(action.order, state.tickets || []);
      const clientDonation = action.order.clientDonation;
      return {
        ...state,
        tickets,
        requestedTickets: action.order.tickets.map((ticket) => ({
          ...ticket,
          quantity: 1
        })),
        clientDonation: isNaN(clientDonation) ? 0 : Number(clientDonation)
      };
    }
    case GET_ORDER_REQ_ERROR: {
      if (!action.error || !action.error.ticketErrors) {
        return state;
      }
      const errorLookUp = {};
      action.error.ticketErrors.forEach((ticketError) => {
        if (!errorLookUp[ticketError.ticketId]) {
          errorLookUp[ticketError.ticketId] = [];
        }
        errorLookUp[ticketError.ticketId].push(ticketError.error);
      });
      const tickets = state.tickets.map((t) => {
        t.errors = errorLookUp[t._id];
        return t;
      });
      return {
        ...state,
        tickets
      };
    }
    case SELECT_DATE: {
      return {
        ...state,
        tickets: [],
        selectedDateId: action.dateId,
        selectedDateDisplay: action.dateDisplay,
        selectedStartDate: action.startDate
      };
    }
    case SHOW_CUSTOM_DONATION_FIELD: {
      return {
        ...state,
        donationOtherOption: action.donationOtherOption
      };
    }
    default:
      return state;
  }
};
export default {
  initialState,
  reducer,
  eventReduce,
  ticketsFromOrder
};
