import {
  GET_COUNTRY_INFO_ERROR,
  GET_COUNTRY_INFO_REQUEST,
  GET_COUNTRY_INFO_SUCCESS,
  GET_STATE_INFO_ERROR,
  GET_STATE_INFO_REQUEST,
  GET_STATE_INFO_SUCCESS
} from "../actions/AddressActions";

const initialState = {
  countries: {
    loading: false,
    info: []
  },
  states: {
    loading: false,
    info: {}
  }
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COUNTRY_INFO_ERROR:
      return { ...state, countries: { ...state.countries, loading: false } };
    case GET_COUNTRY_INFO_REQUEST:
      return { ...state, countries: { ...state.countries, loading: true } };
    case GET_COUNTRY_INFO_SUCCESS:
      return {
        ...state,
        countries: { loading: false, info: action.countries.countries },
        ...(action.countries.states
          ? {
              states: {
                loading: false,
                info: { ...state.states.info, ...action.countries.states }
              }
            }
          : {})
      };
    case GET_STATE_INFO_ERROR:
      return { ...state, states: { ...state.states, loading: false } };
    case GET_STATE_INFO_REQUEST:
      return { ...state, states: { ...state.states, loading: true } };
    case GET_STATE_INFO_SUCCESS:
      return {
        ...state,
        states: {
          loading: false,
          info: { ...state.states.info, ...action.states }
        }
      };
    default:
      return state;
  }
};
export default { initialState, reducer };
