import { h } from "preact";
import config from "../config";

const EditEvent = ({ event }) => {
  return event.preview ? (
    <a
      href={`${config("CONSOLE_URL")}/console/my-events/${event._id}`}
      style={{
        position: "absolute",
        left: 10,
        top: 10,
        background: "rgba(0, 0, 0, 0.3)",
        border: "none",
        padding: "4px 8px",
        fontSize: "14px",
        borderRadius: 4,
        color: "#fff"
      }}
    >
      Edit Event
    </a>
  ) : null;
};
export default EditEvent;
