import loadScript from "load-script";

export const LOAD_CHECKOUT_SCRIPT_REQUEST = "LOAD_CHECKOUT_SCRIPT_REQUEST";
export const LOAD_CHECKOUT_SCRIPT_SUCCESS = "LOAD_CHECKOUT_SCRIPT_SUCCESS";
export const LOAD_CHECKOUT_SCRIPT_ERROR = "LOAD_CHECKOUT_SCRIPT_ERROR";

export const loadCheckOutScript = () => {
  return (dispatch) => {
    dispatch({ type: LOAD_CHECKOUT_SCRIPT_REQUEST });
    loadScript("https://www.paypalobjects.com/api/checkout.js", function (err, script) {
      if (err) {
        dispatch({ type: LOAD_CHECKOUT_SCRIPT_ERROR });
        return;
      }
      dispatch({ type: LOAD_CHECKOUT_SCRIPT_SUCCESS });
    });
  };
};
