import { h } from "preact";
import Logo from "../assets/images/Logo";
import config from "../config";
import { connect } from "react-redux";
import { css } from "emotion";
import Breadcrumbs from "./Breadcrumbs";
import { max, firstOfType, lastOfType } from "../utils/CssUtils";

const desktop = css({
  maxWidth: 1200,
  margin: "0 auto",
  padding: "0px 80px",
  [max(900)]: {
    padding: "0px 24px"
  },
  [max(600)]: {
    display: "none"
  }
});

const upperRowStyles = css({
  paddingTop: 40,
  paddingBottom: 40,
  display: "flex",
  [max(600)]: {
    padding: 14
  }
});

const bottomRowStyles = css({
  borderTop: "solid 1px #dedede",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: 68
});

const logoStyles = css({
  width: "135px",
  height: "32px",
  [max(600)]: {
    display: "block",
    margin: "14px auto"
  }
});

const underBar = css({
  margin: "0 auto",
  padding: "12px 60px",
  textAlign: "center",
  [max(600)]: {
    padding: 0,
    marginTop: 12
  }
});

const getCompanyName = (location) => {
  switch (location) {
    case "NZ":
      return "Humanitix New Zealand Charitable Trust";
    default:
      return "Humanitix Limited";
  }
};

const Footer = ({ event, tours: { tour = {} } }) => {
  let theme = event && event.data && event.data.theme ? event.data.theme : {};
  let location = event.data ? event.data.location : "AU";
  if (typeof window !== "undefined" && window.location) {
    const pathName = window.location.pathname;
    if (pathName.search("tours/") > 0 && tour) {
      theme = tour.theme;
      location = tour.location;
    }
  }

  const color = theme && theme.footerTextColor ? `${theme.footerTextColor}` : "#fff";
  const background = theme && theme.footerTextColor ? theme.footerColor : "#353337";
  const footerStyles = {
    width: "100%",
    color,
    background,
    position: "relative"
  };

  const footerLogoColor = theme && theme.footerLogoColor ? theme.footerLogoColor : "twoTone";

  const companyName = getCompanyName(location);

  const columnStyle = css({
    marginRight: 85,
    a: {
      color,
      fontSize: 14
    },
    ul: {
      listStyleType: "none",
      margin: 0
    },
    li: {
      margin: 0,
      marginBottom: 12,
      [firstOfType()]: {
        marginBottom: 14
      },
      [lastOfType()]: {
        marginBottom: 0
      }
    }
  });

  const mobile = css({
    display: "none",
    [max(600)]: {
      display: "block",
      backgroundColor: "#fff",
      color: "#323232",
      textAlign: "center",

      padding: "8px 0px 100px 0px",
      div: {
        marginBottom: 12,
        a: {
          color: "#323232",
          fontSize: 14,
          padding: "0px 12px",
          [firstOfType()]: {
            borderRight: `1px solid #323232`
          }
        }
      },
      img: {
        width: 40,
        height: 40
      }
    }
  });

  const termAndPolicyStyle = css({
    minWidth: 200,
    a: {
      color,
      fontSize: 14,
      [firstOfType()]: {
        marginRight: 24
      }
    }
  });

  const registeredCharityStyle = css({
    background: "url(https://static.humanitix.com/ticketing/registered_charity_logo.svg)",
    width: "64px",
    height: "64px",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    marginLeft: "auto",
    alignSelf: "flex-end"
  });

  return (
    <footer style={footerStyles}>
      <div className={desktop}>
        <Breadcrumbs event={event} color={color} />

        <div className={upperRowStyles}>
          <div className={columnStyle}>
            <ul>
              <li>
                <h4>Find events</h4>
              </li>
              <li>
                <a href={`${config("WEBSITE_URL")}/search`}>Search events</a>
              </li>
            </ul>
          </div>

          <div className={columnStyle}>
            <ul>
              <li>
                <h4>Host events</h4>
              </li>
              <li>
                <a href={`${config("WEBSITE_URL")}/features`}>All features</a>
              </li>
              <li>
                <a href={`${config("WEBSITE_URL")}/pricing`}>Pricing</a>
              </li>
              <li>
                <a href={`${config("WEBSITE_URL")}/faqs`}>FAQs</a>
              </li>
            </ul>
          </div>

          <div className={columnStyle}>
            <ul>
              <li>
                <h4>Get in touch</h4>
              </li>
              <li>
                <a href={`${config("WEBSITE_URL")}/contact-us/buyer-support`}>Contact us</a>
              </li>
            </ul>
          </div>

          <span className={registeredCharityStyle} />
        </div>

        <div className={bottomRowStyles}>
          <div className={logoStyles}>
            <a href={config("WEBSITE_URL")}>
              <Logo footerLogoColor={footerLogoColor} width={135} height={32} />
            </a>
          </div>
          <div className={underBar}>
            © {companyName} {new Date().getFullYear()}
          </div>
          <div className={termAndPolicyStyle}>
            <a href={`${config("CONSOLE_URL")}/public/pdfs/humanitix_privacy_policy.pdf`}>Privacy policy</a>
            <a href={`${config("CONSOLE_URL")}/public/pdfs/humanitix_promoter_agreement.pdf`}>Terms of use</a>
          </div>
        </div>
      </div>

      <div className={mobile}>
        <div>
          <a href={`${config("WEBSITE_URL")}/contact-us/buyer-support`}>Contact</a>
        </div>

        <a href={config("WEBSITE_URL")} aria-label="Humanitix website">
          <img src={"https://static.humanitix.com/ticketing/humani_logo_square_black_white.svg"} alt="Humanitix Logo" />
        </a>
      </div>
    </footer>
  );
};

export default connect((state) => ({ event: state.event, tours: state.tours }), {})(Footer);
