import config from "../../config";
import { css } from "emotion";
import Image from "../Image";
import Slider from "../Slider";
import Button from "../Button";
import Share from "../Share";
import { EventLocation } from "@hx/utilities";

const maxDisplayItems = 4;

const EventCard = (props) => {
  const { eventUrl, imageUrl, ticketsPriceStr, item } = props;
  return (
    <div className={styles.card} key={item._id}>
      <a href={eventUrl}>
        <Image className={styles.img} src={imageUrl} alt={`${item.name}`} />
        <div className={styles.description}>
          <p className={styles.dateStyle}>{item.dates[0].display.combined}</p>
          <div className={styles.titleAddressWrapper}>
            <p className={styles.cardTitle}>{item.name}</p>
            <p className={styles.contents}>
              {EventLocation.getShortLocationStringForEventLocation(item.eventLocation)}
            </p>
          </div>
        </div>
      </a>
      <div className={styles.priceContainer}>
        {ticketsPriceStr !== "FREE" ? (
          <div className={styles.priceText}>{ticketsPriceStr}</div>
        ) : (
          <div className={styles.freePriceText}>Free</div>
        )}
        <div className={styles.shareButton}>
          <Share eventLink={eventUrl} />
        </div>
      </div>
    </div>
  );
};

const EventCardList = (props) => {
  const { moreEvents } = props;

  return (
    <div>
      {moreEvents.map((item, index) => {
        if (index < maxDisplayItems) {
          const eventUrl = `${config("TICKETING_URL")}/${item.slug}`;
          const useOldBannerStyle = !!(!item.bannerImage && item.featureImage);

          let imageHandleOld = item.socialImage
            ? item.socialImage.url
            : item.featureImage
            ? item.featureImage.url
            : "VbieTVjAQvq5BTkE9vIi";

          let imageHandleNew = item.bannerImage ? item.bannerImage.url : "VbieTVjAQvq5BTkE9vIi";

          const imageUrl = `https://process.filestackapi.com/resize=h:165,w:330,fit:crop/${
            useOldBannerStyle ? imageHandleOld : imageHandleNew
          }`;
          const ticketsPriceStr = item.settings.ticketPriceRangeDisplay ? item.ticketsPriceStr : "";
          return <EventCard eventUrl={eventUrl} imageUrl={imageUrl} ticketsPriceStr={ticketsPriceStr} item={item} />;
        }
      })}
    </div>
  );
};

const MoreEvents = (props) => {
  const { event, title = "More events from this host" } = props;
  let moreEvents = event && event.moreEvents ? event.moreEvents : [];

  if (moreEvents.length < 1 || !event?.organiser?.slug) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <h2 className={styles.title}>{title}</h2>
        <div className={styles.mobile}>
          <Slider>
            <EventCardList moreEvents={moreEvents} />
          </Slider>
        </div>
        <div className={styles.desktop}>
          <EventCardList moreEvents={moreEvents} />
        </div>
        <div style={{ clear: "both" }} />
        {moreEvents.length >= maxDisplayItems && (
          <div style={{ marginLeft: 10, marginTop: 12, width: 140, height: 40 }}>
            <Button
              type="secondary"
              aria-label="See more button"
              aria-hidden
              tabIndex={-1}
              link={`${config("TICKETING_URL")}/host/${event.organiser.slug}`}
            >
              See more
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default MoreEvents;

const styles = {
  mobile: css({
    display: "none",
    "@media (max-width: 850px)": {
      display: "block"
    }
  }),

  desktop: css({
    display: "block",
    "@media (max-width: 850px)": {
      display: "none"
    }
  }),

  wrapper: css({
    width: "100%",
    padding: "72px 80px",
    textAlign: "left",
    background: "#f9f9fa",
    display: "flex",
    "@media (max-width: 850px)": {
      padding: "40px 0px 40px 12px",
      display: "block"
    }
  }),
  title: css({
    fontWeight: 600,
    marginLeft: "10px",
    marginBottom: "24px !important",
    color: "#3f3e41",
    fontSize: "18px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.42px"
  }),
  container: css({
    maxWidth: "1062px",
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto"
  }),
  card: css({
    position: "relative",
    borderRadius: "4px",
    display: "flex",
    marginBottom: "24px",
    marginRight: "8px",
    marginLeft: "8px",
    float: "left",
    width: "248px",
    textAlign: "left",
    maxWidth: "330px",
    boxShadow: "0 1px 8px 1px rgba(32, 7, 64, 0.13)",
    backgroundColor: "#ffffff",
    height: "332px",
    minHeight: "332px",
    maxHeight: "332px",
    overflow: "hidden",
    "@media (max-width: 850px)": {
      float: "none",
      display: "inline-block"
    },
    ":hover": {
      boxShadow: "0 1px 8px 3px rgba(32, 7, 64, 0.13)"
    }
  }),
  eventButton: css({
    textAlign: "left",
    textDecoration: "none !important",
    color: "#3f3e41",
    width: "100%"
  }),
  description: css({
    width: "224px",
    marginLeft: "12px",
    marginRight: "12px"
  }),
  img: css({
    borderTopRightRadius: "4px",
    borderTopLeftRadius: "4px",
    width: "248px",
    height: "124px"
  }),
  dateStyle: css({
    marginTop: "10px",
    marginBottom: "8px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "224px",
    height: "19px",
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.33px",
    color: "#353337"
  }),
  titleAddressWrapper: css({
    "@media (max-width:850px)": {
      position: "absolute"
    }
  }),

  cardTitle: css({
    marginBottom: "8px",
    fontSize: "18px",
    fontWeight: "600",
    color: "#3f3e41",
    whiteSpace: "normal",
    maxHeight: "76px",
    overflow: "hidden",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.42px"
  }),
  contents: css({
    marginBottom: "8px",
    color: "#3f3e41",
    fontSize: "14px",
    width: "224px",
    height: "19px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    letterSpacing: "-0.33px"
  }),
  priceContainer: css({
    borderBottomLeftRadius: "4px",
    borderBottomRightRadius: "4px",
    height: "30px",
    marginBottom: "12px",
    paddingLeft: "12px",
    paddingRight: "12px",
    position: "absolute",
    bottom: "0",
    width: "100%",
    display: "flex"
  }),
  freePriceText: css({
    backgroundColor: "#f9f9fa",
    color: "#3f3e41",
    fontSize: "14px",
    fontWeight: "600",
    width: "78px",
    borderRadius: "4px",
    alignItems: "center",
    justifyContent: "center",
    height: "30px",
    display: "flex",
    float: "left",
    overflow: "hidden"
  }),
  priceText: css({
    color: "#3f3e41",
    fontSize: "14px",
    width: "180px",
    borderRadius: "4px",
    alignItems: "center",
    height: "30px",
    display: "flex",
    float: "left",
    overflow: "hidden"
  }),
  shareButton: css({
    overflow: "hidden",
    float: "right"
  })
};
