import {
  GET_TOUR_REQUEST,
  GET_TOUR_SUCCESS,
  GET_TOUR_ERROR,
  GET_TOUR_OPTIONS_REQUEST,
  GET_TOUR_OPTIONS_SUCCESS,
  GET_TOUR_EVENTS_REQUEST,
  GET_TOUR_EVENTS_SUCCESS
} from "../actions/tourActions";
import cssVars from "../../utils/css-vars-ponyfill";

const initialState = {
  loading: false,
  tour: null,
  tourEvents: [],
  featuredEvents: [],
  eventLoading: false,
  tourDates: [],
  tourLocations: [],
  totalEventSize: 0,
  error: false
};
const parseTour = (tour) => {
  if (!tour) {
    return null;
  }
  // add theme
  let variables = {};
  const theme = tour.theme;

  if (!theme) {
    return tour;
  }

  if (theme.primaryColor) {
    variables["--primary-color"] = theme.primaryColor;
  }
  if (theme.primaryColorHover) {
    variables["--primary-color-hover"] = theme.primaryColorHover;
  }
  if (theme.primaryColorFade) {
    variables["--primary-color-fade"] = theme.primaryColorFade;
  }
  if (theme.primaryShadowColour) {
    variables["--primary-shadow-color"] = theme.primaryShadowColour;
  }
  if (theme.primaryTextColor) {
    variables["--primary-text-color"] = theme.primaryTextColor;
  }
  if (theme.linkColor || theme.primaryColor) {
    variables["--link-color"] = theme.linkColor ? theme.linkColor : theme.primaryColor;
  }
  if (theme.footerColor) {
    variables["--footer-color"] = theme.footerColor;
  }
  cssVars({ variables });
  return tour;
};

const processEvents = (events = [], featuredEvents = []) => {
  let tourEvents = events.reduce((expanded, event) => {
    event.dates.forEach((date) => {
      if (!date.disabled && !date.deleted) {
        const dateEvent = {
          ...event,
          dates: [date]
        };
        expanded.push(dateEvent);
      }
    });
    return expanded;
  }, []);
  if (tourEvents.length && featuredEvents) {
    tourEvents = tourEvents.filter((event) => {
      const isFeaturedEvent = featuredEvents.find((featuredEvent) => String(event._id) === String(featuredEvent._id));
      if (!isFeaturedEvent) {
        return event;
      }
    });
  }
  tourEvents = tourEvents.sort((a, b) => {
    a = new Date(a.dates[0].startDate);
    b = new Date(b.dates[0].startDate);
    return a > b ? 1 : a < b ? -1 : 0;
  });
  return tourEvents;
};

const constructInitTour = (tour) => {
  const defaults = {
    settings: {
      showName: true,
      pageLayout: "tiles",
      listViewType: "image",
      showPrice: true,
      namePlacement: "below"
    },
    groups: [],
    theme: {
      primaryColor: "#353337",
      ticketButtonText: "Get Tickets",
      background: {
        type: "color",
        image: {
          url: ""
        },
        color: "#fafafa"
      },
      sponsorBoxBackgroundColor: "#fff",
      titleBackgroundColor: "#fff",
      titleTextColor: "#323232",
      eventCardBackgroundColor: "#ffffff",
      cardAndBarOpacity: 100,
      type: "default"
    },
    bannerImage: {
      url: ""
    },
    events: [],
    featuredEvents: [],
    sponsorsLogos: []
  };
  if (!tour) {
    return null;
  }
  if (!tour.theme) {
    tour.theme = defaults.theme;
  }
  if (!tour.theme.background) {
    tour.theme.background = defaults.theme.background;
  }
  if (!tour.theme.background.image) {
    tour.theme.background.image = defaults.theme.background.image;
  }
  if (!tour.theme.ticketButtonText) {
    tour.theme.ticketButtonText = defaults.theme.ticketButtonText;
  }
  if (!tour.theme.titleBackgroundColor) {
    tour.theme.titleBackgroundColor = defaults.theme.titleBackgroundColor;
  }
  if (!tour.theme.titleTextColor) {
    tour.theme.titleTextColor = defaults.theme.titleTextColor;
  }
  if (!tour.bannerImage) {
    tour.bannerImage = defaults.bannerImage;
  }
  if (!tour.settings) {
    tour.settings = defaults.settings;
  }
  if (!tour.theme.eventCardBackgroundColor) {
    tour.theme.eventCardBackgroundColor = defaults.theme.eventCardBackgroundColor;
  }
  if (!tour.theme.cardAndBarOpacity) {
    tour.theme.cardAndBarOpacity = defaults.theme.cardAndBarOpacity;
  }
  if (!tour.theme.cardTitleColor) {
    tour.theme.cardTitleColor = tour.theme.type === "dark" || tour.theme.type === "funkyPlum" ? "#ffffff" : "#323232";
  }
  if (!tour.theme.cardTextColor) {
    tour.theme.cardTextColor = tour.theme.type === "dark" || tour.theme.type === "funkyPlum" ? "#ffffff" : "#323232";
  }
  if (tour.events) {
    tour.events = processEvents(tour.events, tour.featuredEvents);
  }
  return tour;
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TOUR_REQUEST:
      return {
        ...state,
        tour: null,
        tourEvents: [],
        featuredEvents: [],
        totalEventSize: 0,
        loading: true,
        error: false
      };
    case GET_TOUR_SUCCESS: {
      const tourEvents = processEvents(action.tourEvents, action.featuredEvents);
      return {
        ...state,
        loading: false,
        tour: parseTour(constructInitTour(action.tour, state.tour)),
        tourEvents,
        featuredEvents: action.featuredEvents,
        totalEventSize: action.totalEventSize
      };
    }
    case GET_TOUR_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case GET_TOUR_OPTIONS_REQUEST:
      return {
        ...state,
        tourLocations: [],
        tourDates: []
      };
    case GET_TOUR_OPTIONS_SUCCESS:
      return {
        ...state,
        tourLocations: action.tourLocations,
        tourDates: action.tourDates
      };
    case GET_TOUR_EVENTS_REQUEST:
      return {
        ...state,
        eventLoading: true,
        tourEvents: [],
        featuredEvents: [],
        totalEventSize: 0
      };
    case GET_TOUR_EVENTS_SUCCESS: {
      // expand tour dates
      const tourEventsProcess = processEvents(action.tourEvents, action.featuredEvents);
      return {
        ...state,
        eventLoading: false,
        tourEvents: tourEventsProcess,
        featuredEvents: action.featuredEvents,
        totalEventSize: action.totalEventSize
      };
    }
    default:
      return state;
  }
};
export default { initialState, reducer, constructInitTour, parseTour };
