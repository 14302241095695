Array.prototype.find =
  Array.prototype.find ||
  function (callback) {
    if (this === null) {
      throw new TypeError("Array.prototype.find called on null or undefined");
    } else if (typeof callback !== "function") {
      throw new TypeError("callback must be a function");
    }
    let list = Object(this);
    // Makes sures is always has an positive integer as length.
    let length = list.length >>> 0;
    // eslint-disable-next-line prefer-rest-params
    let thisArg = arguments[1];
    for (let i = 0; i < length; i++) {
      let element = list[i];
      if (callback.call(thisArg, element, i, list)) {
        return element;
      }
    }
  };

if (!Array.prototype.includes) {
  Object.defineProperty(Array.prototype, "includes", {
    enumerable: false,
    value(obj) {
      let newArr = this.filter(function (el) {
        return el === obj;
      });
      return newArr.length > 0;
    }
  });
}

if (!String.prototype.includes) {
  String.prototype.includes = function (search, start) {
    if (typeof start !== "number") {
      start = 0;
    }

    if (start + search.length > this.length) {
      return false;
    }
    return this.indexOf(search, start) !== -1;
  };
}
