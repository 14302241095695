import { LOAD_BRAINTREE_ERROR, LOAD_BRAINTREE_REQUEST, LOAD_BRAINTREE_SUCCESS } from "../actions/braintreeActions";

const initialState = {
  loading: false,
  error: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_BRAINTREE_REQUEST:
      return {
        ...state,
        loading: true,
        error: false
      };
    case LOAD_BRAINTREE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false
      };
    case LOAD_BRAINTREE_ERROR:
      return {
        ...state,
        loading: false,
        error: true
      };
    default:
      return state;
  }
};
export default {
  initialState,
  reducer
};
