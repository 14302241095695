import { h, Component } from "preact";
import { connect } from "react-redux";
import queryString from "query-string";
import PoweredByHumanitix from "./PoweredByHumanitix";
import Header from "./Header";

class Page extends Component {
  componentDidMount() {
    const isBrowser = typeof window !== "undefined";
    this.isWidget = false;
    if (isBrowser && window.self !== window.top) {
      this.isWidget = true;
    }
  }
  getBackground(event, theme, background) {
    const backgroundType = theme && theme.background && theme.background.type;

    const image = theme && theme.background && theme.background.image ? theme.background.image : false;

    if (backgroundType === "image" && image) {
      return `url(${image.url}) no-repeat center #eee`;
    }

    if (backgroundType === "pattern" && image) {
      return `url(${image.url})`;
    }

    if (backgroundType === "color") {
      return `${theme.background.color}`;
    }

    if (background && !event.widget) {
      return "#f9f9fa";
    }

    return "#fff";
  }

  render({
    header = false,
    background = true,
    border = true,
    transparent = false,
    children,
    event,
    banner,
    maxWidth = 1040
  }) {
    const isBrowser = typeof window !== "undefined";
    let parsedTheme;
    if (isBrowser) {
      const parsed = queryString.parse(window.location.search);
      parsedTheme = parsed.theme && parsed.theme !== "undefined" ? JSON.parse(parsed.theme) : false;
    }
    let isWidget = false;
    if (isBrowser && window.self !== window.top) {
      isWidget = true;
    }
    const theme = parsedTheme ? parsedTheme : event && event.data ? event.data.theme : false;
    let wrapperStyle = {
      background: this.getBackground(event, theme, background),
      paddingBottom: 12
    };
    if (theme && theme.background && theme.background.type === "pattern") {
      wrapperStyle.backgroundSize = "initial";
    } else {
      wrapperStyle.backgroundRepeat = "no-repeat";
      wrapperStyle.backgroundAttachment = "fixed";
      wrapperStyle.backgroundSize = "cover";
    }

    if (isWidget) {
      wrapperStyle.paddingTop = 1;
    }
    const pageStyle = {
      position: "relative",
      maxWidth,
      margin: "0px auto",
      background: "#fff",
      boxShadow: border ? "0 1px 3px 0 rgba(9,30,66,0.13), 0 0 1px 0 #F1F1F1" : "none",
      borderTop: "none"
    };
    const showHeader = header && !theme.hideHeader;

    pageStyle.background = transparent ? "transparent" : "#fff";
    return (
      <div style={wrapperStyle}>
        {showHeader ? <Header /> : null}
        {banner ? banner : null}
        <div class="page" style={pageStyle}>
          {children}
          {this.isWidget ? <PoweredByHumanitix /> : null}
        </div>
      </div>
    );
  }
}

const emulateEvent = ({ giftCardPage }) => {
  const { style } = giftCardPage;
  return {
    data: {
      theme: {
        background: {
          type: "color",
          color: style.backgroundColor
        }
      }
    }
  };
};

export default connect((state, ownProps) => {
  const { isGlobal } = ownProps;
  return {
    event: isGlobal ? emulateEvent(state.giftCards) : state.event
  };
}, {})(Page);
