import { h } from "preact";
import config from "../config";
import { css } from "emotion";

const facebookIcon = "https://static.humanitix.com/ticketing/ic_facebook_gray.svg";
const linkedInIcon = "https://static.humanitix.com/ticketing/ic_linkedin.svg";
const twitterIcon = "https://static.humanitix.com/ticketing/ic_twitter_gray.svg";

import { CopyToClipboard } from "react-copy-to-clipboard";
import Image from "./Image";

const copyButton = css({
  width: 32,
  height: 32,
  padding: 0,
  backgroundImage: "url(https://static.humanitix.com/ticketing/ic_copy_link.svg)",
  backgroundSize: "32px 32px",
  margin: "0px 4px",
  display: "inline-flex",
  "@media(max-width: 600px)": {
    margin: "0px 2px 0px 2px"
  }
});

const Link = ({ href, children, label }) => {
  const style = css({
    display: "inline-block",
    height: 32,
    width: 32,
    padding: 0,
    margin: "0px 4px 0px 4px",
    "@media(max-width: 600px)": {
      margin: "0px 2px 0px 2px"
    }
  });
  const styleIcon = {
    width: 32,
    height: 32
  };

  return (
    <a className={style} href={href} target="_blank" aria-label={label} rel="noopener noreferrer">
      <div style={styleIcon}>{children}</div>
    </a>
  );
};

const SharingLinks = ({ event }) => {
  if (!event.public) {
    return <div style={{ height: 40 }} />;
  }
  const style = css(`
    margin: 36px 0;
    position: relative;
    @media(max-width: 760px){
        margin: 24px 0 0 0;
    }
  `);
  const label = {
    display: "inline",
    padding: 0,
    lineHeight: 0,
    position: "absolute",
    top: "17px",
    left: 0,
    fontWeight: 600
  };
  const origin = config("TICKETING_URL");
  const sharingUrl = `${origin}/${event.slug}`;
  let sharingMsg = `${event.name}`;
  sharingMsg = encodeURI(sharingMsg.substr(0, 255));
  return (
    <div className={style}>
      <p style={label}>Share event</p>
      <div style={{ marginLeft: 101 }}>
        <Link label="Share on facebook" href={`https://facebook.com/sharer/sharer.php?u=${sharingUrl}`}>
          <Image src={facebookIcon} alt="facebook" style={{ width: 32 }} />
        </Link>
        <Link label="Share on Twitter" href={`https://twitter.com/intent/tweet/?text=${sharingMsg}&url=${sharingUrl}`}>
          <Image src={twitterIcon} alt="twitter" style={{ width: 32 }} />
        </Link>
        <Link label="Share on Linked In" href={`https://www.linkedin.com/shareArticle?mini=true&url=${sharingUrl}`}>
          <Image src={linkedInIcon} alt="linkedIn" style={{ width: 32 }} />
        </Link>
        <CopyToClipboard text={sharingUrl}>
          <button aria-label="Copy Button Link" aria-hidden className={copyButton} />
        </CopyToClipboard>
      </div>
    </div>
  );
};
export default SharingLinks;
