export const checkObjNotEmpty = (obj) => {
  for (let property in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, property)) {
      if (typeof obj[property] === "object") {
        return checkObjNotEmpty(obj[property]);
      }
      if (obj[property] !== "" && obj[property] !== false && property !== "_id") {
        return true;
      }
    }
  }
  return false;
};

export const getValueFromPath = (path, obj) => {
  try {
    return path.split(".").reduce((o, i) => o[i], obj);
  } catch (err) {
    return null;
  }
};
