import {
  CHECK_ATTENDEE_PROFILE_ERROR,
  CHECK_ATTENDEE_PROFILE_REQUEST,
  CHECK_ATTENDEE_PROFILE_SUCCESS,
  CREATE_ATTENDEE_PROFILE_ERROR,
  CREATE_ATTENDEE_PROFILE_REQUEST,
  CREATE_ATTENDEE_PROFILE_SUCCESS,
  ASSIGN_ATTENDEE_PROFILE_ERROR,
  ASSIGN_ATTENDEE_PROFILE_REQUEST,
  ASSIGN_ATTENDEE_PROFILE_SUCCESS,
  UPDATE_PROFILE_ERROR,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  ADD_EXISTING_ATTENDEE,
  OPEN_ATTENDEE_MODAL,
  CLOSE_ATTENDEE_MODAL,
  CLEAR_EXISTING_ATTENDEE,
  GET_ASSIGNED_PROFILES_REQUEST,
  GET_ASSIGNED_PROFILES_ERROR,
  GET_ASSIGNED_PROFILES_SUCCESS,
  UNASSIGN_TICKET_ERROR,
  UNASSIGN_TICKET_REQUEST,
  UNASSIGN_TICKET_SUCCESS,
  UPDATE_ASSIGNED_ADDITIONAL_QUESTIONS_ERROR,
  UPDATE_ASSIGNED_ADDITIONAL_QUESTIONS_REQUEST,
  UPDATE_ASSIGNED_ADDITIONAL_QUESTIONS_SUCCESS,
  OPEN_ADDITIONAL_QUESTIONS,
  CLOSE_ADDITIONAL_QUESTIONS,
  SET_TOKEN,
  GET_ATTENDEE_PROFILE_ERROR,
  GET_ATTENDEE_PROFILE_REQUEST,
  GET_ATTENDEE_PROFILE_SUCCESS,
  UPDATE_ADDITIONAL_QUESTIONS_REQUEST,
  UPDATE_ADDITIONAL_QUESTIONS_ERROR,
  UPDATE_ADDITIONAL_QUESTIONS_SUCCESS,
  CHECK_ORDER_COMPLETE_REQUEST,
  CHECK_ORDER_COMPLETE_SUCCESS,
  CHECK_ORDER_COMPLETE_ERROR
} from "../actions/attendeeProfileActions";

const profileCheckDefault = {
  profile: false,
  complete: false,
  ticketId: false
};

const attendeeModalDefault = {
  show: false,
  defaultValues: {},
  ticketId: false,
  isNew: true
};

const assigningDefault = {
  loading: false,
  error: false,
  ticketId: false
};

const additionalQuestionDefault = {
  loading: false,
  open: true,
  error: false
};

const initialState = {
  loading: false,
  error: false,
  sent: false,
  token: false,
  profile: false,
  profiles: [],
  profileLoading: false,
  profileCheck: { ...profileCheckDefault },
  attendeeModal: { ...attendeeModalDefault },
  assigning: { ...assigningDefault },
  additionalQuestionDefault: { default: { ...additionalQuestionDefault } }
};

const getTicketState = (state, ticketId) => {
  let ticketState = state.additionalQuestionDefault[ticketId];
  if (!ticketState) {
    ticketState = { ...additionalQuestionDefault };
  }
  return ticketState;
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CHECK_ORDER_COMPLETE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: false
      };
    }
    case CHECK_ORDER_COMPLETE_SUCCESS: {
      return {
        ...state,
        loading: false
      };
    }
    case CHECK_ORDER_COMPLETE_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error
      };
    }
    case SET_TOKEN: {
      return {
        ...state,
        token: action.token
      };
    }

    case UPDATE_ADDITIONAL_QUESTIONS_REQUEST: {
      return {
        ...state,
        loading: true,
        error: false
      };
    }
    case UPDATE_ADDITIONAL_QUESTIONS_SUCCESS: {
      return {
        ...state,
        loading: false
      };
    }
    case UPDATE_ADDITIONAL_QUESTIONS_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error
      };
    }
    case GET_ATTENDEE_PROFILE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: false
      };
    }

    case GET_ATTENDEE_PROFILE_SUCCESS: {
      return {
        ...state,
        loading: false,
        profile: action.profile
      };
    }

    case GET_ATTENDEE_PROFILE_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error
      };
    }

    case OPEN_ADDITIONAL_QUESTIONS: {
      const ticketState = getTicketState(state, action.ticketId);
      return {
        ...state,
        additionalQuestionDefault: {
          ...state.additionalQuestionDefault,
          [action.ticketId]: {
            ...ticketState,
            open: true
          }
        }
      };
    }

    case CLOSE_ADDITIONAL_QUESTIONS: {
      const ticketState = getTicketState(state, action.ticketId);
      return {
        ...state,
        additionalQuestionDefault: {
          ...state.additionalQuestionDefault,
          [action.ticketId]: {
            ...ticketState,
            open: false
          }
        }
      };
    }

    case UPDATE_ASSIGNED_ADDITIONAL_QUESTIONS_REQUEST: {
      return {
        ...state,
        profileLoading: true,
        error: false
      };
    }
    case UPDATE_ASSIGNED_ADDITIONAL_QUESTIONS_SUCCESS: {
      return {
        ...state,
        profileLoading: false
      };
    }
    case UPDATE_ASSIGNED_ADDITIONAL_QUESTIONS_ERROR: {
      return {
        ...state,
        profileLoading: false,
        error: action.error
      };
    }

    case UNASSIGN_TICKET_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error
      };
    }
    case UNASSIGN_TICKET_REQUEST: {
      return {
        ...state,
        loading: true,
        error: false
      };
    }
    case UNASSIGN_TICKET_SUCCESS: {
      return {
        ...state,
        loading: false,
        additionalQuestionDefault: {
          ...state.additionalQuestionDefault,
          [action.ticketId]: {
            open: false
          }
        }
      };
    }
    case UPDATE_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
        error: false
      };
    case UPDATE_PROFILE_SUCCESS: {
      const profiles = action.profiles ? action.profiles : state.profiles;
      return {
        ...state,
        loading: false,
        profiles
      };
    }
    case UPDATE_PROFILE_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false
      };
    case CHECK_ATTENDEE_PROFILE_ERROR:
      return {
        ...state,
        error: action.error,
        profileCheck: { ...profileCheckDefault }
      };
    case CHECK_ATTENDEE_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
        profileCheck: { ...profileCheckDefault }
      };
    case CHECK_ATTENDEE_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        profileCheck: {
          profile: action.profile,
          complete: true,
          ticketId: action.ticketId
        }
      };
    case ADD_EXISTING_ATTENDEE:
      return {
        ...state,
        profiles: state.profiles.concat([action.profile]),
        profileCheck: { ...profileCheckDefault }
      };
    case CREATE_ATTENDEE_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
        error: false
      };
    case CREATE_ATTENDEE_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        profiles: state.profiles.concat(action.profile)
      };
    case CREATE_ATTENDEE_PROFILE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case ASSIGN_ATTENDEE_PROFILE_REQUEST:
      return {
        ...state,
        assigning: {
          loading: true,
          error: false,
          ticketId: action.ticketId
        }
      };
    case ASSIGN_ATTENDEE_PROFILE_ERROR:
      return {
        ...state,
        assigning: {
          loading: false,
          error: action.error
        }
      };
    case ASSIGN_ATTENDEE_PROFILE_SUCCESS:
      return {
        ...state,
        assigning: {
          ...assigningDefault
        },
        additionalQuestionDefault: {
          ...state.additionalQuestionDefault,
          [action.ticketId]: {
            ...additionalQuestionDefault
          }
        }
      };
    case OPEN_ATTENDEE_MODAL:
      return {
        ...state,
        attendeeModal: {
          show: true,
          defaultValues: action.defaultValues,
          ticketId: action.ticketId,
          isNew: action.isNew
        }
      };
    case CLOSE_ATTENDEE_MODAL:
      return {
        ...state,
        attendeeModal: {
          ...attendeeModalDefault
        }
      };
    case CLEAR_EXISTING_ATTENDEE: {
      return {
        ...state,
        profileCheck: { ...profileCheckDefault }
      };
    }
    case GET_ASSIGNED_PROFILES_REQUEST: {
      return {
        ...state,
        loading: true,
        error: false
      };
    }
    case GET_ASSIGNED_PROFILES_SUCCESS: {
      return {
        ...state,
        loading: false,
        profiles: action.profiles
      };
    }
    case GET_ASSIGNED_PROFILES_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error
      };
    }
    default:
      return state;
  }
};
export default { initialState, reducer };
