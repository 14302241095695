import ContactService from "../../services/ContactService";

export const CONTACT_RESET = "CONTACT_RESET";
export const CONTACT_REQUEST = "CONTACT_REQUEST";
export const CONTACT_SUCCESS = "CONTACT_SUCCESS";
export const CONTACT_ERROR = "CONTACT_ERROR";

export const reset = () => {
  return (dispatch) => {
    dispatch({ type: CONTACT_RESET });
  };
};

export const sendMessage = (eventId, values) => {
  return (dispatch) => {
    dispatch({ type: CONTACT_REQUEST });
    ContactService.sendMessage(eventId, values)
      .then((result) => {
        dispatch({ type: CONTACT_SUCCESS, message: result.message });
      })
      .catch((err) => {
        dispatch({ type: CONTACT_ERROR, error: err });
      });
  };
};
