const routeConstructor = (slug, orderId, isGlobal) => {
  return {
    general: {
      getTickets: `/${slug}/tickets`,
      merchandise: `/${slug}/add-ons/${orderId}`,
      details: `/${slug}/details/${orderId}`,
      seatingMap: `/${slug}/seatingmap/${orderId}`,
      assignedTickets: `/${slug}/assign/${orderId}`,
      payment: `/${slug}/payment/${orderId}`,
      afterCheckout: `/${slug}/afterCheckout/${orderId}`,
      complete: `/${slug}/complete/${orderId}`
    },
    giftCard: {
      getTickets: `/gift-cards/${slug}`,
      details: `/gift-cards/${slug}/details/${orderId}`,
      payment: `/gift-cards/${slug}/payment/${orderId}`,
      complete: `/gift-cards/${slug}/complete/${orderId}`
    }
  };
};

export default routeConstructor;
