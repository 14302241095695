export const LOAD_BRAINTREE_REQUEST = "LOAD_BRAINTREE_REQUEST";
export const LOAD_BRAINTREE_SUCCESS = "LOAD_BRAINTREE_SUCCESS";
export const LOAD_BRAINTREE_ERROR = "LOAD_BRAINTREE_ERROR";

export const BRAINTREE_INIT = "BRAINTREE_INIT";
export const BRAINTREE_INIT_REQUEST = "BRAINTREE_INIT_REQUEST";
export const UPDATED_FIELDS = "UPDATED_FIELDS";

import { initialiseGateway } from "@hx/payment-gateways-frontend";
import { payClicked, payV2, CHECKOUT_ERROR } from "./orderActions";

let braintree;

export const initBraintree = (braintreeGateway) => {
  return async (dispatch, getState) => {
    const { order } = getState();
    const { paymentSwitch, data: orderData } = order;
    const { currency } = orderData;
    const { payableAmount: amount, paymentGatewayData } = paymentSwitch.data;
    try {
      dispatch({ type: LOAD_BRAINTREE_REQUEST });
      braintree = await initialiseGateway({
        gateway: braintreeGateway,
        ...paymentGatewayData,
        fullName: `${order.data.firstName || ""} ${order.data.lastName || ""}`.trim(),
        onPaid: (paymentData) => {
          dispatch(payV2(order.data._id, paymentData, order.data.eventId));
        },
        onError: (err) => {
          dispatch({ type: LOAD_BRAINTREE_ERROR });
          dispatch({ type: CHECKOUT_ERROR, error: err.message || "Something went wrong, please try again." });
        },
        onCanceled: () => {
          dispatch({ type: LOAD_BRAINTREE_ERROR });
          dispatch({ type: CHECKOUT_ERROR, error: "Payment cancelled." });
        },
        currency,
        amount,
        location: order.data.location
      });
      dispatch({ type: LOAD_BRAINTREE_SUCCESS });
    } catch (err) {
      dispatch({ type: LOAD_BRAINTREE_ERROR });
      dispatch({ type: CHECKOUT_ERROR, error: err.message || "Something went wrong, please try again." });
    }
  };
};

export const payBraintree = (recaptcha) => {
  return async (dispatch, getState) => {
    try {
      const { order } = getState();
      const { data: orderData, paymentSwitch } = order;
      const { email, mobile, currency } = orderData;
      const amount = paymentSwitch.data.payableAmount;
      dispatch(payClicked());
      const paymentData = await braintree.getPaymentData({ email, mobile, amount, currency });
      if (paymentData.status === "failure") {
        dispatch({ type: CHECKOUT_ERROR, error: paymentData.errorMessage });
      } else {
        dispatch(payV2(order.data._id, { ...paymentData, recaptcha }, order.data.eventId));
      }
    } catch (err) {
      dispatch({ type: CHECKOUT_ERROR, error: err.message || "Something went wrong, please try again." });
    }
  };
};
