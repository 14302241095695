/* eslint-disable no-useless-escape */

export const required = (value) => {
  const requiredMsg = `This field is required`;
  value = typeof value === "string" || value instanceof String ? value.trim() : value;
  if (typeof value === "object" && value !== null) {
    if (value.location || value.details) {
      const details = value.details || {};
      return details.country && details.postalCode && details.state && details.street && details.suburb
        ? undefined
        : requiredMsg;
    }
  }
  return value || value === 0 ? undefined : requiredMsg;
};

export const email = (value) => {
  value = typeof value === "string" || value instanceof String ? value.trim() : value;
  return value &&
    !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(
      value
    )
    ? "Invalid email address"
    : undefined;
};

export const mobile = (value) => {
  value = typeof value === "string" || value instanceof String ? value.trim() : value;
  if (!value || !value.length) {
    return undefined;
  }
  if (value.length < 4) {
    return "Invalid mobile number";
  }
  const validMobile = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/im.test(value);
  if (validMobile) {
    return undefined;
  }
  return "Invalid mobile number";
};

export const match = (value, allValues) => {
  return value === allValues.order.email ? undefined : `Emails must match`;
};

export const checkLength = (msg, len, symbol) => (value) => {
  switch (symbol) {
    case "==":
      return value && value.length === len ? undefined : msg;
    case ">=":
      return value && value.length >= len ? undefined : msg;
    case "<=":
      return value && value.length <= len ? undefined : msg;
    case ">":
      return value && value.length > len ? undefined : msg;
    case "<":
      return value && value.length < len ? undefined : msg;
    case "!=":
      return value && value.length !== len ? undefined : msg;
    default:
      return undefined;
  }
};

export const checkUniqueness =
  (questionsToCheckAgainst, ticketId, uniqueAdditionalQuestionAnswerFields) => (value, allValues) => {
    if (!value || !allValues) return undefined;
    const currentTicketsAnswers = allValues.tickets[ticketId];
    //firstName and lastName are sep fields but we dedupe off of full name
    //this joins them
    const currentTicketsAnswer = Object.keys(currentTicketsAnswers)
      .reduce((answerArray, key) => {
        if (questionsToCheckAgainst.includes(key)) answerArray.push(currentTicketsAnswers[key].trim());
        return answerArray;
      }, [])
      .join("")
      .trim()
      .toLowerCase();
    const listOfCurrentAnswers = Object.keys(allValues.tickets).reduce((listOfCurrentAnswers, key) => {
      const ticketAnswerForAllQuestions = allValues.tickets[key];
      const answersToCheck = Object.keys(ticketAnswerForAllQuestions).reduce((answers, ticketAnswerKey) => {
        if (questionsToCheckAgainst.includes(ticketAnswerKey))
          answers.push(ticketAnswerForAllQuestions[ticketAnswerKey].trim().toLowerCase());
        return answers;
      }, []);
      listOfCurrentAnswers.push(answersToCheck.join("").trim().toLowerCase());
      return listOfCurrentAnswers;
    }, []);
    //we have a list of answers, one instance of an answer is expected, so we remove it
    //ie mobile question, ["1234567890"] is passing so we remove it but  ["1234567890", "1234567890"]
    //would mean duplicate, after the splice we end up with [] and ["1234567890"] respectively
    listOfCurrentAnswers.splice(listOfCurrentAnswers.indexOf(currentTicketsAnswer), 1);
    if (listOfCurrentAnswers.includes(currentTicketsAnswer))
      return getUniquenessErrorMessage(uniqueAdditionalQuestionAnswerFields);
    return undefined;
  };

const getUniquenessErrorMessage = (uniqueAdditionalQuestionAnswerFields) => {
  const genericError = "Ticket holders must be unique by: ";
  const uniqueComponents = [];
  if (uniqueAdditionalQuestionAnswerFields.fullName) uniqueComponents.push("full name");
  if (uniqueAdditionalQuestionAnswerFields.email) uniqueComponents.push("email");
  if (uniqueAdditionalQuestionAnswerFields.mobile) uniqueComponents.push("mobile");
  return genericError + uniqueComponents.join(", ");
};

export const getQuestionIdsToCheckAgainst = (question, questions) => {
  if (!questions || !question) return [];
  if (question.type !== "firstName" && question.type !== "lastName") {
    return [question._id];
  }
  return questions.reduce((questionIds, question) => {
    if (question.type === "firstName" || question.type === "lastName") {
      questionIds.push(question._id);
    }
    return questionIds;
  }, []);
};

export const needsUniquenessValidator = (question, uniqueAdditionalQuestionAnswerFields) => {
  if (!uniqueAdditionalQuestionAnswerFields || !question) return false;
  const enabledUniqueFields = Object.keys(uniqueAdditionalQuestionAnswerFields).reduce((enabledUniqueFields, key) => {
    if (["enabled", "_id"].includes(key)) return enabledUniqueFields;
    if (uniqueAdditionalQuestionAnswerFields[key]) enabledUniqueFields.push(key);
    return enabledUniqueFields;
  }, []);
  const questionTypesToHaveValidator = enabledUniqueFields.reduce((questionTypesToHaveValidator, questionType) => {
    if (questionType === "fullName") {
      return questionTypesToHaveValidator.concat(["firstName", "lastName"]);
    }
    questionTypesToHaveValidator.push(questionType);
    return questionTypesToHaveValidator;
  }, []);
  return questionTypesToHaveValidator.includes(question.type);
};
