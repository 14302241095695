import routeConstructor from "./routeConstructor";
import TrackingService from "../services/tracking/TrackingService";
export const GET_TICKETS = "getTickets";
export const MERCHANDISE = "merchandise";
export const DETAILS = "details";
export const ASSIGNED_TICKETS = "assignedTickets";
export const SEATING_MAP = "seatingMap";
export const PAYMENT = "payment";
export const AFTER_CHECKOUT = "afterCheckout";
export const COMPLETE = "complete";

const routes = [GET_TICKETS, MERCHANDISE, DETAILS, ASSIGNED_TICKETS, SEATING_MAP, PAYMENT, AFTER_CHECKOUT, COMPLETE];

const giftCardRoutes = [GET_TICKETS, DETAILS, PAYMENT, COMPLETE];

export const getGiftCardRoutes = (giftCardData, orderData) => {
  const routeLinks = routeConstructor(giftCardData.slug, orderData ? orderData._id : false).giftCard;
  const activeRoutes = determineActiveRoutes(orderData, false);
  const shouldShowOnBreadcrumb = shouldShowBreadCrumbStep();
  const buttonLabels = getButtonLabels(false, false, true, false);
  const routeLabels = getRouteLabels(false, false);
  return giftCardRoutes.map((route) => {
    return {
      routeId: route,
      ...routeLabels[route],
      path: routeLinks[route],
      active: activeRoutes[route],
      required: true,
      showOnBreadcrumb: shouldShowOnBreadcrumb[route],
      buttonLabel: buttonLabels[route]
    };
  });
};

export const getApplicableRoutes = (eventData, orderData, merchData) => {
  const routeLabels = getRouteLabels(eventData, orderData);
  const requiredRoutes = determineRequiredRoutes(eventData, orderData, merchData);
  const routeLinks = routeConstructor(eventData ? eventData.slug : false, orderData ? orderData._id : false).general;
  const activeRoutes = determineActiveRoutes(orderData, requiredRoutes[SEATING_MAP]);
  const routeTransitionCalls = getRouteTransitionCalls(requiredRoutes[AFTER_CHECKOUT]);
  const shouldShowOnBreadcrumb = shouldShowBreadCrumbStep();
  const buttonLabels = getButtonLabels(
    requiredRoutes[ASSIGNED_TICKETS],
    requiredRoutes[SEATING_MAP],
    requiredRoutes[PAYMENT],
    isDonationOnlyEvent(eventData)
  );
  return routes
    .map((route) => {
      return {
        routeId: route,
        ...routeLabels[route],
        path: routeLinks[route],
        active: activeRoutes[route],
        required: requiredRoutes[route],
        transitionCall: routeTransitionCalls[route],
        showOnBreadcrumb: shouldShowOnBreadcrumb[route],
        buttonLabel: buttonLabels[route]
      };
    })
    .filter((route) => route.required);
};

const getButtonLabels = (hasAssignedTickets, hasSeatingMap, hasPaymentPage, isDonationOnlyEvent) => {
  const paymentLabel = "Checkout";
  const seatingLabel = "Select Seats";

  let detailsText = "Save & Checkout";
  if (hasAssignedTickets) detailsText = "Assign tickets";
  else if (hasSeatingMap) detailsText = seatingLabel;
  else if (!hasPaymentPage) detailsText = "Register";

  const assignedTickets = hasSeatingMap ? seatingLabel : paymentLabel;

  return {
    getTickets: isDonationOnlyEvent ? "Continue" : "Enter details",
    merchandise: "Enter details",
    details: detailsText,
    seatingMap: paymentLabel,
    assignedTickets,
    payment: "Pay now",
    afterCheckout: "Submit",
    complete: false
  };
};

const getRouteLabels = (eventData, orderData) => {
  const isDonationOnly = isDonationOnlyEvent(eventData);
  const usingAssignedTickets = eventUsingAssignedTickets(eventData, orderData);

  return {
    getTickets: {
      desktopLabel: isDonationOnly ? "Donation" : "Select tickets",
      mobileLabel: isDonationOnly ? "Donation" : "Tickets"
    },
    merchandise: {
      desktopLabel: "Add-ons",
      mobileLabel: "Add-ons"
    },
    details: {
      desktopLabel: usingAssignedTickets ? "Buyer details" : "Enter details",
      mobileLabel: "Details"
    },
    seatingMap: {
      desktopLabel: "Seats",
      mobileLabel: "Seats"
    },
    assignedTickets: {
      desktopLabel: "Assign tickets",
      mobileLabel: "Assign"
    },
    payment: {
      desktopLabel: "Payment",
      mobileLabel: "Payment"
    },
    afterCheckout: {
      desktopLabel: "After checkout",
      mobileLabel: "After checkout"
    },
    complete: {
      desktopLabel: "Complete",
      mobileLabel: "Complete"
    }
  };
};
/* istanbul ignore next */
const shouldShowBreadCrumbStep = () => {
  return {
    getTickets: true,
    merchandise: true,
    details: true,
    seatingMap: true,
    assignedTickets: true,
    payment: true,
    afterCheckout: false,
    complete: false
  };
};

const determineRequiredRoutes = (eventData, orderData, merchData) => {
  return {
    getTickets: true,
    merchandise: merchOffered(eventData, orderData),
    details: true,
    seatingMap: seatingSelectionRequired(eventData, orderData),
    assignedTickets: eventUsingAssignedTickets(eventData, orderData),
    payment: paymentRequired(eventData, orderData, merchData),
    afterCheckout: eventHasAfterCheckoutQuestions(eventData, orderData),
    complete: true
  };
};

/**
 * This gets ignored for now. The action for constructing routes is overriding the logic.
 */
const determineActiveRoutes = (orderData, seatingRequired) => {
  return {
    getTickets: true,
    merchandise: merchandiseActive(orderData),
    details: detailsActive(orderData),
    seatingMap: seatingMapActive(orderData),
    assignedTickets: assignedTicketsMapActive(orderData, seatingRequired),
    payment: paymentActive(orderData, seatingRequired),
    afterCheckout: true,
    complete: true
  };
};
/* istanbul ignore next */
const getRouteTransitionCalls = (afterCheckoutRequired) => {
  return {
    getTickets: (state) => {
      TrackingService.ticketsAdded(state.event.data, state.order.data);
    },
    merchandise: (state) => {},
    details: (state) => {
      TrackingService.enteredDetails(state.event.data, state.order.data);
      if (state.order.data.totals.total === 0) {
        TrackingService.complete(state.event.data, state.order.data);
      }
    },
    seatingMap: (state) => {},
    assignedTickets: (state) => {},
    payment: (state) => {
      TrackingService.complete(state.event.data, state.order.data);
    },
    afterCheckout: (state) => {},
    complete: (state) => {}
  };
};

const merchandiseActive = (orderData) => {
  if (orderData) {
    return true;
  }
  return false;
};

const detailsActive = (orderData) => {
  if (orderData) {
    return true;
  }
  return false;
};

const seatingMapActive = (orderData) => {
  if (orderData && orderData.firstName) {
    return true;
  }
  return false;
};

const assignedTicketsMapActive = (orderData) => {
  if (orderData && orderData.firstName) {
    return true;
  }
  return false;
};

const paymentActive = (orderData, seatingRequired) => {
  if (orderData) {
    const orderDetailsFilled = !!(orderData && orderData.firstName);
    if (!orderDetailsFilled || !seatingRequired) {
      return orderDetailsFilled;
    }
    const tickets = orderData.tickets || [];
    return tickets.reduce((foundAssignment, ticket) => {
      if (foundAssignment) {
        return foundAssignment;
      }
      return (
        true &&
        ticket.seatingLocation &&
        ticket.seatingLocation.section &&
        ticket.seatingLocation.section.id !== "unassigned"
      );
    }, false);
  }
  return false;
};

const seatingSelectionRequired = (eventData, orderData) => {
  let seatingMapId;
  if (orderData) {
    const { tickets } = orderData;
    const event = eventData;
    if (event && event.seatingMaps && tickets) {
      event.seatingMaps.forEach((seatingMap) => {
        const isPublic = seatingMap.public !== false;
        seatingMap.ticketTypeSet.forEach((ticketType) => {
          const containsPackageTicket =
            tickets.filter((ticket) => String(ticket.packageId) === String(ticketType)).length > 0;
          const containsTicketType =
            tickets.filter((ticket) => String(ticket.ticketTypeId) === String(ticketType)).length > 0;
          if ((containsPackageTicket || containsTicketType) && !seatingMapId && isPublic) {
            seatingMapId = seatingMap.id;
          }
        });
      });
    }
  }
  return !!seatingMapId;
};

const merchOffered = (eventData, orderData) => {
  if (!orderData || !eventData) {
    return false;
  }
  if (eventData.isFreeOnly) {
    return false;
  }
  const includesMerchOffer = orderData.orderIncludesMerchOffer;

  return !!includesMerchOffer;
};

const eventUsingAssignedTickets = (eventData, orderData) => {
  return !!(
    eventData &&
    eventData.settings &&
    eventData.settings.enableTicketAssigning &&
    orderData &&
    orderData.tickets &&
    orderData.tickets.length > 0
  );
};

const isDonationOnlyEvent = (eventData) => {
  const hasDonation = eventData && eventData.hasDonation;
  if (!hasDonation) return false;

  return !!(
    eventData &&
    eventData.ticketTypes &&
    !eventData.ticketTypes.some((ticketType) => {
      return !ticketType.isDonation && !ticketType.disabled && !ticketType.deleted && ticketType.onSale;
    })
  );
};

const paymentRequired = (eventData, orderData, merchData) => {
  const eventIsFree = !!eventData && eventData.isFree && !eventData.hasDonation;
  const ticketsAreNotFree = !!orderData && !!orderData.tickets && orderData.tickets.some((ticket) => ticket.total > 0);
  const orderHasPaidMerchandiseAdded = !!merchData && merchData.some((merch) => merch.total > 0);

  if (eventIsFree && !orderHasPaidMerchandiseAdded && !ticketsAreNotFree) {
    return false;
  }
  if (!orderData) return true;

  //payment ain't required if the order is manual
  if (orderData.manualOrder) return false;

  const orderHasTotals = orderData.totals && orderData.totals.total > 0;
  const orderHasDonation = !!orderData.clientDonation;

  return !!(orderHasTotals || orderHasDonation || ticketsAreNotFree || orderHasPaidMerchandiseAdded);
};

const eventHasAfterCheckoutQuestions = (eventData, orderData) => {
  if (!eventData || !orderData || !orderData.tickets) {
    return false;
  }
  const additionalQuestions = eventData.additionalQuestions ? eventData.additionalQuestions : [];
  const afterCheckoutQuestions = additionalQuestions.filter((q) => !q.disabled && q.askAfterCheckout);
  return orderData.tickets.reduce((found, ticket) => {
    afterCheckoutQuestions.forEach((q) => {
      if (!q.appliesTo || !q.appliesTo.length || q.appliesTo.indexOf(ticket.ticketTypeId) !== -1) {
        found = true;
      }
    });
    return found;
  }, false);
};

export const privateMethods = {
  getButtonLabels,
  getRouteLabels,
  determineRequiredRoutes,
  determineActiveRoutes,
  getRouteTransitionCalls,
  merchandiseActive,
  detailsActive,
  seatingMapActive,
  assignedTicketsMapActive,
  paymentActive,
  seatingSelectionRequired,
  merchOffered,
  eventUsingAssignedTickets,
  isDonationOnlyEvent,
  paymentRequired,
  eventHasAfterCheckoutQuestions
};
