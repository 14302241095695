import { css } from "emotion";
import { h } from "preact";
import AddToCalendar from "../../components/addToCalender/AddToCalendar";

const btnClass = css({
  fontSize: 14,
  fontWeight: 600,
  display: "block",
  minWidth: 200,
  color: "#196097"
});

const EventAddToCalendar = ({ event, eventDate }) => {
  if (!event) {
    return null;
  }
  if (!eventDate) {
    const validDates = event.data.dates.filter((date) => date.valid);
    if (validDates.length === 1) {
      eventDate = validDates[0];
    } else {
      return (
        <AddToCalendar allDates>
          <a className={btnClass}>Add to calendar</a>
        </AddToCalendar>
      );
    }
  }
  if (!eventDate) {
    return null;
  }

  return (
    <div>
      <AddToCalendar event={event.data} eventDate={eventDate}>
        <a className={btnClass}>Add to calendar</a>
      </AddToCalendar>
    </div>
  );
};

export default EventAddToCalendar;
