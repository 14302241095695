export const OPEN_FOLLOW_HOST_MODAL = "OPEN_FOLLOW_HOST_MODAL";
export const CLOSE_FOLLOW_HOST_MODAL = "CLOSE_FOLLOW_HOST_MODAL";

export const FOLLOW_HOST_REQUEST = "FOLLOW_HOST_REQUEST";
export const FOLLOW_HOST_SUCCESS = "FOLLOW_HOST_SUCCESS";
export const FOLLOW_HOST_ERROR = "FOLLOW_HOST_ERROR";

import FollowHostService from "../../services/FollowHostService";

export const openModal = () => {
  return (dispatch) => {
    dispatch({ type: OPEN_FOLLOW_HOST_MODAL });
  };
};

export const closeModal = () => {
  return (dispatch) => {
    dispatch({ type: CLOSE_FOLLOW_HOST_MODAL });
  };
};

export const follow = (organiserId, followerInfo) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FOLLOW_HOST_REQUEST });
      const result = await FollowHostService.follow(organiserId, followerInfo);
      dispatch({ type: FOLLOW_HOST_SUCCESS, result });
    } catch (error) {
      dispatch({ type: FOLLOW_HOST_ERROR, error });
    }
  };
};
