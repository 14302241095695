import axios from "./AxiosWrapper";
import config from "../config";

const setParams = (params = {}) => {
  return {
    params
  };
};
const AttendeeProfileService = {
  async check(identifier) {
    const response = await axios.get(`${config("API_URL")}attendee-profile/check/${identifier}`);
    return response.data;
  },
  async checkIfComplete(orderId) {
    const response = await axios.post(`${config("API_URL")}attendee-profile/order/${orderId}/determine-complete-order`);
    return response.data;
  },
  async createAndAssign(ticketId, profileData, token) {
    const response = await axios.post(
      `${config("API_URL")}attendee-profile/${ticketId}`,
      profileData,
      setParams({ token })
    );
    return response.data;
  },
  async assign(ticketId, profileId, token) {
    const response = await axios.post(
      `${config("API_URL")}attendee-profile/${ticketId}/${profileId}`,
      {},
      setParams({ token })
    );
    return response.data;
  },
  async getAssignedProfiles(orderId, token) {
    const response = await axios.get(`${config("API_URL")}attendee-profile/assigned/${orderId}`, setParams({ token }));
    return response.data;
  },
  async updateProfile(orderId, attendeeProfileId, profile, token) {
    const response = await axios.post(
      `${config("API_URL")}attendee-profile/update/${orderId}/${attendeeProfileId}`,
      profile,
      setParams({ token })
    );
    return response.data;
  },
  async unAssignTicket(ticketId, token) {
    const response = await axios.post(
      `${config("API_URL")}attendee-profile/ticket/un-assign/${ticketId}`,
      {},
      setParams({ token })
    );
    return response.data;
  },
  async updateAssignedAdditionalQuestions(ticketId, additionalQuestions, token) {
    const response = await axios.post(
      `${config("API_URL")}attendee-profile/ticket/additionalQuestions/${ticketId}`,
      additionalQuestions,
      setParams({ token })
    );
    return response.data;
  },
  async getAttendeeData(orderId, eventId, attendeeProfileId, token) {
    const response = await axios.get(
      `${config("API_URL")}public/edit-ticket/order/${orderId}/${eventId}/${attendeeProfileId}/${token}`
    );
    return response.data;
  },
  async updateAttendeeProfilePublic(routeParams, profileData) {
    const { orderId, eventId, attendeeProfileId, token } = routeParams;
    const response = await axios.post(
      `${config("API_URL")}public/edit-ticket/order/${orderId}/${eventId}/${attendeeProfileId}/${token}`,
      profileData
    );
    return response.data;
  },
  async updateAdditionalQuestionPublic(routeParams, tickets) {
    const { orderId, eventId, attendeeProfileId, token } = routeParams;
    const response = await axios.post(
      `${config("API_URL")}public/edit-ticket/additional-question/${orderId}/${eventId}/${attendeeProfileId}/${token}`,
      tickets
    );
    return response.data;
  },
  async addAdditionalQuestions(orderId, additionalQuestions, token) {
    const response = await axios.post(
      `${config("API_URL")}attendee-profile/order/additionalQuestions/${orderId}`,
      additionalQuestions,
      setParams({ token })
    );
    return response.data;
  },
  async updateProfileAdditionalQuestions(eventId, orderId, attendeeProfileId, additionalQuestions, token) {
    const response = await axios.post(
      `${config("API_URL")}attendee-profile/additionalQuestions/${eventId}/${orderId}/${attendeeProfileId}`,
      additionalQuestions,
      setParams({ token })
    );
    return response.data;
  }
};

export default AttendeeProfileService;
