import { h, Component } from "preact";
import { css } from "emotion";
import queryString from "query-string";
import { connect } from "react-redux";

import Content from "../../components/Content";
import Dates from "../../components/Dates";
import EditEvent from "../../components/EditEvent";
import HumanitixCause from "../../components/HumanitixCause";

import EventError from "../../components/EventError";
import EventLinks from "../../components/EventLinks";
import Location from "../../components/Location";
import MoreEvents from "../../components/moreEvents/MoreEvents";
import OrganiserInfo from "../../components/OrganiserInfo";
import Page from "../../components/Page";
import RefundPolicy from "../../components/RefundPolicy";
import RichTextDisplay from "../../components/RichTextDisplay";
import SharingLinks from "../../components/SharingLinks";
import TicketsBar from "../../components/TicketsBar";
import { getEvent } from "../../state/actions/eventActions";
import { clearTickets } from "../../state/actions/ticketsActions";
import EventAddToCalendar from "./EventAddToCalendar";
import EventBanner from "./EventBanner";
import LinkedInAttending from "../../components/LinkedInAttending";

const mobile = css({
  display: "none",
  "@media(max-width: 700px)": {
    display: "block"
  }
});

const desktop = css({
  display: "block",
  "@media(max-width: 700px)": {
    display: "none"
  }
});

const eventContainer = css({
  padding: "48px 22px 72px",
  boxSizing: "border-box",
  marginBottom: "12",
  margin: "0 auto",
  "@media (max-width: 700px)": {
    padding: "24px 12px 0px",
    margin: 0,
    width: "100%"
  },
  pre: {
    whiteSpace: "pre-wrap"
  }
});

const cols = css({
  display: "flex",
  marginBottom: 24
});

const eventInfo = css({
  padding: "0 140px 0 0",
  flex: "1 1 100%",
  maxWidth: "calc(100% - 280px)",
  "@media (max-width: 700px)": {
    padding: "0 0 0 0",
    maxWidth: "100%"
  }
});

const eventAside = css([
  {
    // width: 280
    flex: "0 0 280px"
  },
  desktop
]);

class Event extends Component {
  componentDidMount() {
    const { event, getEvent, eventSlug } = this.props;
    const parsed = queryString.parse(window.location.search);
    if (!event.data && !event.loading) {
      getEvent(eventSlug, parsed.token);
    } else if (event.data && event.data.slug !== eventSlug) {
      getEvent(eventSlug, parsed.token);
      clearTickets();
    }
  }

  render({ event, eventSlug }) {
    const isBrowser = typeof window !== "undefined";
    let accessCode;
    let discountCode;
    let parsedTheme;
    if (isBrowser) {
      const parsed = queryString.parse(window.location.search);
      parsedTheme = parsed.theme && parsed.theme !== "undefined" ? JSON.parse(parsed.theme) : false;
      accessCode = parsed.accesscode;
      discountCode = parsed.discountcode ? parsed.discountcode : null;
    }
    const locationType =
      event && event.data && event.data.eventLocation && event.data.eventLocation.type
        ? event.data.eventLocation.type
        : false;

    if (event.error) {
      return (
        <Content>
          <EventError type="notFound" />
        </Content>
      );
    }

    if (event.data && !event.data.published) {
      return (
        <Content>
          <EventError type="notPublished" eventId={event.data._id} />
        </Content>
      );
    }
    if (event.loading || !event.data) {
      return (
        <Page>
          <Content>
            <div class="loading loading-lg" style={{ minHeight: 500 }} />
          </Content>
        </Page>
      );
    }

    const headingTitle = css({
      margin: "0 0 50px 0 !important",
      display: event.data.useOldBannerStyle ? "block" : "none",
      marginBottom: event.data.useOldBannerStyle ? 50 : "inherit",
      marginTop: event.data.useOldBannerStyle ? 50 : "inherit",
      "@media (max-width: 700px)": {
        margin: "0 0 30px 0 !important"
      },
      "@media(max-width: 1015px)": {
        display: "block",
        marginBottom: 24,
        fontSize: 18,
        fontWeight: 600,
        color: "#323232"
      }
    });

    const eventOrganiser = css({
      marginTop: event.data.useOldBannerStyle ? 50 : 0,
      display: "none",
      "@media(max-width: 1015px)": {
        display: !event.data.useOldBannerStyle ? "block" : "none"
      },
      fontSize: 12,
      fontWeight: 600,
      color: "#5a626c",
      marginBottom: 12
    });

    const description = css({
      fontWeight: 600,
      "@media (max-width: 700px)": {
        marginTop: locationType === "online" ? 0 : 24
      }
    });

    const theme = parsedTheme ? parsedTheme : event && event.data && event.data.theme ? event.data.theme : null;

    return (
      <div>
        <Page header background={false} border={false} banner={<EventBanner event={event.data} theme={theme} />}>
          <EditEvent event={event.data} />
          <TicketsBar
            event={event.data}
            theme={theme}
            accessCode={accessCode}
            discountCode={discountCode}
            eventSlug={eventSlug}
          />
          <Content className={eventContainer}>
            <div className={cols}>
              <div className={eventInfo}>
                {event.data.organiser && event.data.organiser.name ? (
                  <a className={eventOrganiser} href="#organiserInfoBox">
                    {event.data.organiser.name}
                  </a>
                ) : null}
                <h1 className={headingTitle}>{event.data.name}</h1>
                <div className={mobile}>
                  <Dates
                    event={event.data}
                    eventSlug={eventSlug}
                    oldStyle={event.data.useOldBannerStyle}
                    addToCalendar={<EventAddToCalendar event={event} theme={theme} />}
                  />
                  <Location location={event.data.eventLocation} oldStyle={event.data.useOldBannerStyle} />
                </div>

                <h3 className={description}>Event description</h3>
                <RichTextDisplay html={event.data.description} />

                <SharingLinks event={event.data} />
                <EventLinks event={event.data} />
                <RefundPolicy event={event.data} />
                <LinkedInAttending />
              </div>
              <div className={eventAside} data-cy="events-aside">
                <Dates
                  event={event.data}
                  eventSlug={eventSlug}
                  oldStyle={event.data.useOldBannerStyle}
                  addToCalendar={<EventAddToCalendar event={event} theme={theme} />}
                />
                <Location location={event.data.eventLocation} oldStyle={event.data.useOldBannerStyle} />
                <HumanitixCause hidden={event.data.settings.removeImpactCommunication} />
              </div>
            </div>
            <OrganiserInfo event={event.data} />
          </Content>
          <div className={mobile} style={{ margin: "0px 12px" }}>
            <HumanitixCause hidden={event.data.settings.removeImpactCommunication} />
          </div>
        </Page>
        <MoreEvents event={event.data} />
      </div>
    );
  }
}

export default connect((state) => ({ event: state.event }), {
  getEvent,
  clearTickets
})(Event);
