import {
  GET_GIFT_CARD_PAGE_ERROR,
  GET_GIFT_CARD_PAGE_REQUEST,
  GET_GIFT_CARD_PAGE_SUCCESS,
  GET_GIFT_CARD_EVENTS_ERROR,
  GET_GIFT_CARD_EVENTS_REQUEST,
  GET_GIFT_CARD_EVENTS_SUCCESS,
  SET_GIFT_CARD_LOCATION
} from "../actions/giftCardActions";

import { GET_ORDER_SUCCESS, SAVE_ORDER_DATA_SUCCESS } from "../actions/orderActions";

import cssVars from "../../utils/css-vars-ponyfill";

const initialState = {
  loading: false,
  error: false,
  giftCardPage: false,
  giftCardList: [],
  giftCardsOrder: [],
  location: "AU",
  events: {
    loading: false,
    error: false,
    list: [],
    page: 0
  }
};

const setStyling = (giftCardPage) => {
  const { style } = giftCardPage;
  const variables = {};
  if (style) {
    variables["--primary-color"] = style.primaryColor;
  }
  if (style.primaryColorHover) {
    variables["--primary-color-hover"] = style.primaryColorHover;
  } else if (style.primaryColor) {
    variables["--primary-color-hover"] = style.primaryColor;
  }
  if (style.primaryColorFade) {
    variables["--primary-color-fade"] = style.primaryColorFade;
  }
  if (style.primaryShadowColour) {
    variables["--primary-shadow-color"] = style.primaryShadowColour;
  }
  if (style.primaryTextColor) {
    variables["--primary-text-color"] = style.primaryTextColor;
  }
  if (style.primaryColor) {
    variables["--link-color"] = style.primaryColor;
  }
  cssVars({ variables });
  return event;
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_GIFT_CARD_LOCATION:
      return {
        ...state,
        location: action.location
      };
    case GET_GIFT_CARD_PAGE_REQUEST:
      return {
        ...state,
        loading: true,
        error: false
      };

    case GET_GIFT_CARD_PAGE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case GET_GIFT_CARD_PAGE_SUCCESS:
      setStyling(action.giftCardPage);
      return {
        ...state,
        loading: false,
        giftCardPage: action.giftCardPage,
        giftCardList: action.giftCards,
        location: action.giftCardPage.location
      };
    case GET_ORDER_SUCCESS: {
      const giftCards = giftCardsFromOrder(action.order);
      return {
        ...state,
        giftCardsOrder: giftCards
      };
    }
    case SAVE_ORDER_DATA_SUCCESS: {
      const giftCards = giftCardsFromOrder(action.order);
      return {
        ...state,
        giftCardsOrder: giftCards
      };
    }
    case GET_GIFT_CARD_EVENTS_ERROR: {
      return {
        ...state,
        events: {
          ...state.events,
          loading: false,
          error: false
        }
      };
    }
    case GET_GIFT_CARD_EVENTS_SUCCESS: {
      return {
        ...state,
        events: {
          ...state.events,
          loading: false,
          error: false,
          list: action.events,
          total: action.total
        }
      };
    }
    case GET_GIFT_CARD_EVENTS_REQUEST: {
      return {
        ...state,
        events: {
          ...state.events,
          page: action.page,
          loading: true,
          error: false
        }
      };
    }
    default:
      return state;
  }
};

const giftCardsFromOrder = (order) => {
  const selectedGiftCardsLookUp = {};
  (order.giftCards || []).forEach((giftCard) => {
    if (!selectedGiftCardsLookUp[giftCard.giftCardTypeId]) {
      let giftCardData = {
        _id: giftCard.giftCardTypeId,
        quantity: 0,
        name: giftCard.giftCardTypeName,
        price: giftCard.price,
        discountCodes: [],
        currency: giftCard.currency
      };
      selectedGiftCardsLookUp[giftCard.giftCardTypeId] = giftCardData;
    }
    if (giftCard.discountCodes && giftCard.discountCodes.length) {
      selectedGiftCardsLookUp[giftCard.giftCardTypeId].discountCodes = giftCard.discountCodes;
    }
    selectedGiftCardsLookUp[giftCard.giftCardTypeId].quantity++;
  });
  return Object.keys(selectedGiftCardsLookUp).map((id) => selectedGiftCardsLookUp[id]);
};

export default {
  initialState,
  reducer,
  setStyling,
  giftCardsFromOrder
};
