export function normaliseTicketSelectionData(ticketSelection) {
  const requestedTickets = (ticketSelection.requestedTickets || [])
    .filter((reqTicket) => !reqTicket.package)
    .map((requestedTicket) => {
      if (requestedTicket.priceOptions && requestedTicket.priceOptions.enabled) {
        return {
          type: "optional",
          ticketTypeId: requestedTicket._id,
          optionalIndex: Number(requestedTicket.priceOptionsIndex),
          accessCode: requestedTicket.accessCode
        };
      }
      if (requestedTicket.priceRange && requestedTicket.priceRange.enabled) {
        return {
          type: "ranged",
          ticketTypeId: requestedTicket._id,
          range: requestedTicket.userEnteredPrice,
          accessCode: requestedTicket.accessCode
        };
      }
      return {
        type: "fixed",
        ticketTypeId: requestedTicket._id,
        accessCode: requestedTicket.accessCode
      };
    });
  const requestedPackageTickets = (ticketSelection.requestedTickets || [])
    .filter((reqTicket) => reqTicket.package)
    .map((requestedPackage) => {
      return {
        type: "package",
        packageTypeId: requestedPackage._id,
        accessCode: requestedPackage.accessCode
      };
    });
  const clientDonation = ticketSelection.clientDonation || 0;
  return { requestedTickets, requestedPackageTickets, clientDonation };
}
