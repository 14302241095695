import WaitlistService from "../../services/WaitlistService";

export const OPEN_WAITLIST = "OPEN_WAITLIST";
export const CLOSE_WAITLIST = "CLOSE_WAITLIST";

export const ADD_TO_WAITLIST_REQUEST = "ADD_TO_WAITLIST_REQUEST";
export const ADD_TO_WAITLIST_SUCCESS = "ADD_TO_WAITLIST_SUCCESS";
export const ADD_TO_WAITLIST_ERROR = "ADD_TO_WAITLIST_ERROR";

export const PASS_WAITLIST_REQUEST = "PASS_WAITLIST_REQUEST";
export const PASS_WAITLIST_SUCCESS = "PASS_WAITLIST_SUCCESS";
export const PASS_WAITLIST_ERROR = "PASS_WAITLIST_ERROR";

export const open = (ticketId) => {
  return (dispatch) => {
    dispatch({ type: OPEN_WAITLIST, ticketId });
  };
};

export const close = () => {
  return (dispatch) => {
    dispatch({ type: CLOSE_WAITLIST });
  };
};

export const add = (data) => {
  return (dispatch) => {
    dispatch({ type: ADD_TO_WAITLIST_REQUEST });
    WaitlistService.add(data)
      .then(() => {
        dispatch({ type: ADD_TO_WAITLIST_SUCCESS });
      })
      .catch(() => {
        dispatch({
          type: ADD_TO_WAITLIST_ERROR,
          error: "Failed to add to waitlist"
        });
      });
  };
};

export const pass = (waitlistId) => {
  return (dispatch) => {
    dispatch({ type: PASS_WAITLIST_REQUEST });
    WaitlistService.pass(waitlistId)
      .then(() => {
        dispatch({ type: PASS_WAITLIST_SUCCESS });
      })
      .catch(() => {
        dispatch({
          type: PASS_WAITLIST_ERROR,
          error: "Failed to remove from waitlist"
        });
      });
  };
};
