import { Component, h } from "preact";
import { connect } from "react-redux";
import Button from "../Button";
import Modal from "../Modal";
import DateSelectorForm from "./DateSelectorForm";
import ReactAddToCalendar from "./ReactAddToCalendar";
import { EventLocation } from "@hx/utilities";

class AddToCalendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    };
  }

  toggleModal = () => {
    this.setState({
      showModal: !this.state.showModal
    });
  };

  render() {
    const { showModal } = this.state;
    const { event, eventDate, order, allDates, children } = this.props;
    if (event && event.settings && event.settings.hideAddToCalendar) {
      return "";
    }
    if (allDates) {
      return (
        <>
          <Modal showModal={showModal} closeHandler={this.toggleModal} title="Add to calendar">
            <div style={{ padding: 16 }}>
              <DateSelectorForm />
            </div>
          </Modal>
          <span onClick={this.toggleModal}>
            {children ? (
              children
            ) : (
              <Button type="secondary" size="small">
                Add to calendar
              </Button>
            )}
          </span>
        </>
      );
    }
    const ticketTypeIds = ((order && order.tickets) || []).map((ticket) => ticket.ticketTypeId);
    const uniqueTicketTypeIds = [...new Set(ticketTypeIds)];
    const ticketTypes = uniqueTicketTypeIds.map((ticketTypeId) =>
      event.ticketTypes.find((ticketType) => ticketType._id === ticketTypeId)
    );
    const avaiablieTicketDates = ticketTypes
      .filter(
        (ticketType) =>
          ticketType && ticketType.overrides && (ticketType.overrides.startDate || ticketType.overrides.endDate)
      )
      .map((ticketType) => {
        const startDate = (ticketType && ticketType.overrides && ticketType.overrides.startDate) || eventDate.startDate;
        const endDate = (ticketType && ticketType.overrides && ticketType.overrides.endDate) || eventDate.endDate;
        return { startDate, endDate };
      });

    const avaiablieDates = [{ startDate: eventDate.startDate, endDate: eventDate.endDate }].concat(
      avaiablieTicketDates
    );
    const uniqueDates = [...new Set(avaiablieDates)];
    let calendarDescription = "";
    if (order || event) {
      calendarDescription = order && order.calendarDescription ? order.calendarDescription : event.calendarDescription;
    }
    const eventCalender = {
      title: event.name,
      description: calendarDescription,
      location: EventLocation.getLocationStringForEventLocation(event.eventLocation),
      startTime: eventDate.startDate,
      endTime: eventDate.endDate
    };
    if (!order || (uniqueDates && uniqueDates.length === 1)) {
      return (
        <ReactAddToCalendar event={eventCalender}>
          {children ? (
            children
          ) : (
            <Button type="secondary" size="small">
              Add to calendar
            </Button>
          )}
        </ReactAddToCalendar>
      );
    }
    return (
      <>
        <Modal showModal={showModal} closeHandler={this.toggleModal} title="Add to calendar">
          <div>
            <DateSelectorForm ticketTypes={ticketTypes} eventDate={eventDate} />
          </div>
        </Modal>
        <Button type="secondary" size="small" onClick={this.toggleModal}>
          Add to calendar
        </Button>
      </>
    );
  }
}

export default connect((state) => {
  const event = state.event && state.event.data;
  const order = state.order && state.order.data;
  let eventDate;
  if (order) {
    event.dates.forEach((date) => {
      if (String(date._id) === String(order.eventDateId)) {
        eventDate = date;
      }
    });
  }
  if (!eventDate) {
    eventDate = event.dates.filter((d) => d.valid)[0];
  }
  return {
    event,
    order,
    eventDate
  };
}, {})(AddToCalendar);
