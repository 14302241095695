/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import DataLayerService from "../../services/dataLayerService";

const initializeEcommerceObject = { ecommerce: null };

async function fetchObjectAndPushToDataLayer(orderId, serviceFunction) {
  try {
    const dataLayerObject = await serviceFunction(orderId);
    if (!dataLayerObject) return;
    window.dataLayer = window.dataLayer || [];
    const hasBeenInitialised = window.dataLayer.includes(
      (dataObject) => JSON.stringify(dataObject) === JSON.stringify(initializeEcommerceObject)
    );
    if (!hasBeenInitialised) {
      window.dataLayer.push(initializeEcommerceObject);
      window?.top?.postMessage({ type: "hx-datalayer-echo", contents: initializeEcommerceObject }, "*");
    }
    window.dataLayer.push(dataLayerObject);
    window?.top?.postMessage({ type: "hx-datalayer-echo", contents: dataLayerObject }, "*");
    // eslint-disable-next-line no-undef
    if (gtag) {
      // eslint-disable-next-line no-undef
      gtag("event", dataLayerObject.event, dataLayerObject.ecommerce);
    }
  } catch (err) {
    //empty on purpose
  }
}

export async function beginCheckout(orderId) {
  await fetchObjectAndPushToDataLayer(orderId, DataLayerService.getBeginCheckout);
}

export async function addShippingInfo(orderId) {
  await fetchObjectAndPushToDataLayer(orderId, DataLayerService.getAddShippingIndo);
}

export async function addPaymentInfo(orderId) {
  await fetchObjectAndPushToDataLayer(orderId, DataLayerService.getAddPaymentInfo);
}

export async function purchase(orderId) {
  await fetchObjectAndPushToDataLayer(orderId, DataLayerService.getPurchaseInfo);
}
