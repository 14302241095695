import VirtualEventHubService from "../../services/VirtualEventHubService";
import LocationService from "../../services/LocationService";

import { GET_EVENT_SUCCESS } from "../actions/eventActions";

export const GET_VIRTUAL_EVENT_HUB_REQUEST = "GET_VIRTUAL_EVENT_HUB_REQUEST";
export const GET_VIRTUAL_EVENT_HUB_SUCCESS = "GET_VIRTUAL_EVENT_HUB_SUCCESS";
export const GET_VIRTUAL_EVENT_HUB_ERROR = "GET_VIRTUAL_EVENT_HUB_ERROR";

export const BEAT_VIRTUAL_EVENT_HUB_REQUEST = "BEAT_VIRTUAL_EVENT_HUB_REQUEST";
export const BEAT_VIRTUAL_EVENT_HUB_SUCCESS = "BEAT_VIRTUAL_EVENT_HUB_SUCCESS";
export const BEAT_VIRTUAL_EVENT_HUB_ERROR = "BEAT_VIRTUAL_EVENT_HUB_ERROR";

export const getVirtualEventHub = (eventId, ticketId, previewId) => {
  return (dispatch) => {
    dispatch({ type: GET_VIRTUAL_EVENT_HUB_REQUEST });
    const accessId = localStorage.getItem(`hub-access-${ticketId}`);
    VirtualEventHubService.get(eventId, ticketId, previewId, accessId)
      .then((result) => {
        const { event, virtualEventHub, date, access, message } = result;
        localStorage.setItem(`hub-access-${ticketId}`, access);
        if (event) {
          LocationService.setEventLocation(event.location);
          dispatch({ type: GET_EVENT_SUCCESS, event });
        }
        if (access && !previewId) {
          setTimeout(() => {
            dispatch(heartbeat(ticketId, access));
          }, 10000);
        }
        dispatch({
          type: GET_VIRTUAL_EVENT_HUB_SUCCESS,
          virtualEventHub,
          date,
          access,
          message
        });
      })
      .catch((err) => {
        dispatch({ type: GET_VIRTUAL_EVENT_HUB_ERROR, error: err });
      });
  };
};

export const heartbeat = (ticketId, accessId, retries = 0) => {
  return (dispatch) => {
    dispatch({ type: BEAT_VIRTUAL_EVENT_HUB_REQUEST });
    VirtualEventHubService.heartbeat(ticketId, accessId)
      .then((result) => {
        const { access, message } = result;
        if (access) {
          dispatch({ type: BEAT_VIRTUAL_EVENT_HUB_SUCCESS, access });
          localStorage.setItem(`hub-access-${ticketId}`, access);
          setTimeout(() => {
            dispatch(heartbeat(ticketId, access));
          }, 10000);
        }
        dispatch({
          type: BEAT_VIRTUAL_EVENT_HUB_ERROR,
          access,
          message
        });
      })
      .catch((err) => {
        // retry the heartbeat times before showing an error
        if (retries < 5) {
          const nextRetry = retries + 1;
          setTimeout(() => {
            dispatch(heartbeat(ticketId, accessId, nextRetry));
          }, nextRetry * 5000);
        }
        console.log("err", err);
        dispatch({
          type: BEAT_VIRTUAL_EVENT_HUB_ERROR,
          access: false,
          message: "Unique link is in use"
        });
      });
  };
};
