import { h, Component } from "preact";
import { Router } from "preact-router";
import { Provider } from "react-redux";

import AsyncRoute from "preact-async-route";
import Footer from "./components/Footer";
import PageLoading from "./components/PageLoading";
import config from "./config";
import "./utils/PolyFill.js";

import create from "./state/store";

import Event from "./routes/event/Event";
import NoEvent from "./routes/noEvent/NoEvent";
import NoTickets from "./routes/noTickets/NoTickets";

import { parseQueryString } from "./utils/Format";
import cssVars from "./utils/css-vars-ponyfill";
import MarkerIO from "./components/MarkerIO";

const allowedOrderData = ["firstName", "lastName", "mobile", "email"];

export default class App extends Component {
  componentWillMount() {
    this.isWidget = false;
    const isBrowser = typeof window !== "undefined";
    if (isBrowser && window.self !== window.top) {
      this.isWidget = true;
      this.initialWidgetLoad = true;
    }
    cssVars();
  }
  componentDidMount() {
    if (typeof window !== "undefined" && this.isWidget) {
      let appDom = document.getElementById("app");
      setInterval(function () {
        appDom = appDom ? appDom : document.getElementById("app");
        if (appDom) {
          window.top.postMessage(
            {
              scrollHeight: appDom.scrollHeight,
              location: window.location.href
            },
            "*"
          );
        }
      }, 100);
    }
  }

  handleRoute = (evt) => {
    try {
      const isBrowser = typeof window !== "undefined";
      if (isBrowser) {
        window.scroll(0, 0);
        const canonicalPath = evt.url.split("?")[0];
        const ticketingUrl = config("TICKETING_URL");
        try {
          const canonicalElement = document.getElementById("canonical-url");
          if (canonicalElement) {
            canonicalElement.href = `${ticketingUrl}${canonicalPath}`;
          }
        } catch (err) {}

        if (this.isWidget) {
          if (!this.initialWidgetLoad) {
            const appDom = document.getElementById("app");
            const scrollHeight = appDom ? appDom.scrollHeight : null;
            window.top.postMessage({ scrollHeight, pageChange: true }, "*");
          } else {
            this.initialWidgetLoad = false;
          }
        }
      }
    } catch (err) {}
  };

  processComponent = (cb, componentOrPromise) => {
    if (componentOrPromise.then) {
      // for client
      return componentOrPromise.then((module) => module.default);
    } else if (componentOrPromise.default) {
      // for node
      cb({ component: componentOrPromise.default });
    }
  };

  render({
    url = null,
    eventData = null,
    orderData = null,
    merchData = null,
    tourData = null,
    giftCardPageData = null
  }) {
    let providedOrderData = {};
    let eventDateIds;
    if (typeof window !== "undefined") {
      url = window.__PRELOADED_STATE__ && window.__PRELOADED_STATE__.url ? window.__PRELOADED_STATE__.url : url;
      eventData =
        window.__PRELOADED_STATE__ && window.__PRELOADED_STATE__.eventData
          ? window.__PRELOADED_STATE__.eventData
          : eventData;
      orderData =
        window.__PRELOADED_STATE__ && window.__PRELOADED_STATE__.orderData
          ? window.__PRELOADED_STATE__.orderData
          : orderData;
      merchData =
        window.__PRELOADED_STATE__ && window.__PRELOADED_STATE__.merchData
          ? window.__PRELOADED_STATE__.merchData
          : merchData;
      tourData =
        window.__PRELOADED_STATE__ && window.__PRELOADED_STATE__.tourData
          ? window.__PRELOADED_STATE__.tourData
          : tourData;
      giftCardPageData =
        window.__PRELOADED_STATE__ && window.__PRELOADED_STATE__.giftCardPageData
          ? window.__PRELOADED_STATE__.giftCardPageData
          : giftCardPageData;
      delete window.__PRELOADED_STATE__;

      // load the params passed in
      const params = parseQueryString(location.search.replace("?", ""));
      const formattedParams = {};
      // eslint-disable-next-line guard-for-in
      for (let key in params) {
        formattedParams[key.toLowerCase()] = params[key];
      }
      // save pre set order data
      allowedOrderData.forEach((key) => {
        if (formattedParams[key.toLowerCase()]) {
          providedOrderData[key] = formattedParams[key.toLowerCase()];
          if (key === "email") {
            providedOrderData.confirmEmail = providedOrderData.email;
          }
        }
      });
      // check invoicing
      const invoiceGateway = params.iv;
      if (invoiceGateway) {
        try {
          localStorage.setItem(`${eventData.slug}-iv`, "true");
        } catch (err) {
          console.log("err", err);
        }
      }

      const redirect = params.redirect;
      if (redirect) {
        try {
          localStorage.setItem(`${eventData.slug}-redirect`, redirect);
        } catch (err) {
          console.log("setting redirect in localstorage failed", err);
        }
      }
      // track affiliateCode
      const affiliateCode = params.c;
      if (affiliateCode && eventData && eventData.affiliateCodes) {
        // check ref
        const affiliateCodes = eventData.affiliateCodes.filter((affiliate) => affiliate.code === affiliateCode);
        const existingCode = affiliateCodes.length ? affiliateCodes[0] : false;
        // save ref link for affiliate tracking
        if (existingCode) {
          try {
            localStorage.setItem(`${eventData.slug}-affiliate`, existingCode.code);
          } catch (err) {}
        }
      }
      eventDateIds = params.eventDateIds;
      // track affiliateCode
      const fb_oea = params.fb_oea;
      if (fb_oea) {
        window.fb_oea = fb_oea;
      }
      const { token, manualOrder, manualOrderSettings } = params;
      if (token) {
        window.token = token;
      }
      if (manualOrder) {
        window.isManualOrder = manualOrder === "true";
        window.manualOrderSettings = manualOrderSettings;
      }
    }
    const store = create(eventData, orderData, providedOrderData, eventDateIds, merchData, tourData, giftCardPageData);
    return (
      <Provider store={store}>
        <main id="app" class={this.isWidget ? "widget" : ""}>
          <MarkerIO />
          <Router url={url} onChange={this.handleRoute}>
            <NoEvent path="/" />
            <NoTickets path="/tickets/no-tickets-found" />
            <AsyncRoute
              loading={() => <PageLoading />}
              path="/tickets/no-tickets-found"
              getComponent={(url, cb, props) => {
                const componentOrPromise = import("./routes/noTickets/NoTickets");
                return this.processComponent(cb, componentOrPromise);
              }}
            />

            <AsyncRoute
              loading={() => <PageLoading />}
              path="/organisers/:organiserId"
              getComponent={(url, cb, props) => {
                const componentOrPromise = import("./routes/organiser/Organiser");
                return this.processComponent(cb, componentOrPromise);
              }}
            />
            <AsyncRoute
              loading={() => <PageLoading />}
              path="/tours/:slug/:tourGroupId?"
              getComponent={(url, cb, props) => {
                const componentOrPromise = import("./routes/tours/Tour");
                return this.processComponent(cb, componentOrPromise);
              }}
            />
            {/** ============= Virtual Event Hub ============== */}

            <AsyncRoute
              loading={() => <PageLoading />}
              path="/hub/preview/:previewId"
              getComponent={(url, cb, props) => {
                const componentOrPromise = import("./routes/virtualEventHub/VirtualEventHub");
                return this.processComponent(cb, componentOrPromise);
              }}
            />

            <AsyncRoute
              loading={() => <PageLoading />}
              path="/hub/:eventId/:ticketId"
              getComponent={(url, cb, props) => {
                const componentOrPromise = import("./routes/virtualEventHub/VirtualEventHub");
                return this.processComponent(cb, componentOrPromise);
              }}
            />

            {/** ============= Gift Card routes ============== */}

            <AsyncRoute
              loading={() => <PageLoading />}
              path="/gift-cards/:eventSlug/details/:orderId"
              getComponent={(url, cb, props) => {
                const componentOrPromise = import("./routes/details/DetailsWrapper");
                return this.processComponent(cb, componentOrPromise);
              }}
            />

            <AsyncRoute
              loading={() => <PageLoading />}
              path="/gift-cards/:eventSlug/payment/:orderId"
              getComponent={(url, cb, props) => {
                const componentOrPromise = import("./routes/payment/Payment");
                return this.processComponent(cb, componentOrPromise);
              }}
            />

            <AsyncRoute
              loading={() => <PageLoading />}
              path="/gift-cards/:slug/complete/:orderId"
              getComponent={(url, cb, props) => {
                const componentOrPromise = import("./routes/complete/GlobalOrderComplete");
                return this.processComponent(cb, componentOrPromise);
              }}
            />
            <AsyncRoute
              loading={() => <PageLoading />}
              path="/gift-cards/:giftCardSlug"
              getComponent={(url, cb, props) => {
                const componentOrPromise = import("./routes/giftCards/GiftCardPage");
                return this.processComponent(cb, componentOrPromise);
              }}
            />

            <AsyncRoute
              loading={() => <PageLoading />}
              path="/gift-cards/:giftCardSlug/event-list/:giftCardTypeId"
              getComponent={(url, cb, props) => {
                const componentOrPromise = import("./routes/giftCards/GiftCardEventPage");
                return this.processComponent(cb, componentOrPromise);
              }}
            />

            {/** ============= Event order routes ============== */}

            <Event path="/:eventSlug" />

            <AsyncRoute
              loading={() => <PageLoading />}
              path="/:eventSlug/additional-questions-preview"
              getComponent={(url, cb, props) => {
                const componentOrPromise = import("./routes/widgets/AdditionalQuestionsPreview");
                return this.processComponent(cb, componentOrPromise);
              }}
            />

            <AsyncRoute
              loading={() => <PageLoading />}
              path="/:eventSlug/complete-preview"
              getComponent={(url, cb, props) => {
                const componentOrPromise = import("./routes/widgets/CompletePreview");
                return this.processComponent(cb, componentOrPromise);
              }}
            />

            <AsyncRoute
              loading={() => <PageLoading />}
              path="/:eventSlug/tickets"
              getComponent={(url, cb, props) => {
                const componentOrPromise = import("./routes/tickets/Tickets");
                return this.processComponent(cb, componentOrPromise);
              }}
            />

            <AsyncRoute
              loading={() => <PageLoading />}
              path="/:eventSlug/assign/:orderId"
              getComponent={(url, cb, props) => {
                const componentOrPromise = import("./routes/conferences/AssignTickets");
                return this.processComponent(cb, componentOrPromise);
              }}
            />

            <AsyncRoute
              loading={() => <PageLoading />}
              path="/:eventSlug/add-ons/:orderId"
              getComponent={(url, cb, props) => {
                const componentOrPromise = import("./routes/merchandise/Merchandise");
                return this.processComponent(cb, componentOrPromise);
              }}
            />

            <AsyncRoute
              loading={() => <PageLoading />}
              path="/:eventSlug/details/:orderId"
              getComponent={(url, cb, props) => {
                const componentOrPromise = import("./routes/details/DetailsWrapper");
                return this.processComponent(cb, componentOrPromise);
              }}
            />

            <AsyncRoute
              loading={() => <PageLoading />}
              path="/:eventSlug/payment/:orderId/:selectedPaymentOption?"
              getComponent={(url, cb, props) => {
                const componentOrPromise = import("./routes/payment/Payment");
                return this.processComponent(cb, componentOrPromise);
              }}
            />

            <AsyncRoute
              loading={() => <PageLoading />}
              path="/:eventSlug/afterCheckout/:orderId"
              getComponent={(url, cb, props) => {
                const componentOrPromise = import("./routes/afterCheckout/AfterCheckout");
                return this.processComponent(cb, componentOrPromise);
              }}
            />

            <AsyncRoute
              loading={() => <PageLoading />}
              path="/:eventSlug/complete/:orderId"
              getComponent={(url, cb, props) => {
                const componentOrPromise = import("./routes/complete/Complete");
                return this.processComponent(cb, componentOrPromise);
              }}
            />
            <AsyncRoute
              loading={() => <PageLoading />}
              path="/:eventSlug/add-to-calendar/:orderId"
              getComponent={(url, cb, props) => {
                const componentOrPromise = import("./routes/complete/AddToCalendar");
                return this.processComponent(cb, componentOrPromise);
              }}
            />
            <AsyncRoute
              loading={() => <PageLoading />}
              path="/:eventSlug/contact"
              getComponent={(url, cb, props) => {
                const componentOrPromise = import("./routes/contact/Contact");
                return this.processComponent(cb, componentOrPromise);
              }}
            />
            <AsyncRoute
              loading={() => <PageLoading />}
              path="/:eventSlug/accessibility"
              getComponent={(url, cb, props) => {
                const componentOrPromise = import("./routes/accessibility/Accessibility");
                return this.processComponent(cb, componentOrPromise);
              }}
            />
            <AsyncRoute
              loading={() => <PageLoading />}
              path="/:eventSlug/seatingmap/:orderId"
              getComponent={(url, cb, props) => {
                const componentOrPromise = import("./routes/seatingMap/SeatingMap");
                return this.processComponent(cb, componentOrPromise);
              }}
            />
            <AsyncRoute
              loading={() => <PageLoading />}
              path="/:eventSlug/waitlist-pass/:waitlistId"
              getComponent={(url, cb, props) => {
                const componentOrPromise = import("./routes/waitlistPass/WaitlistPass");
                return this.processComponent(cb, componentOrPromise);
              }}
            />

            {/** ============= Public after order routes ============== */}

            <AsyncRoute
              loading={() => <PageLoading />}
              path="/public/edit-order/:orderId/:eventId"
              getComponent={(url, cb, props) => {
                const componentOrPromise = import("./routes/public/editOrder/PublicOrderDetails");
                return this.processComponent(cb, componentOrPromise);
              }}
            />

            <AsyncRoute
              loading={() => <PageLoading />}
              path="/public/edit-tickets/:orderId/:eventId/:attendeeProfileId"
              getComponent={(url, cb, props) => {
                const componentOrPromise = import("./routes/public/editTicket/EditTicket");
                return this.processComponent(cb, componentOrPromise);
              }}
            />

            <AsyncRoute
              loading={() => <PageLoading />}
              path="/public/edit-seating-map/:location/:orderId"
              getComponent={(url, cb, props) => {
                const componentOrPromise = import("./routes/public/seatingMap/Iframe");
                return this.processComponent(cb, componentOrPromise);
              }}
            />

            <AsyncRoute
              loading={() => <PageLoading />}
              path="/public/view-seating-map/:orderId/:eventId"
              getComponent={(url, cb, props) => {
                const componentOrPromise = import("./routes/public/seatingMap/PublicSeatingMap");
                return this.processComponent(cb, componentOrPromise);
              }}
            />

            <AsyncRoute
              loading={() => <PageLoading />}
              path="/public/wallet/google"
              getComponent={(url, cb, props) => {
                const componentOrPromise = import("./routes/public/wallet/PublicGoogleWallet");
                return this.processComponent(cb, componentOrPromise);
              }}
            />

            {/** ====================================================== */}
            <AsyncRoute
              loading={() => <PageLoading />}
              path="/event-listings"
              getComponent={(url, cb, props) => {
                const componentOrPromise = import("./routes/widgets/EventListings");
                return this.processComponent(cb, componentOrPromise);
              }}
            />
            {/** ====================================================== */}
            <AsyncRoute
              loading={() => <PageLoading />}
              path=":eventSlug/payment/:orderId/zipmoneypay"
              getComponent={(url, cb, props) => {
                const componentOrPromise = import("./routes/payment/components/ZipMoneyCharge");
                return this.processComponent(cb, componentOrPromise);
              }}
            />
            {/** ====================================================== */}
            <AsyncRoute
              loading={() => <PageLoading />}
              path=":eventSlug/payment/:orderId/pin-verify-charge"
              getComponent={(url, cb, props) => {
                const componentOrPromise = import("./routes/payment/components/PinThreeDSCharge");
                return this.processComponent(cb, componentOrPromise);
              }}
            />
          </Router>
          {this.isWidget === false ? <Footer path="/:eventSlug" /> : null}
        </main>
      </Provider>
    );
  }
}
