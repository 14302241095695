import { h } from "preact";
import Logo from "../assets/images/Logo";

const PoweredByHumanitix = () => {
  return (
    <div
      style={{
        fontWeight: 300,
        padding: "0 12px 12px 12px",
        display: "flex",
        justifyContent: "flex-end"
      }}
    >
      <p
        style={{
          fontSize: 10,
          margin: 0,
          color: "#5A626C"
        }}
      >
        Powered by
      </p>{" "}
      <div
        style={{
          height: 15,
          marginLeft: 5,
          marginTop: 3
        }}
      >
        <Logo footerLogoColor={"black"} height={15} />
      </div>
    </div>
  );
};
export default PoweredByHumanitix;
