import {
  ADD_TO_WAITLIST_REQUEST,
  ADD_TO_WAITLIST_SUCCESS,
  ADD_TO_WAITLIST_ERROR,
  PASS_WAITLIST_REQUEST,
  PASS_WAITLIST_SUCCESS,
  PASS_WAITLIST_ERROR,
  OPEN_WAITLIST,
  CLOSE_WAITLIST
} from "../actions/waitlistActions";

const initialState = {
  open: false,
  ticketId: null,
  add: {
    loading: false,
    error: false,
    added: false
  },
  pass: {
    loading: true,
    error: false,
    added: false
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_WAITLIST:
      return {
        ...state,
        ...initialState,
        open: true,
        ticketId: action.ticketId
      };
    case CLOSE_WAITLIST:
      return {
        ...state,
        ...initialState,
        open: false
      };
    case ADD_TO_WAITLIST_REQUEST:
      return {
        ...state,
        add: {
          ...state.add,
          loading: true,
          error: false
        }
      };
    case ADD_TO_WAITLIST_SUCCESS:
      return {
        ...state,
        add: {
          ...state.add,
          added: true,
          loading: false,
          error: false
        }
      };
    case ADD_TO_WAITLIST_ERROR:
      return {
        ...state,
        add: {
          ...state.add,
          added: false,
          loading: false,
          error: action.error
        }
      };
    case PASS_WAITLIST_REQUEST:
      return {
        ...state,
        pass: {
          ...state.pass,
          loading: true,
          error: false
        }
      };
    case PASS_WAITLIST_SUCCESS:
      return {
        ...state,
        pass: {
          ...state.pass,
          passed: true,
          loading: false,
          error: false
        }
      };
    case PASS_WAITLIST_ERROR:
      return {
        ...state,
        pass: {
          ...state.pass,
          loading: false,
          error: action.error
        }
      };
    default:
      return state;
  }
};
export default {
  initialState,
  reducer
};
