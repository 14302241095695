import { h } from "preact";
import { css } from "emotion";

const cdn = "https://cdn.filestackcontent.com";

const imgBox = css({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%"
});

function imagePath(handle, format, width) {
  return `${cdn}/compress/output=format:${format}/cache=expiry:max/resize=width:${width}/${handle}`;
}

function buildSrcSet(handle, format, width) {
  const sizes = [width / 2, width, width * 1.3];
  return sizes.map((size) => `${imagePath(handle, format, size)} ${size}w`).join(", ");
}

const Picture = ({ img, width = 1000, alt, ratio = 2 }) => {
  let handle = "VbieTVjAQvq5BTkE9vIi";
  if (img) {
    handle = img.handle;
  }
  const height = width / ratio;
  const aspectRatioBox = css({
    height: 0,
    overflow: "hidden",
    paddingTop: `${(height / width) * 100}%`,
    position: "relative"
  });

  const imageTypes = ["webp", "png", "jpg"];
  return (
    <div className={aspectRatioBox}>
      <div className={imgBox}>
        <picture>
          {imageTypes.map((type) => (
            <source srcset={buildSrcSet(handle, type, width)} type={`image/${type}`} sizes={`${width}w`} />
          ))}
          <img
            src={`${imagePath(handle, "jpg", width)}`}
            alt={alt}
            width={width}
            srcset={buildSrcSet(handle, "jpg", width)}
          />
        </picture>
      </div>
    </div>
  );
};
export default Picture;
