/* eslint-disable no-var,no-redeclare */
import { locationDataByCurrency, locationDataByCountryCode } from "@hx/locations";

export const getCurrencySymbol = (currencyCode) => {
  return locationDataByCurrency[currencyCode] ? locationDataByCurrency[currencyCode].symbol : "$";
};

export const getCurrencySymbolByLocation = (location) => {
  return locationDataByCountryCode[location] ? locationDataByCountryCode[location].symbol : "$";
};

export const currency = (number, options = {}) => {
  const { currencyCode = "AUD", prefix = false, hideCentsIfWholeNumber = false } = options;
  if (isNaN(number) || number === null) {
    return number;
  }
  const symbol = getCurrencySymbol(currencyCode);
  let str = `${symbol}${number.toFixed(2)}`;
  if (hideCentsIfWholeNumber) {
    str = str.replace(".00", "");
  }
  if (prefix) {
    str = `${currencyCode} ${str}`;
  }
  return str;
};

export const truncateString = (string, maxLength = 50) => {
  if (!string) return null;
  if (string.length <= maxLength) return string;
  return `${string.substring(0, maxLength)}...`;
};

export const parseQueryString = (query) => {
  const vars = query.split("&");
  const query_string = {};
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split("=");
    // If first entry with this name
    if (typeof query_string[pair[0]] === "undefined") {
      query_string[pair[0]] = decodeURIComponent(pair[1]);
      // If second entry with this name
    } else if (typeof query_string[pair[0]] === "string") {
      const arr = [query_string[pair[0]], decodeURIComponent(pair[1])];
      query_string[pair[0]] = arr;
      // If third or later entry with this name
    } else {
      query_string[pair[0]].push(decodeURIComponent(pair[1]));
    }
  }
  return query_string;
};

export const reverseCamelize = (string) => {
  const text = string;
  const result = text.replace(/([A-Z])/g, " $1");
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
};

export const checkPad = (number) => {
  if (isNaN(number)) {
    return number;
  }
  let numberStr = number.toFixed(2);
  numberStr = numberStr.replace(".00", "");
  return numberStr;
};

// convert value to array based on delimiter
export const convertValueToArray = (value, delimiter) => {
  const values = value && String(value).indexOf(delimiter) !== -1 ? value.split(delimiter) : [value];
  return values;
};
// convert css hex to rgba
export const hexToRgba = (hex, opacity) => {
  const isAString = typeof hex === "string" || hex instanceof String;
  if (!isAString) {
    return hex;
  }
  // eslint-disable-next-line no-redeclare
  var opacity = opacity || 100;
  // eslint-disable-next-line no-redeclare
  var hex = hex.replace("#", "");
  if (hex.length === 6) {
    var r = parseInt(hex.substring(0, 2), 16);
    var g = parseInt(hex.substring(2, 4), 16);
    var b = parseInt(hex.substring(4, 6), 16);
  } else {
    var rd = hex.substring(0, 1) + hex.substring(0, 1);
    var gd = hex.substring(1, 2) + hex.substring(1, 2);
    var bd = hex.substring(2, 3) + hex.substring(2, 3);
    var r = parseInt(rd, 16);
    var g = parseInt(gd, 16);
    var b = parseInt(bd, 16);
  }
  return "rgba(" + r + ", " + g + ", " + b + ", " + opacity / 100 + ")";
};

export const checkUrlProtocol = (url) => {
  if (url.indexOf("http") === 0 || url.indexOf("//") === 0) {
    return url;
  }
  // no Protocol found add https://
  return `https://${url}`;
};
