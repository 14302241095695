const Directions = ({ location }) => {
  if (
    location &&
    location.type !== "online" &&
    location.type !== "toBeAnnounced" &&
    location.latLng &&
    location.latLng.length &&
    location.placeId
  ) {
    return (
      <a
        href={`https://www.google.com/maps/search/?api=1&query=${location.latLng[0]},${location.latLng[1]}&query_place_id=${location.placeId}`}
      >
        Get directions
      </a>
    );
  }
  return null;
};
export default Directions;
