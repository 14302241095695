import { h } from "preact";
import { css } from "emotion";

const wrapper = css({
  overflow: "hidden"
});

const slider = css({
  whiteSpace: "nowrap",
  display: "flex",
  overflowY: "hidden",
  overflowX: "scroll",
  width: "1px",
  minWidth: "100%"
});

const Slider = ({ children }) => (
  <div className={wrapper}>
    <div class={slider}>{children}</div>
  </div>
);

export default Slider;
