import { Link } from "preact-router/match";
import Picture from "./Picture";
import { css } from "emotion";
import colorContrast from "color-contrast";
import IconFollower from "../assets/images/IconFollower";
import Follow from "./followHost/Follow";

const OrganiserInfo = ({ event, hideContactLink }) => {
  if (!event) {
    return null;
  }

  const primaryColor = event.theme && event.theme.primaryColor ? event.theme.primaryColor : "#353337";

  const colorCode = ["#323232", "#FFFFFF"];
  let primaryTextColor = "#323232";
  if (event.theme && event.theme.primaryColor) {
    colorCode.forEach((color) => {
      if (colorContrast(primaryColor, color) >= 4.5) {
        primaryTextColor = color;
      }
    });
  }

  const noPicture = css({
    backgroundColor: `${primaryColor}`,
    fontSize: 26,
    borderRadius: 4,
    width: "100%",
    height: "100%",
    paddingTop: 30,
    "@media(max-width: 700px)": {
      fontSize: 18
    }
  });

  const linkColor = event.theme && event.theme.linkColor ? event.theme.linkColor : "#196097";

  const linkSpans = css({
    margin: "auto",
    a: {
      color: linkColor,
      fontWeight: 600
    },
    svg: {
      fill: linkColor
    }
  });

  const hostTitle = event.organiser ? `${event.organiser.name}` : `Event host`;

  const contactInfo = hideContactLink ? null : <ContactLink event={event} primaryColor={primaryColor} />;

  const hostTotalFollowers = event.organiser?.totalFollowers ? (
    <div className={followingInfoStyle}>
      <IconFollower style={{ marginRight: 4 }} />
      {event.organiser.totalFollowers} followers
    </div>
  ) : null;

  //getting acronym from event organisers name if no picture supplied
  const organiserName = event.organiser ? event.organiser.name : false;
  const acronym = organiserName
    ? organiserName.split(/\s/).reduce((response, word) => (response += word.slice(0, 1)), "")
    : false;

  const organiserLogo = event.organiser && event.organiser.logo ? event.organiser.logo : false;

  const showPicture = !!(organiserLogo || organiserName);
  const hostPicture = (
    <>
      {showPicture ? (
        <div className={pictureStyle}>
          {organiserLogo ? (
            <Picture img={organiserLogo} alt={`${organiserName} logo`} width={100} ratio={1} />
          ) : organiserName ? (
            <div className={noPicture}>
              <div style={{ display: "inline-block", color: primaryTextColor }}>{acronym}</div>
            </div>
          ) : null}
        </div>
      ) : null}
    </>
  );

  return (
    <div className={outerWrapper}>
      <div className={wrapper}>
        {hostPicture}
        <div style={{ marginBottom: 12 }}>
          <h3 id="organiserInfoBox" style={{ fontWeight: 600, marginBottom: 0 }}>
            {hostTitle}
          </h3>
          {hostTotalFollowers ? <div className={hostTotalFollowersWrapper}>{hostTotalFollowers}</div> : null}
        </div>
        <div className={infoLinks}>
          <span className={linkSpans}>{contactInfo}</span>
          <OrganiserLink event={event} linkSpans={linkSpans} primaryColor={primaryColor} />
          <Follow organiser={event.organiser} />
        </div>
      </div>
    </div>
  );
};

export default OrganiserInfo;

const ContactLink = ({ event, primaryColor }) => {
  return (
    <Link href={`/${event.slug}/contact`} style={{ color: primaryColor }}>
      CONTACT HOST
    </Link>
  );
};

const OrganiserLink = ({ event, linkSpans, primaryColor }) => {
  if (!event.organiser) {
    return null;
  }
  return (
    <span className={linkSpans}>
      <a href={`/host/${event.organiser.slug}`} style={{ color: primaryColor }} native>
        VIEW PROFILE
      </a>
    </span>
  );
};

const wrapper = css({
  border: "1px solid #DEDEDE",
  borderRadius: 5,
  padding: 24,
  textAlign: "center",
  "@media(max-width: 700px)": {
    border: "none",
    paddingLeft: 0,
    paddingRight: 0
  }
});

const pictureStyle = css({
  width: 100,
  height: 100,
  display: "inline-block",
  marginBottom: 16,
  overflow: "hidden",
  borderRadius: "50%",
  "@media(max-width: 700px)": {
    marginBottom: 12,
    width: 80,
    height: 80
  }
});

const outerWrapper = css({
  width: "100%",
  "@media(max-width: 600px)": {
    borderTop: "1px solid #DEDEDE"
  }
});

const infoLinks = css({
  margin: "0px auto 16px",
  padding: "0px 8px",
  maxWidth: 400,
  display: "flex",
  justifyItems: "center",
  justifyContent: "space-between",
  "@media(max-width: 700px)": {
    fontSize: 14,
    marginBottom: 12
  }
});

const hostTotalFollowersWrapper = css({
  display: "flex",
  justifyContent: "center"
});

const followingInfoStyle = css({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: 300
});
