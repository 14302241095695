import { h } from "preact";
import config from "../config";
import loadScript from "load-script";
import { useEffect } from "preact/hooks";

const MarkerIO = () => {
  useEffect(() => {
    const NODE_ENV = config("NODE_ENV");
    const show = NODE_ENV !== "production";
    if (show) {
      loadScript(`/markerio.js`);
    }
  }, []);

  return <div></div>;
};

export default MarkerIO;
