import { css } from "emotion";
import config from "../config";
import Logo from "../assets/images/Logo";

const wrapperStyle = css({
  textAlign: "center",
  color: "#3f3e41",
  margin: 0,
  width: "100%",
  p: {
    margin: "8px 0px 0px"
  },
  h4: {
    margin: "8px 0px 0px",
    fontSize: 14
  },
  h5: {
    textAlign: "center",
    borderBottom: "1px solid #353337",
    lineHeight: "0.1em",
    fontSize: 12,
    margin: "8px 0px 0px",
    span: {
      background: "#fff",
      padding: "0 4px"
    }
  },

  border: "1px solid #dedede",
  padding: "16px 20px 20px",
  borderRadius: 4
});

const logoStyle = css({
  width: "135px",
  height: "32px",
  margin: "14px auto 0px"
});

const HumanitixCause = ({ hidden = false }) => {
  if (hidden) {
    return null;
  }

  return (
    <div className={wrapperStyle}>
      <h5>
        <span>POWERED BY</span>
      </h5>
      <div className={logoStyle}>
        <a href={config("WEBSITE_URL")}>
          <Logo footerLogoColor={"black"} width={135} height={32} />
        </a>
      </div>
      <p>Humanitix donates 100% of profits to children's charities.</p>
      <h4>The humane choice for tickets.</h4>
    </div>
  );
};
export default HumanitixCause;
