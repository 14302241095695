import { h } from "preact";
import { EventLocation } from "@hx/utilities";
import { checkUrlProtocol } from "../utils/Format";

const LocationStr = ({ location }) => {
  if (location.type === "online") {
    return (
      <>
        {location.instructions}
        {location.onlineUrl ? (
          <a href={checkUrlProtocol(location.onlineUrl)} target="_blank" rel="noreferrer">
            Online event link
          </a>
        ) : null}
      </>
    );
  }
  if (location.type === "toBeAnnounced") {
    return <p>{EventLocation.getToBeAnnouncedString(location)}</p>;
  }
  const parts = EventLocation.getAddressParts(location);
  return (
    <p>
      {parts.map((part, index) => {
        return (
          <span style={{ color: "#323232" }}>
            {part}
            {index < part.length - 1 ? <br /> : ""}
          </span>
        );
      })}
    </p>
  );
};
export default LocationStr;
