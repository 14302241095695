import TourService from "../../services/TourService";

export const GET_TOUR_REQUEST = "GET_TOUR_REQUEST";
export const GET_TOUR_SUCCESS = "GET_TOUR_SUCCESS";
export const GET_TOUR_ERROR = "GET_TOUR_ERROR";

export const GET_TOUR_EVENTS_REQUEST = "GET_TOUR_EVENTS_REQUEST";
export const GET_TOUR_EVENTS_SUCCESS = "GET_TOUR_EVENTS_SUCCESS";
export const GET_TOUR_EVENTS_ERROR = "GET_TOUR_EVENTS_ERROR";

export const GET_TOUR_OPTIONS_REQUEST = "GET_TOUR_OPTIONS_REQUEST";
export const GET_TOUR_OPTIONS_SUCCESS = "GET_TOUR_OPTIONS_SUCCESS";

const onlyEventUpdate = (query) => {
  return !!(query.date || query.location || query.onlyEventLoading);
};

export const getTour = (slug, query, token) => {
  return (dispatch) => {
    if (onlyEventUpdate(query)) {
      dispatch({ type: GET_TOUR_EVENTS_REQUEST });
    } else {
      dispatch({ type: GET_TOUR_REQUEST });
    }
    TourService.getTour(slug, query, token)
      .then((result) => {
        const tour = result.data;
        const tourEvents = tour.events ? tour.events : [];
        const featuredEvents = tour.featuredEvents ? tour.featuredEvents : [];
        const totalEventSize = tour.totalEventSize ? tour.totalEventSize : 0;
        if (onlyEventUpdate(query)) {
          dispatch({
            type: GET_TOUR_EVENTS_SUCCESS,
            tourEvents,
            featuredEvents,
            totalEventSize
          });
        } else {
          dispatch({
            type: GET_TOUR_SUCCESS,
            tour,
            tourEvents,
            featuredEvents,
            totalEventSize
          });
        }
      })
      .catch((err) => {
        dispatch({ type: GET_TOUR_ERROR, error: err });
      });
  };
};

export const getTourFilterOptions = (slug, query) => {
  return (dispatch) => {
    dispatch({ type: GET_TOUR_OPTIONS_REQUEST });
    TourService.getTourFilterOptions(slug, query).then((result) => {
      dispatch({
        type: GET_TOUR_OPTIONS_SUCCESS,
        tourLocations: result.data.locationOptions,
        tourDates: result.data.dateOptions
      });
    });
  };
};

export const getTourPreview = (slug, query) => {
  return (dispatch) => {
    if (onlyEventUpdate(query)) {
      dispatch({ type: GET_TOUR_EVENTS_REQUEST });
    } else {
      dispatch({ type: GET_TOUR_REQUEST });
    }
    TourService.getTourPreview(slug, query)
      .then((result) => {
        const tour = result.data;
        const tourEvents = tour.events ? tour.events : [];
        const featuredEvents = tour.featuredEvents ? tour.featuredEvents : [];
        const totalEventSize = tour.totalEventSize ? tour.totalEventSize : 0;
        if (onlyEventUpdate(query)) {
          dispatch({
            type: GET_TOUR_EVENTS_SUCCESS,
            tourEvents,
            featuredEvents,
            totalEventSize
          });
        } else {
          dispatch({
            type: GET_TOUR_SUCCESS,
            tour,
            tourEvents,
            featuredEvents,
            totalEventSize
          });
        }
      })
      .catch((err) => {
        dispatch({ type: GET_TOUR_ERROR, error: err });
      });
  };
};
