import axios from "./AxiosWrapper";
import config from "../config";

const StripeService = {
  createPaymentIntent(orderId) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${config("API_URL")}stripe/payment-intent/${orderId}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
};

export default StripeService;
