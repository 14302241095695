import axios from "./AxiosWrapper";
import config from "../config";

const WaitlistService = {
  add(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${config("API_URL")}waitlist/add`, data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  pass(waitlistId) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${config("API_URL")}waitlist/pass/${waitlistId}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
};

export default WaitlistService;
