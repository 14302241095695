import OrderService from "../../services/OrderService";
import { normaliseTicketSelectionData } from "../../utils/normaliseTicketSelectionData";
import { normaliseRequestedGiftCards } from "../../utils/normaliseGiftCardData";
import { createManualOrderTicketOverride } from "../../utils/ManualOrderUtils";
export const GET_SUMMARY_REQUEST = "GET_SUMMARY_REQUEST";
export const GET_SUMMARY_SUCCESS = "GET_SUMMARY_SUCCESS";
export const GET_SUMMARY_ERROR = "GET_SUMMARY_ERROR";

export const loadSummary = () => {
  return async (dispatch, getState) => {
    try {
      const { order } = getState();
      const orderId = order.data._id;
      dispatch({ type: GET_SUMMARY_REQUEST });
      const result = await OrderService.summary(orderId);
      dispatch({
        type: GET_SUMMARY_SUCCESS,
        summary: result
      });
    } catch (err) {
      dispatch({ type: GET_SUMMARY_ERROR, error: "Summary failed to load" });
    }
  };
};

export const loadTicketRequestSummary = () => {
  return async (dispatch, getState) => {
    try {
      const { ticketSelection, event } = getState();
      dispatch({ type: GET_SUMMARY_REQUEST });
      const { requestedPackageTickets, requestedTickets, clientDonation } =
        normaliseTicketSelectionData(ticketSelection);

      const manualOrderTicketOverride = createManualOrderTicketOverride(ticketSelection);

      const result = await OrderService.ticketRequestSummary(
        event.data._id,
        requestedTickets,
        requestedPackageTickets,
        manualOrderTicketOverride,
        clientDonation
      );
      dispatch({
        type: GET_SUMMARY_SUCCESS,
        summary: result
      });
    } catch (err) {
      dispatch({ type: GET_SUMMARY_ERROR, error: "Summary failed to load" });
    }
  };
};

export const loadAddOnRequestSummary = (orderId, requestedAddons) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_SUMMARY_REQUEST });
      const result = await OrderService.addOnRequestSummary(orderId, requestedAddons);
      dispatch({
        type: GET_SUMMARY_SUCCESS,
        summary: result
      });
    } catch (err) {
      dispatch({
        type: GET_SUMMARY_ERROR,
        error: "Summary failed to load"
      });
    }
  };
};

export const loadGlobalSummary = () => {
  return async (dispatch, getState) => {
    try {
      const { order } = getState();
      const orderId = order.data._id;
      dispatch({ type: GET_SUMMARY_REQUEST });
      const result = await OrderService.globalSummary(orderId);
      dispatch({
        type: GET_SUMMARY_SUCCESS,
        summary: result
      });
    } catch (err) {
      dispatch({ type: GET_SUMMARY_ERROR, error: "Summary failed to load" });
    }
  };
};

export const loadGiftCardRequestSummary = (requestedGiftCards) => {
  return async (dispatch, getState) => {
    try {
      const { giftCards } = getState();
      dispatch({ type: GET_SUMMARY_REQUEST });
      const giftCardPageId = giftCards.giftCardPage._id;
      const result = await OrderService.giftCardSummary(
        giftCardPageId,
        normaliseRequestedGiftCards(requestedGiftCards)
      );
      dispatch({
        type: GET_SUMMARY_SUCCESS,
        summary: result
      });
    } catch (err) {
      dispatch({ type: GET_SUMMARY_ERROR, error: "Summary failed to load" });
    }
  };
};
