import loadScript from "load-script";

import StripeService from "../../services/StripeService";

export const STRIPE_CHECKOUT_REQUEST = "STRIPE_CHECKOUT_REQUEST";
export const STRIPE_CHECKOUT_SUCCESS = "STRIPE_CHECKOUT_SUCCESS";
export const STRIPE_CHECKOUT_ERROR = "STRIPE_CHECKOUT_ERROR";

export const createCheckout = (orderId) => {
  return async (dispatch) => {
    dispatch({ type: STRIPE_CHECKOUT_REQUEST });
    loadScript("https://js.stripe.com/v3/", (err, script) => {
      if (err) {
        dispatch({ type: STRIPE_CHECKOUT_ERROR });
        return;
      }
      StripeService.createPaymentIntent(orderId)
        .then((response) => {
          const { clientSecret, paymentIntentId } = response;
          dispatch({ type: STRIPE_CHECKOUT_SUCCESS, clientSecret, paymentIntentId });
        })
        .catch((err) => {
          dispatch({ type: STRIPE_CHECKOUT_ERROR, error: "Failed to create payment" });
        });
    });
  };
};
