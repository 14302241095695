import {
  LOAD_PIN_REQUEST,
  LOAD_PIN_SUCCESS,
  LOAD_PIN_ERROR,
  PIN_INIT,
  PIN_INIT_REQUEST,
  UNMOUNT_PIN
} from "../actions/pinActions";

const initialState = {
  checkoutScript: {
    loading: false,
    error: false,
    loaded: false
  },
  payment: {
    loading: true,
    initialised: false
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_PIN_REQUEST:
      return {
        ...state,
        payment: {
          ...initialState.payment
        },
        checkoutScript: {
          loading: true,
          loaded: false,
          error: false
        }
      };
    case LOAD_PIN_SUCCESS:
      return {
        ...state,
        checkoutScript: {
          loading: false,
          loaded: true,
          error: false
        }
      };
    case LOAD_PIN_ERROR:
      return {
        ...state,
        checkoutScript: {
          loading: false,
          loaded: true,
          error: true
        }
      };
    case PIN_INIT_REQUEST: {
      return {
        ...state,
        payment: {
          ...state.payment,
          loading: true,
          initialised: true
        }
      };
    }
    case PIN_INIT:
      return {
        ...state,
        payment: {
          ...state.payment,
          loading: false
        }
      };
    case UNMOUNT_PIN:
      return {
        ...state,
        payment: initialState.payment
      };
    default:
      return state;
  }
};
export default {
  initialState,
  reducer
};
