import config from "../config";
import axios from "./AxiosWrapper";

const DataLayerService = {
  async getBeginCheckout(orderId) {
    const response = await axios.get(`${config("API_URL")}data-layer/begin-checkout/${orderId}`);
    return response.data;
  },
  async getAddShippingIndo(orderId) {
    const response = await axios.get(`${config("API_URL")}data-layer/add-shipping-info/${orderId}`);
    return response.data;
  },
  async getAddPaymentInfo(orderId) {
    const response = await axios.get(`${config("API_URL")}data-layer/add-payment-info/${orderId}`);
    return response.data;
  },
  async getPurchaseInfo(orderId) {
    const response = await axios.get(`${config("API_URL")}data-layer/purchase/${orderId}`);
    return response.data;
  }
};

export default DataLayerService;
