import axios from "./AxiosWrapper";
import config from "../config";

const ContactService = {
  sendMessage(eventId, values) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${config("API_URL")}emails/contact-organiser`, {
          eventId,
          values
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
};

export default ContactService;
