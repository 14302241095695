import loadScript from "load-script";

export const LOAD_PIN_REQUEST = "LOAD_PIN_REQUEST";
export const LOAD_PIN_SUCCESS = "LOAD_PIN_SUCCESS";
export const LOAD_PIN_ERROR = "LOAD_PIN_ERROR";

export const PIN_INIT = "PIN_INIT";
export const PIN_INIT_REQUEST = "PIN_INIT_REQUEST";
export const UPDATED_FIELDS = "UPDATED_FIELDS";

export const UNMOUNT_PIN = "UNMOUNT_PIN";

import { checkoutError, payV2, payClicked } from "./orderActions";
import config from "../../config";

export const loadCheckOutScript = () => {
  return (dispatch, getState) => {
    const state = getState();
    const checkoutScript = state.pin.checkoutScript;
    if (checkoutScript.error || (!checkoutScript.loaded && !checkoutScript.loading)) {
      dispatch({ type: LOAD_PIN_REQUEST });
      loadScript(`https://cdn.pinpayments.com/pin.hosted_fields.v1.js`, (err, script) => {
        if (err) {
          dispatch({ type: LOAD_PIN_ERROR });
          return;
        }
        dispatch({ type: LOAD_PIN_SUCCESS });
      });
    } else {
      dispatch({ type: LOAD_PIN_SUCCESS });
    }
  };
};

export const initPin = () => {
  return (dispatch, getState) => {
    const { pin } = getState();
    const initialised = pin.payment.initialised;
    if (initialised) return;

    dispatch({ type: PIN_INIT_REQUEST });
    const fields = window.HostedFields.create({
      sandbox: config("NODE_ENV") !== "production",
      autocomplete: true,
      styles: {
        input: {
          "font-size": "16px",
          "font-family": '"Lato", "Open Sans", sans-serif, -apple-system, system-ui',
          color: "#3f3e41"
        }
      },
      fields: {
        name: {
          selector: "#name",
          placeholder: "Full Name"
        },
        number: {
          selector: "#number",
          placeholder: "Card Number"
        },
        cvc: {
          selector: "#cvc",
          placeholder: "CVC"
        },
        expiry: {
          selector: "#expiry",
          placeholder: "MM/YY"
        }
      }
    });
    window.fields = fields;
    fields.on("ready", (event) => {
      dispatch({ type: PIN_INIT });
    });
  };
};

export const unmountPin = () => {
  return (dispatch) => {
    dispatch({ type: UNMOUNT_PIN });
  };
};

export const updateFields = (data) => {
  return (dispatch) => {
    dispatch({ type: UPDATED_FIELDS, data });
  };
};

export const payPin = (order, event, isGlobal) => {
  return (dispatch) => {
    dispatch(payClicked());
    const fields = window.fields;
    fields.tokenize(
      {
        publishable_api_key: event.data.paymentOptions.pinInfo.publishableApiKey
      },
      (errors, response) => {
        if (errors) {
          let errorMessages = [];
          if (errors.messages && errors.messages.length) {
            errorMessages = errors.messages.map((err) => err.message);
          }
          dispatch(checkoutError(errorMessages.join(", ")));
          return;
        }
        const paymentId = isGlobal ? order.userId : event.data._id;
        dispatch(
          payV2(
            order.data._id,
            { gateway: "pin", data: { cardType: response.scheme, transactionToken: response.token } },
            paymentId,
            isGlobal,
            order.tipFees
          )
        );
      }
    );
  };
};
